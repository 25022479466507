import React from "react";
import { Link } from "react-router-dom";
import clsx from 'clsx'
import {
  Grid, Card, CardContent, Typography, Button, CardActionArea,
  CardActions, makeStyles, createStyles
} from '@material-ui/core'
import { ReactComponent as Arrow } from '../assets/right-arrow.svg'
import { ReactComponent as Customer } from '../assets/Customer.svg'
import { ReactComponent as Setting } from '../assets/Setting.svg'
import { ReactComponent as Store } from '../assets/Store.svg'
import useCustomTranslation from '../container/useCustomTranslation';
import { AssessmentOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => createStyles({
  root: {
    margin: 8,
    minWidth: '280px',
    borderRadius: '3px',
    border: '1px solid rgba(0, 0, 0,0.01)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.24)',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    }
  },
  title: {
    fontSize: '32px',
    lineHeight: '43px',
    letterSpacing: '0.03em',
    color: '#FFFFFF',
  },
  container: {
    flexGrow: 1,
    marginTop: '5%',
    overflow: 'hidden'

  },
  stores: {
    background: '#2EC2F9',
  },
  settings: {
    background: '#FB9C08',
  },
  customer: {
    background: '#EB5B37',
  },
  reports: {
    background: '#27AA54',
  },
  buttonContent: {
    padding: 4,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.03em',
    color: '#FFFFFF',
    '&:hover': {
      fontWeight: 'bold',
      backgroundColor: 'transparent',
    }
  },
  cardContent: {
    height: '130px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  cardAction: {
    height: '37px',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }
}));

function MenuCard(props: any) {
  const classes = useStyles();
  const { t } = useCustomTranslation();

  const { title, ImageData, target, cardClass, id } = props;
  return (
    <Grid item>
      <Card id={`id-card-${id}`} className={clsx(classes.root, cardClass)}>
        <CardActionArea component={Link} to={target} disableRipple>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title} color="primary" gutterBottom>
              {title}
            </Typography>
            <ImageData />
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardAction}>
          <Button
            id={`id-btn-${id}`}
            color="primary"
            disableFocusRipple
            disableElevation
            disableRipple
            disableTouchRipple
            className={classes.buttonContent}
            endIcon={<Arrow fill="white" />}
            component={Link}
            to={target}>
            {t('Home.Enter')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default function Home() {
  const classes = useStyles();
  const { t } = useCustomTranslation();

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
      <MenuCard id="stores" cardClass={classes.stores} title={t("Home.Stores")} target="/locations" ImageData={Store} />
      <MenuCard id="customers" cardClass={classes.customer} title={t("Home.Customers")} target="/customers" ImageData={Customer} />
      <MenuCard id="reports" cardClass={classes.reports} title={t("Home.Reports")} target="/reports"
        ImageData={() => <AssessmentOutlined style={{ color: '#22954a', height: '60px', width: '60px' }} />} />
      <MenuCard id="settings" cardClass={classes.settings} title={t("Home.Settings")} target="/settings" ImageData={Setting} />
    </Grid>
  );
};