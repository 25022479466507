import client, { clientAsync } from './api-client';

export const getGoogleMeetClientId = async () => {
    let getGoogleCalendarRequestApi = 'organizations/me/googleMeetClientId';
    try {
        const res = await clientAsync(getGoogleCalendarRequestApi);
        if (res.ok) {
            return await res.text();
        } else {
            return Promise.reject(res);
        }
    }
    catch (e) {
        return Promise.reject(e);
    }
};

export const isGoogleMeetAuthenticated = async () => {
    let Uri = 'organizations/me/isGoogleMeetAuthenticated';
    try {
        const res = await clientAsync(Uri);
        if (res.ok) {
            return await res.text();
        } else {
            return Promise.reject(res);
        }
    }
    catch (e) {
        return Promise.reject(e);
    }
};

export const sendGoogleMeetCode = async (code: string) => {
    let endpoint = 'organizations/me/generateGoogleMeetToken';
    let urlencoded = new URLSearchParams();
    if (code){
        urlencoded.append('authorizationCode', code);
        urlencoded.append('redirectUri', "postmessage");
    }
    let requestOptions: any = {
        method: 'PUT',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }
    try {
        const res = client(endpoint,{ ...requestOptions });
        return res;
    }
    catch (e) {
        return Promise.reject(e);
    }
};

export const revokeGoogleMeetToken = async () => {
    let endpoint = 'organizations/me/revokeGoogleMeetToken';
    let requestOptions: any = {
        method: 'put',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
    try {
        const res = client(endpoint,{ ...requestOptions });
        return res;
    }
    catch (e) {
        return Promise.reject(e);
    }
};