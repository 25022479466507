
import { format } from 'date-fns';
import { minToHr } from '../utils/eventUtils';
import { slotModel } from '../utils/slotModel';
import client, { clientAsync } from './api-client';

const STORE_ENDPOINT = "locations";

export async function getStores(sub?: any) {
    let storeURL = `${STORE_ENDPOINT}?sortBy=name`;
    storeURL = storeURL + `&localTimeDifference=${minToHr()}`;
    const metadataResponse = clientAsync(storeURL);
    return metadataResponse;
}

export const getStore = async (id: string) => {
    return client(`${STORE_ENDPOINT}/${id}`);
};

export const getStoreList = async () => {
    let storeURL = `${STORE_ENDPOINT}?sortBy=name`;
    storeURL = storeURL + `&localTimeDifference=${minToHr()}`;
    return client(storeURL);
};
export const getLocationsLite = async () => {
    return client(`${STORE_ENDPOINT}/light?sortBy=name`);
};


export const fetchEmployees = async (storeId?: any) => {
    if (storeId) {
        return client(`${STORE_ENDPOINT}/${storeId}/employees?sortBy=name`)
    } else {
        return client('employees?sortBy=name');
    }
};


export async function getAppointments(storeId: any, startDate?: any, endDate?: any) {
    let start = startDate ? startDate : '2020-06-11'
    let end = endDate ? endDate : '2020-08-11'

    const url = `${STORE_ENDPOINT}/${storeId}/appointments?startDate=${start}&endDate=${end}`;

    const metadataResponse = clientAsync(url);
    return metadataResponse;
}

export async function getScheduledAppointments(startDate?: any, endDate?: any) {
    let start = startDate ? startDate : '2020-06-11'
    let end = endDate ? endDate : '2020-08-11'

    const url = `appointments?startDate=${start}&endDate=${end}`;

    const metadataResponse = client(url);
    return metadataResponse;
}


export async function approveAppointment(appointmentId: string, hostId?: string) {
    let endpoint = `appointments/${appointmentId}/approve`
    let urlencoded = new URLSearchParams();
    urlencoded.append('isApproved', 'true');
    if (hostId) {
        //endpoint = endpoint + `?hostId=${hostId}`
        urlencoded.append('hostId', hostId);
    }
    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function assignAppointment(appointmentId: string, hostId?: string) {
    let endpoint = `appointments/${appointmentId}/assign`
    let urlencoded = new URLSearchParams();
    if (hostId) {
        //endpoint = endpoint + `?hostId=${hostId}`
        urlencoded.append('hostId', hostId);
    }
    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function completeAppointment(appointmentId: string, completionNotes?: string) {
    let endpoint = `appointments/${appointmentId}/complete`
    let urlencoded = new URLSearchParams();
    if (completionNotes) {
        //endpoint = endpoint + `?completionNotes=${completionNotes}`
        urlencoded.append('completionNotes', completionNotes);
    }

    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function rescheduleAppointment(appointmentId: string, appointmentDate: string, appointmentSlot: string) {
    let endpoint = `appointments/${appointmentId}/reschedule`
    let urlencoded = new URLSearchParams();
    if (appointmentDate && appointmentSlot) {
        //       endpoint = endpoint + `?appointmentTime=${appointmentTime}`
        urlencoded.append('appointmentDate', appointmentDate);
        urlencoded.append('appointmentSlot', appointmentSlot);
    }

    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function rescheduleAdminAppointment(appointmentId: string,
    appointmentDate: string, appointmentSlot: string,appointmentDuration: string) {
    let endpoint = `appointments/${appointmentId}/reschedule/admin`
    let urlencoded = new URLSearchParams();
    if (appointmentDate && appointmentSlot) {
        urlencoded.append('appointmentDate', appointmentDate);
        urlencoded.append('appointmentSlot', appointmentSlot);
    }
    if (appointmentDuration) {
       urlencoded.append('appointmentDuration', appointmentDuration);
    }

    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function rejectAppointment(appointmentId: string, rejectionReason?: string) {
    let endpoint = `appointments/${appointmentId}/approve`
    let urlencoded = new URLSearchParams();
    urlencoded.append('isApproved', 'false');
    if (rejectionReason) {
        //endpoint = endpoint + `&cancellationReason=${rejectionReason}`
        urlencoded.append('cancellationReason', rejectionReason);
    }

    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function deleteAppointment(appointmentId: string, isRecurring?: boolean) {
    let endpoint = `appointments/${appointmentId}`
    let urlencoded = new URLSearchParams();
    if(isRecurring)
        endpoint = `${endpoint}?deleteRecurring=true`

    var requestOptions: any = {
        method: 'DELETE',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

/*
export async function cancelAppointment(appointmentId: string, rejectionReason?: string) {
    let endpoint = `appointments/${appointmentId}/accpet?isAccepted=false`
    if (rejectionReason) {
        endpoint = endpoint + `&rejectionReason=${rejectionReason}`
    }
    const metadataResponse = clientAsync(endpoint,{method:'POST'});
    return metadataResponse; 
}
*/

export async function getJoinAppointment(appointmentId: string, participantId?: string) {
    let endpoint = `appointments/${appointmentId}/hlight`
    if (participantId) {
        endpoint = endpoint + `?participantId=${participantId}`
    }
    const metadataResponse = client(endpoint);
    return metadataResponse;
}

export async function notify(appointmentId: string, type: number = 1, message?: string) {
    let endpoint = `appointments/${appointmentId}/notify`

    let urlencoded = new URLSearchParams();
    urlencoded.append('type', `${type}`);
    if (message) {
        urlencoded.append('message', message);
    }
    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function updateLinks(appointmentId: string, joinUrl?: string, startUrl?: string) {
    let endpoint = `appointments/${appointmentId}/updateLinks`
    let urlencoded = new URLSearchParams();
    if (startUrl) {
        urlencoded.append('startUrl', startUrl);
    }
    if (joinUrl) {
        urlencoded.append('joinUrl', joinUrl);
    }

    var requestOptions: any = {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function createBulkAppointment({ selectedStore, type, reason,appointmentDuration, customer, selectedDate, selectedSlot, host,startUrl,joinUrl, formFields, recurringDays, recurringUpto }: any) {
    const slot = slotModel(selectedSlot);
    const appointmentTime = slot.getLocalTime(selectedDate).toISOString();
    const recurringUptoTime = recurringUpto ? slot.getLocalTime(recurringUpto).toISOString() : null;
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const url = `${STORE_ENDPOINT}/${selectedStore}/appointments/admin/bulk?appointmentDate=${formattedDate}`;

    const data : any = {
        type,
        reason,
        customerIds: customer,
        appointmentDuration,
        appointmentTime,
        host,
        startUrl,
        joinUrl
    }
    if(formFields.length){
        data.formFields = formFields;
    }

    if(recurringDays.length)
        data.recurringDays = recurringDays;
    if(recurringUptoTime)
        data.recurringUpto = recurringUptoTime;

    const metadataResponse = clientAsync(url, { data });
    return metadataResponse;

}

export async function createAdminAppointment(appointmentData: any) {
    const { selectedStore, selectedDate, selectedSlot, appointmentDuration, recurringUpto } = appointmentData;
    const slot = slotModel(selectedSlot);
    const appointmentTime = slot.getLocalTime(selectedDate).toISOString();
    const recurringUptoTime = recurringUpto ? slot.getLocalTime(recurringUpto).toISOString() : null;
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const url = `${STORE_ENDPOINT}/${selectedStore}/appointments/admin?appointmentDate=${formattedDate}`;
    
    const data: any = {
        type: appointmentData.type,
        reason:appointmentData.reason,
        customer:appointmentData.customer,
        host: appointmentData.host,
        startUrl:appointmentData.startUrl,
        joinUrl: appointmentData.joinUrl,
        appointmentTime,
        appointmentDuration
    }
    if(appointmentData.formFields.length){
        data.formFields = appointmentData.formFields;
    }

    if(appointmentData.recurringDays.length)
        data.recurringDays = appointmentData.recurringDays;
    if(recurringUptoTime)
        data.recurringUpto = recurringUptoTime;

    const metadataResponse = clientAsync(url, { data });
    return metadataResponse;

}

export const getFileUploadURL = async (id: any, fileId: any) => {
    return await (await clientAsync(`/appointments/${id}/files/${fileId}/upload`)).text();
};

export const getFileURL = async (id: any, fileId: any) => {
    return await (await clientAsync(`/appointments/${id}/files/${fileId}`)).text();
};