import client, { clientAsync } from './api-client';
import { useAsync } from "react-async"

function getEnd(id: any, path: string) {
    // org id 7f9b3941-79a1-46aa-bc40-71606f3f90c9
    if (id) {
        return `organizations/${id}/${path}`
    } else {
        return `organizations/me/${path}`
    }
}


export const getImageUploadURL = async (id?: any) => {
    return await (await clientAsync(getEnd(id, 'writableLogoUrl'))).text();
};

export const getDescUploadURL = async (id?: any) => {
    return await (await clientAsync(getEnd(id, 'writableDescriptionUrl'))).text();
};

// export function useImageUploadURL(callback?: any) {
//     return useAsync({ deferFn: getImageUploadURL, onResolve: callback });
// }

export const deleteImage = async (id?: any) => {
    return client(getEnd(id, 'organizationLogo'), {method: 'DELETE' });
};

export function useDeleteImage(callback?: any) {
    return useAsync({ deferFn: deleteImage, onResolve: callback });
}

const updateBasics = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'basics'), { data: orgDetails, method: 'PUT' });
};

export function useUpdateBasicDetails(callback: any) {
    return useAsync({ deferFn: updateBasics, onResolve: callback });
}

const updateWeeklyPlan = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'weeklyPlan'), { data: orgDetails, method: 'PUT' });
};

export function useUpdateWeeklyPlan(callback: any, errCallback?:any) {
    return useAsync({ deferFn: updateWeeklyPlan, onResolve: callback, onReject:errCallback });
}

const updatHolidays = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'holidays'), { data: orgDetails, method: 'PUT' });
};

export function useUpdateHolidays(callback: any,errCallback?:any) {
    return useAsync({ deferFn: updatHolidays, onResolve: callback,onReject:errCallback });
}

const updateAppointment = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'appointment'), { data: orgDetails, method: 'PUT' });
};

export function useUpdateAppointment(callback: any) {
    return useAsync({ deferFn: updateAppointment, onResolve: callback });
}

const updateConference = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'conference'), { data: orgDetails, method: 'PUT' });
};

export function useUpdateConference(callback: any) {
    return useAsync({ deferFn: updateConference, onResolve: callback });
}

const updateTerminology = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'terminology'), { data: orgDetails, method: 'PUT' });
};

export function useUpdateTerminology(callback: any) {
    return useAsync({ deferFn: updateTerminology, onResolve: callback });
}

const updatePaymantGateway = async ([orgDetails, id]: any) => {
    return client(getEnd(id, 'paymentGateway'), { data: orgDetails, method: 'PUT' });
};

export function useUpdatePaymantGateway(callback: any) {
    return useAsync({ deferFn: updatePaymantGateway, onResolve: callback });
}