import React from "react";
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from './Routes';
import { CssBaseline } from '@material-ui/core';
import { light, dark } from '../styles/theme';


export default function AdminApp() {
    return (
        <ThemeProvider theme={light}>
            <CssBaseline />
            <Routes />
        </ThemeProvider>
    );
};
