import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { getAppointments, fetchEmployees, getStore } from '../api/calendarService';
import Calendar from "../component/Calendar/Calendar";
import Loader from "../component/Loader";
import { Redirect } from "react-router";
import { LOGIN_PATH } from "../utils/types";



const loadAppointments = async ({ storeId, startDate, endDate }: any, { signal }: any) => {
    if (storeId) {
        const res = await getAppointments(storeId, startDate, endDate);
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
    }
}

const loadAppointmentsDf = async ([{ storeId, startDate, endDate }]: any, { signal }: any) => {
    if (storeId) {
        const res = await getAppointments(storeId, startDate, endDate);
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
    }
}

export default function CalendarPage(props: any) {
    const storeId = props.location && props.location.state ? props.location.state.id : undefined;
    const [storeData, setStoreData] = useState<any>();
    const [eventTimes, setEventTimes] = useState<any>({
        startDate: moment().startOf('week').toISOString(),
        endDate: moment().endOf('week').toISOString()
    });
    useEffect(() => {
        if (storeId) {
            const fetchData = async () => {
                const apiStore = await getStore(storeId);
                const apiEmployee = await fetchEmployees(storeId);

                setStoreData({ store: apiStore, employees: apiEmployee });
            };

            fetchData();
        }
    }, []);

    const { data, error, run } = useAsync({
        promiseFn: loadAppointments, deferFn: loadAppointmentsDf,
        storeId, startDate: eventTimes.startDate, endDate: eventTimes.endDate
    })

    const changeCalView = ({ startDate, endDate, isForcedRefresh }: any) => {
        if (isForcedRefresh) {
            run({ storeId, startDate: eventTimes.startDate, endDate: eventTimes.endDate });
            return;
        }

        const isStartChanged = moment(startDate).isBefore(moment(eventTimes.startDate));
        const isEndChanged = moment(endDate).isAfter(moment(eventTimes.endDate));
        // Later change mechanisum to append data instead of calling huge data
        if (isStartChanged) {
            setEventTimes({ startDate, endDate: eventTimes.endDate })
            run({ storeId, startDate, endDate: eventTimes.endDate });
        } else if (isEndChanged) {
            setEventTimes({ startDate: eventTimes.startDate, endDate })
            run({ storeId, startDate: eventTimes.startDate, endDate });
        }
    }
    if (!storeId) {
        return <Redirect to={{ pathname: LOGIN_PATH, state: { from: props.location } }} />
    }

    //if(isPending) return <Loader useBackdrop open={true} />
    if (error) return <div>Something went wrong: {error.message}</div>
    if (data && storeData) {
        //const events = mapAppointment(data.models, calSettings.slot);
        //Create calendar context & manage state from here
        return <Calendar events={null} evt={data.models} storeId={storeId}
            storeData={storeData} fetchEv={changeCalView} />
    }
    return <Loader useBackdrop open={true} />;
}


