import {
    Box, Button, createStyles, Grid,
    IconButton, makeStyles, Table, TableBody, TableCell, TableRow
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import clsx from 'clsx';
import React from "react";
import { ReactComponent as CustomerFilled } from '../../assets/CustomerFilled.svg';
import { ReactComponent as Arrow } from '../../assets/right-arrow.svg';
import useCustomTranslation from '../../container/useCustomTranslation';
import { formatPhoneNumber, localeFormattedDate } from '../../utils/eventUtils';


const useStyles = makeStyles((theme: any) =>
    createStyles({
        gridListItem: {
            minHeight: 107,
            border: '1px solid #DFE3E9',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px #cccccc5e',
            borderTop: '4px solid',
            borderTopColor: theme.palette.secondary.main,
            margin: '10px 10px 0',
            borderRadius: '5px',
            lineBreak: 'anywhere'
        },
        head: {
            padding: theme.spacing(1, 2),
            border: 0,
            '&:last-child': {
                paddingRight: theme.spacing(1),
            }
        },
        refLink: {
            color: theme.palette.secondary.main,
            fontWeight: 400,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
        },
        text: {
            border: 0,
            fontSize: '14px',
            fontWeight: 600,
            width: '5rem',
            padding: '4px 4px 4px 16px',
        },
        subText: {
            fontSize: '14px',
            padding: '0px 4px 4px 8px',
            border: 0,
            '&:last-child': {
                padding: '4px 4px 4px 0px'
            }
        },
        paper: {
            textAlign: 'center',
            border: 0
        },
        buttonContent: {
            width: '100%',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.03em',
            color: theme.palette.secondary.main,
            borderTop: '2px solid #DFE3E9',
            backgroundColor: '#F9FAFA',
            boxSizing: 'border-box',
            borderRadius: '0 0 5px 5px',
            '&:hover': {
                fontWeight: 'bold'
            }
        },
        buttonCell: {
            padding: '4px 0 0 0',
            borderTop: 0,
            '&:last-child': {
                padding: '0',
            }

        },
        arrowColor: {
            fill: theme.palette.secondary.main,
            marginTop: '3px'
        },
        icButton: {
            margin: '0 0 0 8px',
            '&:hover': {
                color: 'black'
            }
        },
        truncate: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '40px',
        },
        title: {
            fontSize: '16px',
            textAlign: 'left',
            fontWeight: 600,
            minWidth: 'calc(100% - 100px)'
        },
    }),
);

export default function CustomerTable(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { customer, onCardClick, onEdit, onDelete } = props;
    return (
        <Grid key={customer.id} item xs={12} sm={6} md={4} justify="center">
            <Grid className={classes.gridListItem} key={customer.id} item justify="center">
                <Table size="small" stickyHeader={true}>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.head} colSpan={2}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="inline" width="25px"><CustomerFilled fill="#6c6c6c" /></Box>
                                    <span title={customer.name} className={clsx(classes.truncate, classes.title)}>
                                        {customer.name}
                                    </span>
                                    <Box display="inline" width="70px">
                                        <IconButton size="small" className={classes.icButton} onClick={() => onEdit(customer)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                        <IconButton size="small" className={classes.icButton} onClick={() => onDelete(customer)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.text}>{t('Customers.Email')}</TableCell>
                            <TableCell className={clsx(classes.subText, classes.truncate)} align="left" title={customer.email}>
                                <a href={`mailto:${customer.email}`} className={classes.refLink}>
                                    {customer.email}
                                </a>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.text}>{t('Customers.Phone')} </TableCell>
                            <TableCell className={clsx(classes.subText, classes.truncate)} align="left" title={customer.mobilePhone}>
                                <a href={`tel:${customer.mobilePhone}`} className={classes.refLink}>
                                    {formatPhoneNumber(customer.mobilePhone)}
                                </a>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.text}>{t('Customers.Created')} </TableCell>
                            <TableCell className={clsx(classes.subText, classes.truncate)} align="left">
                                {localeFormattedDate(customer.created)}
                                {/* {customer.created ? moment.utc(customer.created).local().format("L LT") : '-'} */}
                                {/* {customer.created ? new Date(customer.created).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : '-'} */}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.buttonCell} colSpan={2} align="center">
                                <Button
                                    id={`open-cal-${customer.id}`}
                                    color="primary"
                                    onClick={(e) => { onCardClick(customer.id) }}
                                    className={classes.buttonContent}
                                    endIcon={<Arrow className={classes.arrowColor} />}
                                    disableRipple>
                                    {t('Customers.Appointment')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}