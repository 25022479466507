import { Box, Button, makeStyles, createStyles, Theme, Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import {
    getGoogleMeetClientId, sendGoogleMeetCode, isGoogleMeetAuthenticated,
    revokeGoogleMeetToken
} from '../../api/googleApi';
import { ModalContext } from "../../container/ModalContainer";

export default function GoogleLoginAuth(props: any) {
    const classes = useStyles();
    const { email, refreshFn } = props;
    const [clientId, setClientId] = useState<any>(undefined);
    const [showButton, setShowButton] = useState(false);
    const [auth, setAuth] = useState(false);
    const alert = useContext(ModalContext);

    const onSuccess = async (e: any) => {
        try {
            const res = await sendGoogleMeetCode(e.code);
            setShowButton(false)
            setAuth(true);
            alert.showDone('Authentication Successful');
            refreshFn();
        } catch (err) {
            alert.showErr("Authentication Failed");
        }
    }
    const revoke = async (e: any) => {
        try {
            const res = await revokeGoogleMeetToken();
            setShowButton(true);
            setAuth(false);
            alert.showDone('Revoke Successful');
        } catch (err) {
            alert.showErr("Revoke Failed");
        }
    }

    useEffect(() => {
        isGoogleMeetAuthenticated().then((res) => {
            if (res === "true") {
                setShowButton(false);
                setAuth(true);
            }
            else {
                getGoogleMeetClientId().then((res) => {
                    setClientId(res);
                    setShowButton(true);
                    setAuth(false);
                }).catch(e => {
                });
            }
        }).catch(e => { });
    }, []);

    return (<>
        <Grid item xs={12} md={5}>
            <Grid container item spacing={3}>
                {auth && email && <Grid item xs={12}>
                    <TextField
                        label="Email"
                        id="email"
                        defaultValue={email}
                        variant="outlined"
                        disabled
                        size='small'
                        fullWidth
                    />
                </Grid>}
                {clientId && showButton ?
                    <>
                        <GoogleLogin
                            clientId={clientId}
                            render={renderProps => (
                                <Button variant="contained" color="secondary" className={classes.googleButton}
                                    onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                    Authenticate
                                </Button>
                            )}
                            onSuccess={(e) => onSuccess(e)}
                            onFailure={(e) => { alert.showModal({ msg: "Authentication Failed", type: 'error' }) }}
                            scope="https://www.googleapis.com/auth/calendar.events"
                            accessType="offline"
                            responseType="code"
                            redirectUri="postmessage"
                            prompt="consent"
                        />
                    </>
                    :
                    <Box>
                        <Button variant="contained" color="secondary" className={classes.googleButton}
                            disabled>
                            Authenticate
                        </Button>
                    </Box>}
                {auth && <Box>
                    <Button variant="contained" color="secondary" onClick={revoke} className={classes.revokeButton}>
                        Revoke
                        </Button>
                </Box>}
            </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
            <Grid container item spacing={2} className={classes.helpboxBorder}>
                <Grid item className={classes.headerText}>
                    Steps to authenticate with GoogleMeet for video calls :
                </Grid>
                <Grid item className={classes.labelText}>
                    <ol className={classes.orderList}>
                        <li>
                            Click on 'Authenticate' button to sign-in with your google account.
                    </li>
                        <li>
                            Complete the login process and grant permissions to proappointments.io to create video calls using your google calendar.
                    </li>
                        <li>
                            If you want to login with different google account click on 'Revoke' button.
                    </li>
                    </ol>
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        googleButton: {
            margin: '12px'
        },
        revokeButton: {
            margin: '12px'
        },
        helpboxBorder: {
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                paddingLeft: '0px'
            },
            borderLeft: '1px solid #aaa',
            paddingLeft: theme.spacing(1),
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px'
        },
        labelText: {
            fontSize: '14px',
            color: '#6C6C6C',
            lineHeight: '21px'
        },
        orderList: {
            margin: theme.spacing(0, 2),
            padding: theme.spacing(0, 1),
            '& li': {
                margin: theme.spacing(0.75, 0),
                '&::marker': {
                    fontWeight: 'bold',
                }
            }
        }
    }),
);