import { CircularProgress, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useField } from 'formik';
import React, { useState } from 'react';
import { ReactComponent as CheckAll } from '../../assets/CheckAll.svg';
import { ReactComponent as Validated } from '../../assets/Validated.svg';

function DomainValidateField(props: any) {
    const { name, validate } = props;
    const isSmallDevices = false;//useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    const [field, meta, helpers] = useField(name);
    const [, vMeta, vHelpers] = useField('valid');
    const [loading, setLoading] = useState(false);
    const isValidated = () => (vMeta.value && vMeta.value !== '') === true;
    const setName = ((e: any) => {
        helpers.setValue(e.target.value);
        if (isValidated()) {
            vHelpers.setValue('');
        }
    });

    const validateDomain = () => {
        setLoading(true);
        validate(meta.value)
            .then((res: any) => {
                setLoading(false)
                if (!res) {
                    helpers.setError("This domain name is already occupied");
                } else {
                    vHelpers.setValue(meta.value);
                }
            })
            .catch((e: any) => {
                setLoading(false)
                helpers.setError("Error occurred in validating Domain Path");
            })
    }
    return (
        <>
            <TextField
                {...field}
                {...props}
                onChange={setName}
                variant="outlined" color="secondary" required
                fullWidth size="small" id="domain"
                label="Domain Path"
                placeholder="<Enter domain here>"
                disablePointerEvents
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched && meta.error}
                InputProps={{
                    style: { fontWeight: 'bold' },
                    startAdornment: <InputAdornment position="start" style={{ marginRight: '0px' }}>
                        {isSmallDevices ? 'proappointments.io/' : 'www.proappointments.io/'}
                    </InputAdornment>,
                    endAdornment: <IconButton disabled={isValidated() || loading} onClick={() => validateDomain()}
                        edge='end'
                        style={{ borderRadius: '10px' }}>
                        <InputAdornment position='end' >
                            {loading ?
                                <CircularProgress size={20} color="secondary" /> :
                                isValidated() ?
                                    <Validated /> :
                                    <CheckAll />}
                        </InputAdornment>
                    </IconButton>,
                }}
            />
        </>
    )
}

export default React.memo(DomainValidateField);