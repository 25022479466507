import {
    createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useGetSubscriptionsPlans } from '../../api/subscriptionService';
import useCustomTranslation from '../../container/useCustomTranslation';
import { getCurrency } from '../../utils/eventUtils';
import { PaymentStatus } from '../../utils/types';
import Loader from "../Loader";
import CurrentSubscription from "./subscriptionComponent/CurrentSubscription";
import Plan from "./subscriptionComponent/Plan";
import TopUp from "./subscriptionComponent/TopUp";
import PlanSwitch from './subscriptionComponent/PlanSwitch';


export default function Subscription(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { org, refreshFn } = props;
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const showBuy = useMemo(() => {
        let todayDate = new Date();
        let expiryDate = org.subscriptionPlan ? org.subscriptionPlan.expiryDate : undefined;
        let paymentStatus = org.subscriptionPlan ? org.subscriptionPlan.paymentStatus : undefined;
        if (expiryDate && (paymentStatus === PaymentStatus.Paid || paymentStatus === PaymentStatus.Active || paymentStatus === PaymentStatus.CancellationPending)) {
            if (todayDate < new Date(expiryDate)) {
                return false;
            }
        }
        return true;
    }, [org]);

    const initialSubState = {
        showCurrentPlan: true,
        viewAllPlan: showBuy,
        topup: false,
        planType: false, // planType false = monthly , true = yearly
        currency: getCurrency(org.countryCode),
        loading: false,
        autorenewal: false,
        popup: false,
        selectedPlan: null
    };

    const [subscriptionState, SetSubscriptionState] = React.useState(initialSubState);
    const plan = useGetSubscriptionsPlans();
    const showPlan = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (subscriptionState.viewAllPlan) {
            SetSubscriptionState({ ...subscriptionState, viewAllPlan: false, topup: false });
        }
        else {
            SetSubscriptionState({ ...subscriptionState, viewAllPlan: true, topup: false });
        }
    }

    if (plan.isLoading) {
        return <Loader useBackdrop open={true} />
    }
    else if (plan.data) {
        return (
            <Grid className={classes.root}>
                <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0' }}>
                    <Grid item>
                        <Typography variant="h6">
                            {t('Settings.Subscription')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.body}>

                    {// 1. Current sucscription details 
                        subscriptionState.showCurrentPlan &&
                        <CurrentSubscription plan={plan.data} subscriptionState={subscriptionState} SetSubscriptionState={SetSubscriptionState} />}

                    { // 2. All Plans link
                        subscriptionState.showCurrentPlan && <> <Grid xs item container justify='space-between' className={classes.viewPlanLink} >
                            <span onClick={showPlan}>
                                {subscriptionState.viewAllPlan ? 'Hide' : 'Show'} Subscription Plans
                            </span>
                        </Grid></>}
                    { // If small device then change the view
                        isSmallDevices && <> 
                        <Grid xs item container justify='space-between' className={classes.viewPlanLink} >
                            <span>
                                <PlanSwitch context={subscriptionState} SetContext={SetSubscriptionState} />
                            </span>
                        </Grid>
                        </>}

                    {//3. Subscription 
                        subscriptionState.viewAllPlan &&
                        <Plan plan={plan.data} context={subscriptionState} SetContext={SetSubscriptionState} refreshFn={refreshFn} showBuy={showBuy} />}

                    {// 4. Add on
                        subscriptionState.topup &&
                        <TopUp plan={plan.data} context={subscriptionState} SetContext={SetSubscriptionState} refreshFn={refreshFn} />}

                </Grid>
                <Loader useBackdrop open={subscriptionState.loading} />
            </Grid>
        );
    }
    else {
        return null;
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        body: {
            margin: '0 0 16px 0',
        },
        viewPlanLink: {
            marginTop: "10px",
            color: theme.palette.secondary.main,
            fontWeight: 400,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
        },
        selectPlanPannel: {
            flexGrow: 1,
        },
    }),
);

