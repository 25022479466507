import { createStyles, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from "react";
import { fetchEmployees, getLocationsLite } from "../../api/calendarService";
import useCustomTranslation from '../../container/useCustomTranslation';
import { formatPhoneNumber } from '../../utils/eventUtils';
import Loader from "../Loader";
import AddButton from "./AddBtn";
import EmployeeForm from "./EmployeeForm";
import { RenderBox } from "./Stores";

const initialState: any = { open: false, title: '', type: undefined, selected: {} };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkHoverStyle: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        cursor: 'pointer'
      },
    }
  }),
);

export default function Employees(props: any) {
  const { t } = useCustomTranslation();
  const [flyout, setFlyout] = useState(initialState);
  const tableRef = useRef(null);
  const [locations, setLocations] = useState<any>([]);
  const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles();

  useEffect(() => {
    getLocationsLite().then(res => {
      setLocations(res.models);
    })
  }, []);

  const handleClose = (e: any, doRefresh = false) => {
    if (doRefresh === true) {
      //@ts-ignore
      tableRef && tableRef.current && tableRef.current.onQueryChange()
    }
    setFlyout(initialState);
  };

  return (
    <Grid>
      <Grid container item xs={12} justify="space-between" alignItems="center" style={{ padding: '16px 0' }}>
        <Grid item>
          <Typography variant="h6">
            {t('Settings.Employees')}
          </Typography>
        </Grid>
        <Grid item>
          <AddButton fieldId="add-employee-btn" btnLabel={t("EmployeeSettings.AddEmployee")}
            onClick={(event: any) => setFlyout({ open: true, title: t("EmployeeSettings.CreateLabel"), type: 'add', selected: {} })}
          />
        </Grid>
      </Grid>
      <MaterialTable
        title={t('Settings.Employees')}
        tableRef={tableRef}
        options={{
          search: false,
          actionsColumnIndex: -1,
          paging: false,
          sorting: false,
          draggable: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: '#f1f1f1',
            fontWeight: 'bold',
            fontSize: '14px'
          }
        }}
        components={{
          Container: props => <Paper {...props} elevation={0} />,
          OverlayLoading: props => <Loader useBackdrop open={true} />
        }}
        columns={[
          {
            title: t('EmployeeSettings.TableHeaders.Name'), field: 'name',
            render: rowData => <NameLink rowData={rowData} setFlyout={setFlyout} />
          },
          {
            title: t('EmployeeSettings.TableHeaders.Locations'), field: 'locations',
            render: rowData => <LocationNames data={rowData} locations={locations} />
          },
          {
            title: t('EmployeeSettings.TableHeaders.LocationAdmin'), field: 'isLocationAdmin', align: 'center',
            render: rowData => <RenderBox isEnabled={rowData.isLocationAdmin} />
          },
          {
            title: t('EmployeeSettings.TableHeaders.Email'), field: 'email', hidden: isSmallDevices,
            render: rowData => <a href={`mailto:${rowData.email}`} className={classes.linkHoverStyle}>{rowData.email}</a>
          },
          {
            title: t('EmployeeSettings.TableHeaders.Phone'), field: 'mobilePhone', hidden: isSmallDevices,
            render: rowData => <a href={`tel:${rowData.mobilePhone}`} className={classes.linkHoverStyle}>{formatPhoneNumber(rowData.mobilePhone)}</a>
          },

        ]}
        data={() =>
          new Promise((resolve, reject) => {
            fetchEmployees() //.then(response => response.json())
              .then((result) => {
                resolve({
                  data: result.models,
                  page: 0,
                  totalCount: result.totalCount
                })
              }).catch(err => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                })
              })
          })
        }

        actions={[
          {
            icon: () => <AddButton fieldId="add-employee-btn" btnLabel={t("EmployeeSettings.AddEmployee")} />,
            isFreeAction: true,
            onClick: (event) => setFlyout({ open: true, title: t("EmployeeSettings.CreateLabel"), type: 'add', selected: {} })
          },
          {
            icon: () => <Edit style={{ color: '#6c6c6c' }} fontSize="small" />,
            onClick: (event, rowData: any) => setFlyout({ open: true, title: t("EmployeeSettings.EditLabel"), type: 'edit', selected: rowData })
          },
          {
            icon: () => <Delete style={{ color: '#6c6c6c' }} fontSize="small" />,
            onClick: (event, rowData: any) => setFlyout({ open: true, title: t("EmployeeSettings.DeleteLabel"), type: 'delete', selected: rowData }),
          }
        ]}
      />
      {flyout.open &&
        <EmployeeForm open={flyout.open} handleClose={handleClose} type={flyout.type} locations={locations}
          title={flyout.title} selected={flyout.selected} />}
    </Grid>
  )
}

function NameLink(props: any) {
  const { t } = useCustomTranslation();
  const { rowData, setFlyout } = props;
  return (
    <div id={`view-${rowData.name}`} color="inherit" className="hovar-bold" style={{ color: "#069", textTransform: 'capitalize', cursor: "pointer" }}
      onClick={() => setFlyout({ open: true, title: t("EmployeeSettings.ViewLabel"), type: 'edit', selected: rowData })}>
      {rowData.name}
    </div>
  );
}

function LocationNames(props: any) {
  const { data, locations } = props;
  if (!locations || locations.length === 0) {
    return <div>-</div>
  } else if (!data || !data.locations) {
    return <div>-</div>
  } else {
    return (<div>
      {data.locations.map((m: any) => {
        const filteredLocation = locations.find((a: any) => a.id === m.id);
        return filteredLocation ? filteredLocation.name : '';
      }).join(', ')}
    </div>);
  }
}