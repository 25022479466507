import React, { useContext, useState } from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Theme, createStyles, Link, Grid, Button
} from '@material-ui/core';
import { makeStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { SubscriptionPlanTypes } from '../../../utils/types';
import { getCurrency, getCurrencySymbol } from '../../../utils/eventUtils';
import OrgContext from '../../../container/OrgContext';
import { ReactComponent as Tick } from '../../../assets/Tick.svg';


export default function TableBodyMobile(prop: any) {
  const classes = useStyles();
  const { plans , index, SetContext, context, showBuy, buttonState }= prop;
  const IndexPlan = plans[index];
  const contactUs = process.env.REACT_APP_CONTACT_US_URL;
  const [org] = useContext(OrgContext);

  const handleClickOpen = (event: React.SyntheticEvent, plan: any) => {
    event.preventDefault();
    SetContext({ ...context, popup: true, selectedPlan: plan });
  };

  return (
    <>
      {IndexPlan && (<>
        <TableContainer>
            <Table stickyHeader aria-label="all plans">
                <TableBody>
                    <StyledTableRow key="features">
                        <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Features
                        </StyledTableCell>
                        {IndexPlan.plan === SubscriptionPlanTypes.Free || IndexPlan.plan === SubscriptionPlanTypes.Enterprise ? <>
                                <StyledTableCell align="center">
                                    {IndexPlan.plan === SubscriptionPlanTypes.Free ? null :
                                        <Link href={contactUs} target="_blank" className={classes.refLink}>
                                            Contact Us
                                    </Link>}
                                </StyledTableCell> </> : <>
                                    <StyledTableCell align="center" style={{ fontWeight: 600, fontSize: '16px' }}>
                                        <Grid> {context.planType ? <>
                                            {getCurrencySymbol(context.currency)}{IndexPlan.cost.yearly[context.currency].price} </>
                                            :
                                            <> {getCurrencySymbol(context.currency)}{IndexPlan.cost.monthly[context.currency].price} </>}
                                            {org.countryCode !== 'in' ? getCurrency(org.countryCode) : ""}
                                        </Grid>
                                        {showBuy && <Button color="secondary"
                                            variant="contained"
                                            disabled={buttonState}
                                            name={IndexPlan.plan}
                                            className={classes.subscribeButton}
                                            onClick={e => handleClickOpen(e, IndexPlan)}
                                        >
                                            Buy
                                </Button>}
                            </StyledTableCell>
                        </>
                        }
                    </StyledTableRow>
                    <StyledTableRow key="locationCalendarsLimit">
                        <StyledTableCell component="th" scope="row">
                            Location Calendars
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['locationCalendarsLimit'] > 1 ? "Up to " : null}
                                {IndexPlan.features['locationCalendarsLimit'] < 1 ? "Unlimited" : IndexPlan.features['locationCalendarsLimit']}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="appointmentDataRetention">
                        <StyledTableCell component="th" scope="row">
                            Appointment Data Retention
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['appointmentDataRetention'] ? IndexPlan.features['appointmentDataRetention'] + " Days" : null}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="locationVisit">
                        <StyledTableCell component="th" scope="row">
                            Location Visits
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['locationVisit'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="videoCall">
                        <StyledTableCell component="th" scope="row">
                            Video Calls
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['videoCall'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="homeVisit">
                        <StyledTableCell component="th" scope="row">
                            Home Visits
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['homeVisit'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="customerFacingWebsite">
                        <StyledTableCell component="th" scope="row">
                            Customer Facing Webite
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['customerFacingWebsite'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="assignIncomingAppointmentsToEmployees">
                        <StyledTableCell component="th" scope="row">
                            Assign Appointments to Employees
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['assignIncomingAppointmentsToEmployees'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="appointmentLifecycleAndRules">
                        <StyledTableCell component="th" scope="row">
                            Appointment Lifecycle and Rules
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['appointmentLifecycleAndRules'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="emailNotifications">
                        <StyledTableCell component="th" scope="row">
                            Email Notifications
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['emailNotifications'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="smsNotifications">
                        <StyledTableCell component="th" scope="row">
                            SMS Notifications
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['smsNotifications'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="appointmentReminder">
                        <StyledTableCell component="th" scope="row">
                            Appointment Reminders
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['appointmentReminder'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="customerDashboard">
                        <StyledTableCell component="th" scope="row">
                            Customer Dashboard
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['customerDashboard'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="reporting">
                        <StyledTableCell component="th" scope="row">
                            Reporting
                        </StyledTableCell>
                            <StyledTableCell align="center">
                                {IndexPlan.features['reporting'] ? <Tick /> : "-"}
                            </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
      </>)}
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '5px',
        },
        tabs: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            width: '100%',
            minWidth: '64px',
        },
        content: {
            margin: theme.spacing(0, 1),
            marginBottom: theme.spacing(1),
            maxWidth: '100%'
        },
        refLink: {
            color: theme.palette.secondary.main,
            fontWeight: 400,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
        },
        subscribeButton: {
            marginTop: "3px",
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        tabletopbar: {
            minWidth: '150px',
            [theme.breakpoints.down('sm')]: {
                minWidth: 'auto',
            },
        }
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#F5F8FA",
            padding: '8px',
            fontWeight: 600
        },
        body: {
            padding: '8px'
        }

    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
        },
    }),
)(TableRow);