import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import { Backdrop, makeStyles, createStyles, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        gridListItem: {
            minHeight: 107,
            background: '#FFFFFF',
            border: '1px solid #DFE3E9',
            boxSizing: 'border-box',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
            borderTop: '4px solid',
            borderTopColor: theme.palette.secondary.main,
            margin: '10px 10px 0',
            borderRadius: '5px',
            lineBreak: 'anywhere'
        },
        loader: {
            position: 'relative',
            backgroundColor: 'green'
        }

    }),
);

export default function Loader(props: any) {
    const classes = useStyles();

    const { height, useBackdrop, open } = props;
    const { textHeight } = props;
    if (useBackdrop) {
        return (
            <Backdrop open={open} style={{ zIndex: 1500 }}>
                <CircularProgress color="secondary" />
            </Backdrop>)
    }
    return (
        <Grid container xs={12} style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={6} md={4} spacing={1} justify="center">
                <Grid className={classes.gridListItem} item>
                    <Skeleton variant="text" width="50%" height={height} />
                    <Skeleton variant="text" width="80%" height={height} />
                    <Skeleton variant="text" width="80%" height={height} />
                    {textHeight && <Skeleton variant="text" width="80%" height={textHeight} />}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} spacing={1} justify="center">
                <Grid className={classes.gridListItem} item>
                    <Skeleton variant="text" width="50%" height={height} />
                    <Skeleton variant="text" width="80%" height={height} />
                    <Skeleton variant="text" width="80%" height={height} />
                    {textHeight && <Skeleton variant="text" width="80%" height={textHeight} />}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} spacing={1} justify="center">
                <Grid className={classes.gridListItem} item>
                    <Skeleton variant="text" width="50%" height={height} />
                    <Skeleton variant="text" width="80%" height={height} />
                    <Skeleton variant="text" width="80%" height={height} />
                    {textHeight && <Skeleton variant="text" width="80%" height={textHeight} />}
                </Grid>
            </Grid>
        </Grid>
    );

}