import {
    Button, createStyles, useMediaQuery, Paper, Table, Tabs, Tab, Grid,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Theme
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { subscribeSMSPlan } from '../../../api/subscriptionService';
import { ModalContext } from '../../../container/ModalContainer';
import OrgContext from '../../../container/OrgContext';
import { getCurrency, getCurrencySymbol } from '../../../utils/eventUtils';

export default function AddOn(props: any) {
    const classes = useStyles();
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const alertHelpers = useContext(ModalContext);
    const [buttonState, setButtonState] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [org] = useContext(OrgContext);
    const { plan, SetContext, context, refreshFn } = props;
    const addAddon = async (event: React.SyntheticEvent, addon: any) => {
        event.preventDefault();
        setButtonState(true);
        setLoading(true);
        try {
            const smsPlan = addon.package;
            const resp = await subscribeSMSPlan(smsPlan);
            handlePayment(resp);
        } catch (err) {
            if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                alertHelpers.showErr(err.ErrorMessages.join(', '));
            } else {
                alertHelpers.showErr("Error occurred in subscription");
            }
        } finally {
            setLoading(false);
            SetContext({ ...context, topup: false })
        }
    }

    const handlePayment = (result: any) => {
        const { id: order_id, apiKey } = result;
        const options = {
            key: apiKey,
            order_id: order_id,
            name: org.name,
            handler: (response: any) => {
                setTimeout(() => {
                    refreshFn();
                }, 5000);
            },
            theme: classes.paper
        };

        //@ts-ignore
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
    const setLoading = (show: boolean) => {
        SetContext({ ...context, loading: show })
    }

    const handleIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedIndex(newValue);
    };

    return ( <>
    {isSmallDevices ? <>
        <Paper variant="outlined" className={classes.root}>
              <Tabs
                  textColor='secondary' className={classes.tabs}
                  orientation="horizontal"
                  variant="scrollable"
                  scrollButtons="on"
                  value={selectedIndex}
                  onChange={handleIndexChange}>
                    {plan.smsPackages.map((values: any,index: number) => (
                                <Tab id={values.plan} label={values.package.toUpperCase()} value={index} />
                    ))}
                </Tabs>
              <Grid className={classes.content}>
                <TableContainer >
                    <Table >
                        <TableBody>
                            <StyledTableRow key="name">
                                <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                                    Messages
                                </StyledTableCell>
                                    <StyledTableCell align="center">{plan.smsPackages[selectedIndex].messages}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="price">
                                <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                                    Price
                                </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {getCurrencySymbol(context.currency) + plan.smsPackages[selectedIndex].cost[context.currency]} {org.countryCode !== 'in' ? getCurrency(org.countryCode) : ""}
                                    </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="name">
                                <StyledTableCell component="th" scope="row"></StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button color="secondary"
                                            variant="contained"
                                            disabled={buttonState}
                                            className={classes.addButton}
                                            onClick={e => addAddon(e, plan.smsPackages[selectedIndex])}
                                        >
                                            Buy
                                            </Button>
                                    </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
              </Grid>
          </Paper>
        </> :
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        {plan.smsPackages.map((values: any) => (
                            <StyledTableCell align="center">{values.package.toUpperCase()}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow key="name">
                        <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Messages
                        </StyledTableCell>
                        {plan.smsPackages.map((values: any) => (
                            <StyledTableCell align="center">{values.messages}</StyledTableCell>
                        ))}
                    </StyledTableRow>
                    <StyledTableRow key="price">
                        <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Price
                        </StyledTableCell>
                        {plan.smsPackages.map((values: any) => (
                            <StyledTableCell align="center">
                                {getCurrencySymbol(context.currency) + values.cost[context.currency]} {org.countryCode !== 'in' ? getCurrency(org.countryCode) : ""}
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                    <StyledTableRow key="name">
                        <StyledTableCell component="th" scope="row"></StyledTableCell>
                        {plan.smsPackages.map((values: any) => (
                            <StyledTableCell align="center">
                                <Button color="secondary"
                                    variant="contained"
                                    disabled={buttonState}
                                    className={classes.addButton}
                                    onClick={e => addAddon(e, values)}
                                >
                                    Buy
                                    </Button>
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
    </>);

}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root:{

        },
        selectedPlanBox: {
            border: '1px solid #DEE2E6',
            borderRadius: '5px',
        },
        paper: {
            color: theme.palette.secondary.main
        },
        table: {

        },
        addButton: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        buytable: {
            backgroundColor: '#FAFAFA',

        },
        tabs: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            width: '100%',
            minWidth: '64px',
        },
        content: {
            margin: theme.spacing(0, 1),
            marginBottom: theme.spacing(1),
            maxWidth: '100%'
        },
    }),
);



const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#F5F8FA",
            fontWeight: 600
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
        },
    }),
)(TableRow);