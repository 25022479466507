
import { Button, createStyles, Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import { Field, Form, Formik, FieldArray } from 'formik';
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import React, { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import OrgContext from '../../container/OrgContext';
import useCustomTranslation from '../../container/useCustomTranslation';
import AutocompleteCutomerDetails from "./AutocompleteCutomerDetails";
import AutocompleteReasons from "./AutocompleteReaons";
import CustomFormDetails from './CustomFormDetails'
import BulkAutocompleteCutomerDetails from "./BulkAutocompleteCutomerDetails";
import SelectHost from './SelectHost';
import SelectStore from './SelectStore';
import SelectTime from './SelectTime';
import SelectRecurring from "./SelectRecurring";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(0.5, 1),
            borderRadius: '3px',
            width: '100px',
            height: '35px',
            fontSize: '16px',
            lineHeight: '19px',
        },
        label: {
            fontSize: '20px',
            fontWeight: 600,
            color: 'blue'
        },
        hrLine: {
            width: '100%',
            margin: theme.spacing(1, 0),
        },
        checkboxes: {
            padding: '4px 8px',
        },
    }));


export function MuiAppointmentForm(props: any) {

    const { selectedSlot, duration, datepassed, storeList, storeId, storeData, isBulk } = props;
    const { onCreate, onClose } = props;
    const [org] = useContext(OrgContext);
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const [selectedStoreId, SetValue] = useState(storeId);
    const [selectedStoreData, setSelectedStoreData] = useState<any>(storeData);

    const setStoreValue = (event: any) => {
        SetValue(event.target.value);
    };

    useEffect(() => {
        if (selectedStoreId && storeList) {
            const store = storeList.find((s: any) => s.id === selectedStoreId);
            setSelectedStoreData({ store });
        }
    }, [selectedStoreId, storeId])

    const createRecord = (e: any, formState: any) => {
        if (e) {
            e.preventDefault();
        }
        let aptObj: any = {
            selectedStore: selectedStoreId,
            type: formState.type,
            customer: isBulk ? formState.customerIds : {
                name: formState.customerName.trim(),
                mobilePhone: formState.customerPhone,
                email: formState.customerEmail
            },
            reason: formState.reason.trim(),
            selectedDate: formState.appointmentTime,
            appointmentDuration: formState.duration,
            selectedSlot: formState.slotSelected,
            recurringDays: formState.recurringDays,
            recurringUpto: formState.recurringUpto,
            startUrl: formState.startUrl,
            joinUrl: formState.joinUrl,
            formFields: formState.formFields,
        }

        if (formState.hostId && formState.hostId !== '-1') {
            aptObj.host = {
                id: formState.hostId,
                name: formState.hostName,
            }
        }
        onCreate(aptObj);
    }

    const initialState: any = {
        type: 0,
        appointmentTime: datepassed,
        customerName: '',
        customerEmail: '',
        customerPhone: '',
        reason: '',
        hostId: '-1',
        hostName: '',
        duration: duration ? duration : '',
        slotSelected: selectedSlot ? selectedSlot : undefined,
        startUrl: undefined,
        joinUrl: undefined,
        customerIds: [] as string[],
        formFields: [],
        isRecurring: false,
        recurringDays: [] as string[],
        recurringUpto: ''
    }

    return (
        <Formik
            initialValues={initialState}
            validate={(values) => {
                const errors: any = {};
                if (!isBulk) {
                    if (!values.customerName || values.customerName.trim() === '') {
                        errors.customerName = 'Required';
                    }
                    if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.customerEmail)
                    ) {
                        errors.customerEmail = 'Invalid email address';
                    }
                    if (!values.customerPhone) {
                        errors.customerPhone = 'Required';
                    } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.customerPhone)) {
                        errors.customerPhone = 'Invalid mobile number'
                    }
                } else {
                    if (!values.customerIds || values.customerIds.length === 0) {
                        errors.customerIds = 'Required'
                    }
                }
                if (!values.slotSelected) {
                    errors.slotSelected = t('AppointmentForm.SlotRequired');
                }
                if (!values.duration) {
                    errors.duration = t('AppointmentForm.DurationRequired');
                }
                if (!values.reason || values.reason.trim() === '') {
                    errors.reason = 'Required';
                }
                if (values.formFields.length > 0) {
                    let formFields: any = [];
                    let counter = 0;
                    values.formFields.forEach((field: any, index: any) => {
                        const error: any = {};
                        if (field.required) {
                            if (!(field.TextValue || field.NumericValue || field.DateValue)) {
                                if (field.type === 'text') {
                                    error.TextValue = "Required";
                                    counter++;
                                }
                                if (field.type === 'number') {
                                    error.NumericValue = "Required";
                                    counter++;
                                }
                                if (field.type === 'date') {
                                    error.DateValue = "Required";
                                    counter++;
                                }
                            }
                        }
                        formFields.push(error);
                    })
                    if (counter) {
                        errors.formFields = formFields;
                    }
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                createRecord(null, values);
            }}>
            {({ errors, dirty, setFieldValue, isSubmitting, isValid, values }) => (<Form>
                <Grid container spacing={2}>
                    {storeList &&
                        <SelectStore
                            storeId={selectedStoreId}
                            storeList={storeList}
                            onChange={setStoreValue}
                            disabled={storeId} />
                    }
                    {selectedStoreId && selectedStoreData && selectedStoreData.store ?
                        <>
                            {isBulk ? <BulkAutocompleteCutomerDetails storeData={selectedStoreData} /> :
                                <AutocompleteCutomerDetails storeData={selectedStoreData} />}
                            <SelectTime duration={duration} storeId={selectedStoreId} storeData={selectedStoreData} />
                            <SelectRecurring storeId={selectedStoreId} storeData={selectedStoreData} />
                            <Grid item xs={12}>
                                <SelectHost storeId={selectedStoreId} storeData={selectedStoreData} />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteReasons appointmentReasons={org.appointmentReasons} />
                            </Grid>
                            {values.type === 3 && (org.conferenceSettings && org.conferenceSettings.videoConferenceType === 'Other') && <>
                                <Grid item xs={12}>
                                    <Field component={TextField} name="startUrl" type="url" label={t("AppointmentForm.StartURL")}
                                        variant="outlined" size="small" color="secondary" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextField} name="joinUrl" type="url" label={t("AppointmentForm.JoinURL")}
                                        variant="outlined" size="small" color="secondary" fullWidth />
                                </Grid>
                            </>}
                            {selectedStoreData.store.formFields && selectedStoreData.store.formFields.length && <>
                                <Divider variant="fullWidth" className={classes.hrLine} />
                                <FieldArray name="formFields">
                                    {({
                                        ...fieldArrayHelpers
                                    }) =>
                                        <CustomFormDetails StoreData={selectedStoreData} fieldName='formFields'
                                            fieldArrayHelpers={fieldArrayHelpers} />
                                    }
                                </FieldArray>
                            </>}

                        </>
                        : <></>
                    }

                </Grid>
                <div className={classes.buttons}>
                    <Divider variant="fullWidth" className={classes.hrLine} />
                    <Button id="create-aptmt" variant="contained" type="submit" disabled={!dirty || !isValid || isSubmitting}
                        className={classes.button} color="secondary">
                        {t('AppointmentForm.CreateBtn')}
                    </Button>
                    <Button id="cancel-create-aptmt" onClick={onClose} className={classes.button} color="inherit" variant="contained">
                        {t('AppointmentForm.CancelBtn')}
                    </Button>
                </div>
            </Form>)}
        </Formik>
    );
}


export default MuiAppointmentForm;
