import { CustomFormInputValueType } from '../../utils/types'
import { getFileUploadURL }from '../../api/calendarService'

export function getFormFields(fieldArrayHelpers: any,storeData: any){
    if (storeData && storeData.formFields){
        const formFieldsList = storeData.formFields;
        formFieldsList.forEach((formField: any, index: any)=>{
            let field: any = {
                name : formField.name,
                required: formField.isRequired,
            };
            switch (formField.fieldType){ 
                case CustomFormInputValueType.Text:
                    field.TextValue = null;
                    field.type = 'text';
                    break;
                case CustomFormInputValueType.Number:
                    field.NumericValue = null;
                    field.type = 'number';
                    break;
                case CustomFormInputValueType.Date:
                    field.DateValue = null;
                    field.type = 'date';
                    break;
                case CustomFormInputValueType.Dropdown:
                    field.TextValue = null;
                    field.type = 'text';
                    break;
                case CustomFormInputValueType.File:
                    field.TextValue = null;
                    field.file = null;
                    field.type = 'text';
                    break;
                default:
                    break;
            }
            fieldArrayHelpers.push(field);
        })
    }
}

export function uploadAllFiles(AppointmentData: any, fieldData: any){
    if(!fieldData){ return }
    // make the apt to array to handle both case of single and bulk boking.
    AppointmentData = Array.isArray(AppointmentData) ? AppointmentData : [AppointmentData];
    const UploadFile = async (id:any ,fileId: any, file: any) => {
        try {
            const uploadURL = await getFileUploadURL(id,fileId);
            console.log(uploadURL);
            var myHeaders = new Headers();
            myHeaders.append("x-ms-blob-type", "BlockBlob");
            myHeaders.append("Content-Type", file.type);
            myHeaders.append("Access-Control-Allow-Origin", "*");


            let requestOptions: any = {
                method: 'PUT',
                headers: myHeaders,
                body: file,
                redirect: 'follow',
            };

            fetch(uploadURL, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e);
        }
    }
    AppointmentData.forEach( (apt: any) =>{
        if(!apt.formFields) { return }
        const filefields = apt.formFields.filter((f:any) => f.textValue && f.textValue.includes('|'));
        if(!filefields.length){ return }
        const aptId = apt.id;
        filefields.forEach((element:any) => {
            const fieldName = element.name;
            const aptFileValue = element.textValue;
            const fileValue = aptFileValue.split('|');
            const fileName = fileValue[0];
            const fileId = fileValue[1];
            const dataFilefield = fieldData.filter((f:any) => f.name === fieldName);
            const file = dataFilefield[0].file;
            if (fileName && file) { UploadFile(aptId,fileId,file) }
        });
    });
}