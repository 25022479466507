import { useAsync } from 'react-async';
import { getUTCDate } from '../utils/eventUtils';
import client from './api-client';
const STORE_ENDPOINT = "locations";


export const getSlotEndpoint = (selectedDate: any, selectedStore: any, appointmentType: any, appointmentDuration?:any) => {
  const formattedDate = getUTCDate(selectedDate).toISOString();
  const baseURL = `${STORE_ENDPOINT}/${selectedStore}/appointments/availableSlots`;
  if (appointmentDuration) {
    return `${baseURL}/admin?appointmentDate=${formattedDate}&appointmentType=${appointmentType}&appointmentDuration=${appointmentDuration}`;
  } else {
    return `${baseURL}?appointmentDate=${formattedDate}&appointmentType=${appointmentType}`;
  }
};


function useFetchAvailableSlots(selectedDate:any, isFullHoliday:any, selectedStore:any,appointmentType:any,appointmentDuration?:any) {

  const { isPending, data, error, run } = useAsync({
    promiseFn: fetchSlots,
    deferFn: fetchSlotsDef,
    selectedDate,
    selectedStore,
    appointmentType,
    isFullHoliday: isFullHoliday,
    appointmentDuration
  });

  const fetchAvailableSlots = ({ selectedDate, selectedStore, appointmentType,appointmentDuration }:any) => {
    return run({
      selectedDate: selectedDate,
      selectedStore: selectedStore,
      appointmentType,
      appointmentDuration
    });
  };

  return {
    isPending,
    data,
    error,
    fetchAvailableSlots
  };
}

export { useFetchAvailableSlots };

const fetchSlots = async ({
  selectedDate,
  selectedStore,
  appointmentType,
  isFullHoliday,
  appointmentDuration
}:any) => {
  if (isFullHoliday||!appointmentType) {
    return Promise.resolve([]);
  }
  return client(getSlotEndpoint(selectedDate, selectedStore, appointmentType,appointmentDuration));
};

const fetchSlotsDef = async ([
  { selectedDate, selectedStore, appointmentType,appointmentDuration }
]:any) => {
  return client(getSlotEndpoint(selectedDate, selectedStore, appointmentType,appointmentDuration));
};
