import { useAuth0 } from "@auth0/auth0-react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
//@ts-ignore
//import * as Auth0 from "auth0-js";
import { isOrgPresent } from "../utils/eventUtils";
//import '../styles/login.css'

// const webAuth = new Auth0.WebAuth({
//     domain: process.env.REACT_APP_DOMAIN ? process.env.REACT_APP_DOMAIN : '',
//     clientID: process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : '',
//     redirectUri: window.location.origin,
//     responseType: 'token id_token'
// });

export const Login = (props: any) => {
    const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
    const history = useHistory();
    // const [loginState, setLoginState] = useState({ uname: '', pwd: '' });
    // const destination = props.location.state ? props.location.state.from.pathname : '/home'
    const getToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
                scope: "read:current_user",
            });
            localStorage.setItem('authHeader', accessToken);
            return accessToken;
        } catch (e) {
            console.log(e.message);
            throw e;
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            if (isOrgPresent(user)) {
                getToken().then(() => {
                    history.push('/home')
                })
            } else {
                history.push('/form');
            }
        }
        else if (!isAuthenticated && !isLoading) {
            loginWithRedirect()
        }
    }, [isAuthenticated, isLoading]);
    //popup.loginWithCredentials
    // const login = (e: any) => {
    //     e.preventDefault();
    //     console.log(loginState.uname + '  ' + loginState.pwd);
    //     webAuth.popup.loginWithCredentials(
    //         {
    //             connection: 'Username-Password-Authentication',
    //             username: loginState.uname,
    //             password: loginState.pwd,
    //         },
    //         (err: any, data: any) => {
    //             console.log(err, data);
    //         }
    //     );
    // }

    return <div>
        <Backdrop open={isLoading}>
            <CircularProgress color="secondary" />
        </Backdrop>


        {/* <form onSubmit={login} style={{
            display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            height: '50vh', padding: '30px', rowGap: '10px', columnGap: '10px'
        }}>
            <div>Username: <input type="email" required placeholder="Enter Email"
                value={loginState.uname}
                onChange={e => { setLoginState({ ...loginState, uname: e.target.value }) }} /></div>
            <div>Password: <input type="password" required placeholder="Enter Password"
                value={loginState.pwd}
                onChange={e => { setLoginState({ ...loginState, pwd: e.target.value }) }} /></div>

            <div>
                <button type="submit" style={{ width: '80px' }}>Login</button>
                <button type="button" style={{ width: '80px' }} onClick={() => { setLoginState({ uname: '', pwd: '' }) }}>Clear</button>
            </div>
        </form> */}
        {/* <OtherClient/> */}
    </div>
};

function OtherClient() {
    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-header">
                    <h2>Org Name</h2>
                </div>
                <div id="error-message" className="alert alert-danger"></div>
                <form onSubmit={(e) => { e.preventDefault(); }} method="post">
                    <div className="form-group">
                        <label htmlFor="name" >Email</label>
                        <input
                            type="email"
                            className="form-control login-input"
                            id="email"
                            placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Password</label>
                        <input
                            type="password"
                            className="form-control login-input"
                            id="password"
                            placeholder="Password" />
                    </div>
                    <button
                        type="submit"
                        id="btn-login"
                        className="btn btn-primary btn-block login-btn">
                        LOGIN
                    </button>
                </form>
            </div>
        </div>
    );
}