import { createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import 'rc-time-picker/assets/index.css';
import React from "react";
import { useUpdateWeeklyPlan } from '../../api/settingService';
import useCustomTranslation from '../../container/useCustomTranslation';
import { convertWeekModelToUTC, getWeeklyModelToDisplay } from "../../utils/eventUtils";
import Loader from "../Loader";
import { SaveButton } from "./AddBtn";

export default function BusinessHours(props: any) {
  const classes = useStyles();
  const { org, refreshFn, errPopup } = props;
  const updateSettings = useUpdateWeeklyPlan(refreshFn, errPopup);
  const { t } = useCustomTranslation();
  const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const initValues: any = {
    weeklyPlan: getWeeklyModelToDisplay(org.weeklyPlan)
  }

  return (<Formik
    initialValues={initValues}
    validate={(values) => {
      const errors: any = {};
      return errors;
    }}

    onSubmit={(values, { setSubmitting }) => {
      const data = convertWeekModelToUTC(values.weeklyPlan);
      updateSettings.run({ weeklyPlan: data });
      //alert(JSON.stringify(data, null, 1))
      setSubmitting(false)
    }}
  >
    {({ values, dirty }) => (<Form>
      <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0' }}>
        <Grid item>
          <Typography variant="h6">
            {t('Settings.BusinessHours')} </Typography>
        </Grid>
        <Grid item>
          <SaveButton type="submit" id="save-business-hrs" disabled={!dirty} btnLabel={"Save"} />
        </Grid>
      </Grid>
      <Grid container style={{ overflow: 'hidden' }}>
        <FieldArray name="weeklyPlan">
          {({
            form: { values, setValues, validateForm },
            ...fieldArrayHelpers
          }) =>
            <Table size="small">
              <TableHead style={{ backgroundColor: '#f1f1f1' }}>
                <TableRow>
                  <TableCell align={'left'} className={clsx(classes.headerText, classes.textBoxPadding)}>
                    {t('BusinessHours.Day')}
                  </TableCell>
                  <TableCell align={'center'} className={clsx(classes.headerText, classes.textBoxPadding)}>
                    {t('BusinessHours.BusinessHours')}
                  </TableCell>
                  <TableCell align={'center'} className={clsx(classes.headerText, classes.textBoxPadding)}>
                    {t('BusinessHours.Break')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.weeklyPlan && values.weeklyPlan.map((r: any, i: any) => {
                  const isItemSelected = values.weeklyPlan[i].workingHours ? true : false;
                  return (
                    <TableRow hover tabIndex={-1} key={i}>
                      <TableCell className={classes.textBoxPadding}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={isItemSelected}
                          name={`weeklyPlan[${i}].isWorking`}
                          Label={{ label: r.weekday, style: { marginRight: 0 }, classes: { label: classes.dayLabel } }} />
                      </TableCell>

                      <TableCell align="center" className={classes.textBoxPadding}>
                        <Grid container spacing={isSmallDevices ? 0 : 1} justify="center" className={classes.flexColumn}>
                          <Grid item>
                            <Field type="time" name={`weeklyPlan[${i}].workingHours.startTime`} disabled={!(values.weeklyPlan[i].isWorking)}
                              value={r.workingHours.startTime} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                          </Grid>
                          <Grid item>
                            <label>To</label>
                          </Grid>
                          <Grid item>
                            <Field type="time" name={`weeklyPlan[${i}].workingHours.endTime`} disabled={!(values.weeklyPlan[i].isWorking)}
                              value={r.workingHours.endTime} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center" padding="none">
                        <Grid container spacing={isSmallDevices ? 0 : 1} justify="center" className={classes.flexColumn}>
                          <Grid item>
                            <Field type="time" name={`weeklyPlan[${i}].break.startTime`} disabled={!(values.weeklyPlan[i].isWorking)}
                              value={r.break.startTime} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                          </Grid>
                          <Grid item>
                            <label>To</label>
                          </Grid>
                          <Grid item>
                            <Field type="time" name={`weeklyPlan[${i}].break.endTime`} disabled={!(values.weeklyPlan[i].isWorking)}
                              value={r.break.endTime} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                          </Grid>
                        </Grid>
                      </TableCell>

                    </TableRow>)
                })}
              </TableBody>
            </Table>
          }
        </FieldArray>
      </Grid>
      <Loader useBackdrop open={updateSettings.isLoading} />

    </Form>
    )}
  </Formik>)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      fontWeight: 'bold',
      fontSize: '14px'
    },
    labelText: {
      fontWeight: 600,
      fontSize: '14px'
    },
    textBoxPadding: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px 4px',
      },
      '&:last-child': {
        padding: '10px 4px',
      }
    },
    flexColumn: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    dayLabel: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '.9rem',
      }
    }

  }),
);
