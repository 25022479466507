import { DialogContent, DialogTitle, Drawer } from "@material-ui/core";
import { Cancel } from '@material-ui/icons';
import React from 'react';
import useCustomTranslation from "../../container/useCustomTranslation";
import { MuiAppointmentForm } from './AppointmentForm';


export default function BookingDialog(props: any) {
    const { selectedSlot, duration, datepassed, open, handleClose, onCreate, storeList, storeId, storeData, bulk } = props;
    const { t } = useCustomTranslation();


    return (
        <Drawer
            open={open}
            anchor='right'
            onClose={handleClose}
            PaperProps={{ variant: 'outlined', style: { minWidth: '40vw', maxWidth: '500px', width: '100%' } }}
        >
            <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {t("Calendar.CreateAppointment")}
                    <Cancel onClick={handleClose} />
                </div>
            </DialogTitle>
            <DialogContent style={{ padding: '10px' }}>
                <MuiAppointmentForm
                    selectedSlot={selectedSlot}
                    duration={duration}
                    datepassed={datepassed}
                    onCreate={onCreate}
                    onClose={handleClose}
                    storeList={storeList}
                    storeId={storeId}
                    isBulk={bulk}
                    storeData={storeData} />
            </DialogContent>

        </Drawer>
    )

}