import { Grid } from '@material-ui/core';
import { useField } from 'formik';
import update from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Card } from './Card';

export interface ContainerState {
    cards: any[]
}

export default function DragAndDrop(props: any) {
    const { fieldArrayHelpers, values } = props;
    const [cards, setCards] = useState<any>([]);
    const [, , arHelper] = useField('appointmentReasons');
    useEffect(() => {
        updateCards();
    }, [values.appointmentReasons.length])

    const updateCards = () => {
        const cardArr: any = [];
        values.appointmentReasons.forEach((element: any, i: any) => {
            cardArr.push({
                id: i + 1,
                text: element
            })
        });
        setCards(cardArr);
    }
    // useEffect(() => {
    //     const arVal = cards.map(c => c.text);
    //     arHelper.setValue(arVal);
    // },[cards]);

    const moveCard = (id: any, atIndex: number) => {
        const { card, index } = findCard(id)
        const updateC = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
        setCards(updateC);
        // const arVal = updateC.map((c: any) => c.text);
        // arHelper.setValue(arVal);
    }

    const findCard = (id: string) => {
        const card = cards.filter((c: any) => `${c.id}` === id)[0]
        return {
            card,
            index: cards.indexOf(card),
        }
    }

    const updateOrder = () => {
        const arVal = cards.map((c: any) => c.text);
        arHelper.setValue(arVal);
        updateCards();
    }


    const [, drop] = useDrop({ accept: 'card' })
    return (
        <Grid container item xs={12} alignItems="center" ref={drop}>
            {cards.map((card: any, index: any) => (
                <Card
                    key={card.id}
                    id={`${card.id}`}
                    text={card.text}
                    moveCard={moveCard}
                    findCard={findCard}
                    updateOrder={updateOrder}
                    updateCards={updateCards}
                    fieldArrayHelpers={fieldArrayHelpers}
                    index={index}
                />
            ))}
        </Grid>
    )
}
