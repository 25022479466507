import {
    createStyles, Grid, makeStyles, Table, TableBody, TableCell,
    TableHead, TableRow, Theme, Typography, useMediaQuery
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useUpdateTerminology } from '../../api/settingService';
import useCustomTranslation from '../../container/useCustomTranslation';
import Loader from "../Loader";
import { SaveButton } from "./AddBtn";



export default function Terminology(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const padding = isSmallDevices ? 'none' : 'default';
    const { org, refreshFn } = props;

    const updateSettings = useUpdateTerminology(refreshFn);

    const initialValues = {
        ...org.terminology
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: any, { setSubmitting }) => {
                setSubmitting(false);
                updateSettings.run({ Terminology: values });
            }}
            validate={(values) => {
                const errors: any = {};
                return errors;
            }}
        >
            {({ values, dirty }) => (<Form>
                <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0', marginBottom: '8px' }}>
                    <Grid item>
                        <Typography variant="h6">
                            {t('Settings.Terminology')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <SaveButton type="submit" id="save-basic-settings" btnLabel={"Save"}
                            disabled={!dirty} />
                    </Grid>
                </Grid>

                <Loader useBackdrop open={updateSettings.isLoading} />
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align='left' padding={padding} className={classes.headerText}>
                                {t('Terminology.Term')}
                            </TableCell>
                            <TableCell align='left' className={classes.headerText}>
                                {t('Terminology.Singular')}
                            </TableCell>
                            <TableCell align='left' className={classes.headerText}>
                                {t('Terminology.Plural')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                                {t("Terminology.Org")}
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="organization.singular" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>

                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="organization.plural" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                                {t("Terminology.Location")}
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="location.singular" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>

                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="location.plural" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                                {t("Terminology.Employee")}
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="employee.singular" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>

                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="employee.plural" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                                {t("Terminology.Customer")}
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="customer.singular" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>

                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="customer.plural" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                                {t("Terminology.Host")}
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="host.singular" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>

                            <TableCell align='left' className={classes.textBoxPadding}>
                                <Field component={TextField} name="host.plural" type="text"
                                    required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Form>)
            }
        </Formik >
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerText: {
            fontWeight: 'bold',
            fontSize: '14px',
            backgroundColor: '#f1f1f1'
        },
        labelText: {
            fontWeight: 600,
            fontSize: '14px'
        },
        textBoxPadding: {
            [theme.breakpoints.down('xs')]: {
                padding: '4px',
            },
            '&:last-child': {
                paddingRight: '4px',
            }
        }
    }),
);

