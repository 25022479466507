
import { AppBar, Grid, makeStyles, Theme, Toolbar, useMediaQuery } from '@material-ui/core';
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import OrgContext from "../container/OrgContext";
import { diffInDays } from '../utils/eventUtils';
import Menubar from './Menubar';
import ExpiryAlert from './Settings/subscriptionComponent/ExpiryAlert';


const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        minHeight: 60,
        maxHeight: 60,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px'
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },
    toolbarExpire: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 80,
            maxHeight: 80,
            padding: '4px',

        }
    }
}));


function Header(props: any) {
    const { org } = props;
    const { name } = org;
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <>
            <Grid container alignItems="center" spacing={isSmallDevices ? 0 : 1} direction='row'>
                <Grid item container xs justify="flex-start">
                    <Link to="/home" style={{ outline: 'none', display: 'flex' }}>
                        <img src={process.env.REACT_APP_LOGO} height="49px" alt={'Proappointments'} />
                    </Link>
                </Grid>
                {org && !isSmallDevices && <Grid item>
                    <ExpiryAlert currentPlan={org.subscriptionPlan} />
                </Grid>}
                <Grid item container xs justify="flex-end">
                    <Menubar username={name} />
                </Grid>
                {org && isSmallDevices && <ExpiryAlert currentPlan={org.subscriptionPlan} />}
            </Grid>
        </>
    );

}

export default function Topbar(props: any) {
    const classes = useStyles();
    const [org] = useContext(OrgContext);
    const expiryDate = org && org.subscriptionPlan ? org.subscriptionPlan.expiryDate : undefined;
    const diff = diffInDays(expiryDate);
    return (
        <>
            <AppBar position="fixed">
                <Toolbar className={`${classes.toolbar} ${diff.isBelowWeek ? classes.toolbarExpire : undefined}`}>
                    <Header org={org} />
                </Toolbar>
            </AppBar>
            <Toolbar className={`${classes.toolbar} ${diff.isBelowWeek ? classes.toolbarExpire : undefined}`} />
        </>
    )
}