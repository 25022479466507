import {
    Button,
    createStyles, DialogContent, DialogTitle,
    Divider, Drawer,
    Grid, InputAdornment, InputLabel, makeStyles, Theme
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React, { useContext, useLayoutEffect, useState } from 'react';
import client from '../../api/api-client';
import { useAddStore, useDeleteStore, useUpdateStore } from '../../api/storeService';
import { ModalContext } from '../../container/ModalContainer';
import useCustomTranslation from '../../container/useCustomTranslation';
import { convertWeekModelToUTC, getWeeklyModelToDisplay, getCurrency, getCurrencySymbol } from '../../utils/eventUtils';
import { PaymentGatewayType } from '../../utils/types';
import { PhoneNumberField } from '../FormFields/PhoneNumberField';
import Loader from '../Loader';
import StoreHolidays from '../Store/StoreHolidays';
import StoreBusinessHours from '../Store/StoreWorkingHr';
import CountryCode from './CountryCode';
const STORE_ENDPOINT = "locations";

interface StoreFormProps {
    type: 'add' | 'edit' | 'view' | undefined;
    id?: string;
    selected?: any;
    handleClose: any;
    open: boolean;
    title: string;
    org: any;
}
export default function StoreForm(props: StoreFormProps) {
    const { open, handleClose, title, selected, type } = props;

    const [serStore, setStoreDetails] = useState();
    useLayoutEffect(() => {
        if (selected) {
            fetchStoreDetails(selected.id)
                .then(r => setStoreDetails(r));
        }
    }, []);
    return (
        <Drawer open={open} onClose={handleClose}
            PaperProps={{ variant: 'outlined', style: { minWidth: '50vw', maxWidth: '700px', width: '100%' } }}
            anchor='right'>

            <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title}
                    <Cancel id="cloz-aptms" onClick={handleClose} /></div>
            </DialogTitle>
            <DialogContent style={{ padding: '10px' }}>
                {(type === 'add' || serStore) ?
                    <StoreInputs handleClose={props.handleClose} type={type} org={props.org} selected={serStore} /> :
                    <Loader useBackdrop open={true} />
                }
            </DialogContent>
        </Drawer>
    );
}
const fetchStoreDetails = (id: string) => {
    return client(`${STORE_ENDPOINT}/${id}`)
}
function StoreInputs(props: any) {
    const classes = useStyles();
    const { selected, handleClose, type, org } = props;
    const currency = getCurrency(org.countryCode);
    const currencySymbol = getCurrencySymbol(currency);
    const { t } = useCustomTranslation();
    const isReadOnly = type === 'view' || type === 'delete';
    const alertHelper = useContext(ModalContext);

    const closeFlyout = () => {
        handleClose(null, true);
    }
    const alertErr = (err: any) => {
        if (err && err.ErrorMessages) {
            alertHelper.showErr(err.ErrorMessages[0]);
        } else {
            alertHelper.showErr("Error occurred while saving data");
        }
    }
    const updateStore = useUpdateStore(closeFlyout, alertErr);
    const addStore = useAddStore(closeFlyout, alertErr);
    const deleteStore = useDeleteStore(closeFlyout, alertErr);

    const updateSettings = type === 'edit' ? updateStore : addStore;
    const initialValues = {
        name: selected ? selected.name : '',
        email: selected ? selected.email : '',
        mobilePhone: selected ? selected.mobilePhone : '',
        address: selected ? selected.address : '',
        countryCode: selected ? selected.countryCode : '',
        country: selected ? selected.country : '',
        useOrganizationContactDetails: selected ? selected.useOrganizationContactDetails : true,
        useOrganizationWeeklyPlan: selected ? selected.useOrganizationWeeklyPlan : true,
        useOrganizationHolidays: selected ? selected.useOrganizationHolidays : true,
        isLocationVisitEnabled: selected ? selected.isLocationVisitEnabled : false,
        isHomeVisitEnabled: selected ? selected.isHomeVisitEnabled : false,
        isVideoCallEnabled: selected ? selected.isVideoCallEnabled : false,

        homeVisitsCapacity: selected ? selected.homeVisitsCapacity : 0,
        locationCapacity: selected ? selected.locationCapacity : 0,
        videoCallsCapacity: selected ? selected.videoCallsCapacity : 0,

        homeAppointmentDuration: selected ? selected.homeAppointmentDuration : 0,
        locationAppointmentDuration: selected ? selected.locationAppointmentDuration : 0,
        videoAppointmentDuration: selected ? selected.videoAppointmentDuration : 0,

        //Advanced Payment unit rates
        locationAppointmentUnitRate: selected ? selected.locationAppointmentUnitRate : 0,
        videoAppointmentUnitRate: selected ? selected.videoAppointmentUnitRate : 0,
        homeAppointmentUnitRate: selected ? selected.homeAppointmentUnitRate : 0,


        weeklyPlan: selected && selected.weeklyPlan ?
            getWeeklyModelToDisplay(selected.weeklyPlan) : getWeeklyModelToDisplay(org.weeklyPlan),
        holidays: selected && selected.holidays ? selected.holidays : org.holidays ?
            org.holidays : [],
    };

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                let errors: any = {};
                if (!values.name || values.name.trim() === '') {
                    errors.name = 'Required';
                }
                if (values.useOrganizationContactDetails) {
                    errors = {};
                    values.email = "";
                    values.mobilePhone = "";
                }
                if (!values.useOrganizationContactDetails && !values.email) {
                    errors.email = 'Required';
                } else if (!values.useOrganizationContactDetails && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                    errors.email = 'Invalid email address';
                }
                if (!values.useOrganizationContactDetails && !values.mobilePhone) {
                    errors.mobilePhone = 'Required';
                } else if (!values.useOrganizationContactDetails &&
                    !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.mobilePhone)) {
                    errors.mobilePhone = 'Invalid mobile number'
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                if (type === 'delete') {
                    deleteStore.run(selected.id);
                } else {
                    if (values.useOrganizationContactDetails) {
                        values.email = ""
                        values.mobilePhone = ""
                    }
                    if (values.useOrganizationHolidays) {
                        values.holidays = []
                    }
                    if (values.useOrganizationWeeklyPlan) {
                        values.weeklyPlan = [];
                    } else {
                        values.weeklyPlan = convertWeekModelToUTC(values.weeklyPlan);
                    }
                    values.name = values.name.trim()
                    if (selected) {
                        updateSettings.run(values, selected.id)
                    } else {
                        updateSettings.run(values)
                    }
                }
            }}>
            {({ values, dirty }) => (<Form>
                <Loader useBackdrop open={updateSettings.isLoading || deleteStore.isLoading} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Field component={TextField} name="name" type="text" label={t("StoreSettings.Name")}
                            required variant="outlined" size="small" disabled={isReadOnly} color="secondary" fullWidth />
                    </Grid>
                    <Grid item xs={10}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.useOrganizationContactDetails}
                            classes={{ root: classes.checkboxes }} name="useOrganizationContactDetails"
                            Label={{ label: t("StoreSettings.UseOrgContact") }} disabled={isReadOnly} />
                    </Grid>
                    {!values.useOrganizationContactDetails && <Grid container spacing={1} className={classes.textMargin}>
                        <Grid item xs={12}>
                            <Field component={TextField} name="email" type="email" label={t("StoreSettings.Email")}
                                variant="outlined" size="small" color="secondary" disabled={isReadOnly}
                                fullWidth required={!values.useOrganizationContactDetails} />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneNumberField name="mobilePhone" type="text" label={t("StoreSettings.Phone")}
                                variant="outlined" size="small" color="secondary" disabled={isReadOnly}
                                fullWidth required={!values.useOrganizationContactDetails} />
                        </Grid>
                        <Grid item xs={12}>
                            <Field component={TextField} name="address" type="text" label={t("StoreSettings.Address")}
                                variant="outlined" size="small" multiline rows={2} disabled={isReadOnly}
                                color="secondary" fullWidth required={!values.useOrganizationContactDetails} />
                        </Grid>
                        <Grid item xs={12}>
                            <CountryCode required={!values.useOrganizationContactDetails} disabled={isReadOnly} />
                        </Grid>
                    </Grid>}
                    <Grid item xs={10}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.useOrganizationWeeklyPlan}
                            classes={{ root: classes.checkboxes }} name="useOrganizationWeeklyPlan"
                            Label={{ label: t("StoreSettings.UseOrgBusinessHrs") }} disabled={isReadOnly} />
                    </Grid>
                    {!values.useOrganizationWeeklyPlan && <Grid item xs={12} className={classes.textMargin}>
                        <StoreBusinessHours org={org} isReadOnly={isReadOnly} />
                    </Grid>}
                    <Grid item xs={10}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.useOrganizationHolidays}
                            name="useOrganizationHolidays" Label={{ label: t("StoreSettings.UseOrgHolidays") }}
                            classes={{ root: classes.checkboxes }} disabled={isReadOnly} />
                    </Grid>
                    {!values.useOrganizationHolidays && <Grid item xs={12} className={classes.textMargin}>
                        <StoreHolidays storeHolidays={values.holidays} isReadOnly={isReadOnly} />
                    </Grid>}
                    {org.isLocationVisitEnabled ? <> <Grid item xs={12}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.isLocationVisitEnabled}
                            classes={{ root: classes.checkboxes }} name="isLocationVisitEnabled"
                            Label={{ label: t("StoreSettings.EnableStoreVisit") }} disabled={isReadOnly} />
                    </Grid>
                        { // Show capacity input
                            org.locationEnforceAppointmentsCapacity && values.isLocationVisitEnabled &&
                            <Grid container item xs={12} spacing={1} className={classes.textMargin}
                                alignItems="center" justify="flex-start">
                                <Grid item>
                                    <InputLabel htmlFor="aptmt-slot">
                                        {t("StoreSettings.StoreCapacity")}
                                    </InputLabel>
                                </Grid>
                                <Grid item>
                                    <Field component={TextField} className={classes.numberTextInput} name="locationCapacity"
                                        type="number" inputProps={{ min: 1, style: { padding: '4px 4px 4px 8px' } }} required variant="outlined" size="small" color="secondary"
                                        fullWidth={false} disabled={isReadOnly} />
                                </Grid>
                            </Grid>
                        }

                        { // Show location meeting duration
                            values.isLocationVisitEnabled && <Grid item xs={12} className={classes.textMargin}>
                                <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                                    <Grid item>
                                        <InputLabel htmlFor="aptmt-slot">
                                            {t("StoreSettings.AppointmentSlot")}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <Field component={TextField} className={classes.numberTextInput} type="number" variant="outlined"
                                            inputProps={{ min: 15, step: 15, style: { padding: '4px 4px 4px 8px' } }} name="locationAppointmentDuration" required
                                            size="small" color="secondary" disabled={isReadOnly} />
                                    </Grid>
                                    <Grid item>
                                        {t("AppointmentSettings.Mins")}
                                    </Grid>
                                </Grid>
                            </Grid>}
                        {org.paymentGatewaySettings && org.paymentGatewaySettings.paymentGatewayType !== PaymentGatewayType.None ? <>
                            { //Show advanced payment unit rate-videocall
                                values.isLocationVisitEnabled && <Grid item xs={12} className={classes.textMargin}>
                                    <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                                        <Grid item>
                                            <InputLabel htmlFor="aptmt-slot">
                                                {t("StoreSettings.LocationAppointmentUnitRate")}
                                            </InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <Field component={TextField} type="number" variant="outlined"
                                                inputProps={{ min: 0, step: 0.01, style: { padding: '4px 4px 4px 8px', width: '60px' } }} name="locationAppointmentUnitRate" required
                                                size="small" color="secondary" disabled={isReadOnly}
                                                InputProps={{
                                                    style: { padding: '0px 4px' },
                                                    startAdornment: <InputAdornment position="start" style={{ margin: '0px', padding: '4px' }}>
                                                        {currencySymbol}
                                                    </InputAdornment>
                                                }} />
                                        </Grid>
                                        <Grid item>
                                            {org.countryCode !== 'in' ? currency : ""}
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </> : null}
                    </> : null}

                    {org.isVideoCallEnabled ? <> <Grid item xs={12}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.isVideoCallEnabled}
                            classes={{ root: classes.checkboxes }} name="isVideoCallEnabled"
                            Label={{ label: t("StoreSettings.EnableVideoCalls") }} disabled={isReadOnly} />
                    </Grid>
                        { // Show capacity input
                            org.videoEnforceAppointmentsCapacity && values.isVideoCallEnabled &&
                            <Grid container item xs={12} spacing={1} className={classes.textMargin}
                                alignItems="center" justify="flex-start">
                                <Grid item>
                                    <InputLabel htmlFor="aptmt-slot">
                                        {t("StoreSettings.VideoCallCapacity")}
                                    </InputLabel>
                                </Grid>
                                <Grid item>
                                    <Field component={TextField} className={classes.numberTextInput} name="videoCallsCapacity"
                                        type="number" inputProps={{ min: 1, style: { padding: '4px 4px 4px 8px' } }} required
                                        variant="outlined" size="small" color="secondary"
                                        fullWidth={false} disabled={isReadOnly} />
                                </Grid>
                            </Grid>
                        }
                        { // Show video meeting duration
                            values.isVideoCallEnabled && <Grid item xs={12} className={classes.textMargin}>
                                <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                                    <Grid item>
                                        <InputLabel htmlFor="aptmt-slot">
                                            {t("StoreSettings.AppointmentSlot")}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <Field component={TextField} className={classes.numberTextInput} type="number" variant="outlined"
                                            inputProps={{ min: 15, step: 15, style: { padding: '4px 4px 4px 8px' } }} name="videoAppointmentDuration" required
                                            size="small" color="secondary" disabled={isReadOnly} />
                                    </Grid>
                                    <Grid item>
                                        {t("AppointmentSettings.Mins")}
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {org.paymentGatewaySettings && org.paymentGatewaySettings.paymentGatewayType !== PaymentGatewayType.None ? <>
                            { //Show advanced payment unit rate-videocall
                                values.isVideoCallEnabled && <Grid item xs={12} className={classes.textMargin}>
                                    <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                                        <Grid item>
                                            <InputLabel htmlFor="aptmt-slot">
                                                {t("StoreSettings.VideoAppointmentUnitRate")}
                                            </InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <Field component={TextField} type="number" variant="outlined"
                                                inputProps={{ min: 0, step: 0.01, style: { padding: '4px 4px 4px 8px', width: '60px' } }} name="videoAppointmentUnitRate" required
                                                size="small" color="secondary" disabled={isReadOnly}
                                                InputProps={{
                                                    style: { padding: '0px 4px' },
                                                    startAdornment: <InputAdornment position="start" style={{ margin: '0px', padding: '4px' }}>
                                                        {currencySymbol}
                                                    </InputAdornment>
                                                }} />
                                        </Grid>

                                        <Grid item>
                                            {org.countryCode !== 'in' ? currency : ""}
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </> : null}
                    </> : null}

                    {org.isHomeVisitEnabled ? <> <Grid item xs={12}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.isHomeVisitEnabled}
                            classes={{ root: classes.checkboxes }}
                            name="isHomeVisitEnabled" Label={{ label: t("StoreSettings.EnableHomeVisit") }}
                            disabled={isReadOnly} />
                    </Grid>
                        { // Show capacity input
                            org.homeEnforceAppointmentsCapacity && values.isHomeVisitEnabled &&
                            <Grid container item xs={12} spacing={1} className={classes.textMargin}
                                alignItems="center" justify="flex-start">
                                <Grid item>
                                    <InputLabel htmlFor="aptmt-slot">
                                        {t("StoreSettings.HomeVisitCapacity")}
                                    </InputLabel>
                                </Grid>
                                <Grid item>
                                    <Field component={TextField} className={classes.numberTextInput} name="homeVisitsCapacity"
                                        type="number" inputProps={{ min: 1, style: { padding: '4px 4px 4px 8px' } }} required
                                        variant="outlined" size="small" color="secondary"
                                        fullWidth={false} disabled={isReadOnly} />
                                </Grid>
                            </Grid>
                        }
                        { //Show home meeting duration
                            values.isHomeVisitEnabled && <Grid item xs={12} className={classes.textMargin}>
                                <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                                    <Grid item>
                                        <InputLabel htmlFor="aptmt-slot">
                                            {t("StoreSettings.AppointmentSlot")}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <Field component={TextField} className={classes.numberTextInput} type="number" variant="outlined"
                                            inputProps={{ min: 15, step: 15, style: { padding: '4px 4px 4px 8px' } }} name="homeAppointmentDuration" required
                                            size="small" color="secondary" disabled={isReadOnly} />
                                    </Grid>
                                    <Grid item>
                                        {t("AppointmentSettings.Mins")}
                                    </Grid>
                                </Grid>
                            </Grid>}
                        {org.paymentGatewaySettings && org.paymentGatewaySettings.paymentGatewayType !== PaymentGatewayType.None ? <>

                            { //Show advanced payment unit rate
                                values.isHomeVisitEnabled && <Grid item xs={12} className={classes.textMargin}>
                                    <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                                        <Grid item>
                                            <InputLabel htmlFor="aptmt-slot">
                                                {t('StoreSettings.HomeAppointmentUnitRate')}
                                            </InputLabel>
                                        </Grid>
                                        <Grid item>
                                            <Field component={TextField} type="number" variant="outlined"
                                                inputProps={{ min: 0, step: 0.01, style: { padding: '4px 4px 4px 8px', width: '60px' } }} name="homeAppointmentUnitRate" required
                                                size="small" color="secondary" disabled={isReadOnly}
                                                InputProps={{
                                                    style: { padding: '0px 4px' },
                                                    startAdornment: <InputAdornment position="start" style={{ margin: '0px', padding: '4px' }}>
                                                        {currencySymbol}
                                                    </InputAdornment>
                                                }} />
                                        </Grid>

                                        <Grid item>
                                            {org.countryCode !== 'in' ? currency : ""}
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </> : null}
                    </> : null}

                </Grid>
                <div className={classes.buttons}>
                    <Divider variant="fullWidth" className={classes.hrLine} />

                    {!isReadOnly && <Button id="create-store" variant="contained" type="submit"
                        disabled={!dirty} className={classes.button} color="secondary">
                        {props.type === 'add' ? t('StoreSettings.CreateBtn') : t('StoreSettings.EditBtn')}
                    </Button>}
                    {type === 'delete' && <Button id="delete-store" variant="contained"
                        type="submit" className={classes.button} color="secondary">
                        {t('StoreSettings.DeleteBtn')}
                    </Button>}
                    <Button id="cancel-create-store" onClick={handleClose} className={classes.button} color="inherit" variant="contained">
                        {!isReadOnly ? t('StoreSettings.CancelBtn') : t("StoreSettings.CloseBtn")}
                    </Button>
                </div>
            </Form>)}
        </Formik>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(0.5, 1),
            borderRadius: '3px',
            width: '100px',
            height: '35px',
            fontSize: '16px',
            lineHeight: '19px',
        },
        hrLine: {
            width: '100%',
            margin: theme.spacing(1, 0),
        },
        numberTextInput: {
            width: "60px"
        },
        checkboxes: {
            padding: '4px 8px',
        },
        textMargin: {
            paddingLeft: '32px !important',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '8px !important',
            }
        }

    }));