import { createStyles, Divider, Grid, InputAdornment, makeStyles, TextField, Theme, useTheme } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { FileCopy } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as Expand } from '../../assets/expand-arrow.svg';
import { ModalContext } from '../../container/ModalContainer';
import useCustomTranslation from '../../container/useCustomTranslation';
import { formatPhoneNumber, getColorByEnvStats, getCurrency, getCurrencySuffix, getCurrencySymbol, getTypeStringFromType, humanizeMinsToHr, localeFormattedDate, localeFormattedDateOnly } from '../../utils/eventUtils';
import { Appointment, AppointmentStatus } from '../../utils/types';
import EventButtons from './EventButtons';
import { EventIcon } from './EventIcon';
import { getFileURL }from '../../api/calendarService'
import GetAppIcon from '@material-ui/icons/GetApp';
import OrgContext from '../../container/OrgContext';

interface EventPanelProps {
    event: Appointment;
    expanded?: any;
    handleChange?: any;
    eventActions?: any;
    storeId?: any;
    storeData?: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            border: '1px solid #E9E6EB',
            margin: '10px 0',
            boxShadow: '0px 1px 1px rgba(233, 230, 235, 0.21)',
            borderRadius: '3px'
        },
        heading: {
            fontSize: theme.typography.pxToRem(16),
            marginLeft: theme.spacing(1),
        },
        keyText: {
            color: theme.palette.text.primary,
            fontWeight: 600,
            fontSize: '14px',
            whiteSpace: 'nowrap'
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
            fontSize: '14px',
            color: theme.palette.text.secondary,
            fontWeight: 'normal',
            paddingRight: '4px',
            paddingBottom: '8px'
        },
        button: {
            margin: theme.spacing(1),
            borderRadius: '5px',
            height: '30px',
            fontSize: '14px',
        },
        summery: {
            minHeight: '48px',
            '&.Mui-expanded': {
                minHeight: '48px',
                margin: 0,
            }
        },
        content: {
            alignItems: 'center',
            '&.Mui-expanded': {
                margin: 0,
            }
        },
        subText: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        subTextLink: {
            '&:hover': {
                color: 'black',
                cursor: 'pointer'
            }
        },
        refLink: {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
            }
        },
        downloadLink: {
            cursor: "pointer",
            textDecoration: 'none',
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
            }
        },
        downloadBlock:{
            display: 'flex',
        }

    }),
);


const EventStatus = ({ status }: any) => {
    let style: any = {
        fontSize: '12px',
    };
    const evStyle: AppointmentStatus = status;
    const appliedColor = getColorByEnvStats(evStyle);
    style['color'] = appliedColor;
    return <Typography component="span" style={style}>&nbsp;&nbsp;{`(${status})`}</Typography>
}

const Detail = (props: any) => {
    const classes = useStyles();
    const col = props.xs ? props.xs : 12;
    return (<Grid container item xs={col} spacing={1} {...props} style={{ flexWrap: 'nowrap' }}>
        <Grid item className={classes.keyText}>
            {props.heading}
        </Grid>
        <Grid item className={classes.subText} xs>
            {props.children}
        </Grid>
    </Grid>)
}

function EventPanel(props: EventPanelProps) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { event, expanded, eventActions, storeData } = props;
    const [org] = useContext(OrgContext);
    const alertHelper = useContext(ModalContext);
    const theme = useTheme();
    const downloadFile = (id:any ,fileId: any, fileName:any) =>{
        getFileURL(id,fileId)
        .then(response => {
            let a = document.createElement('a');
            a.style.display = 'none';
            a.href = response;
            a.target = "_blank";
            a.download = fileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        });
    };
    const showValue = (field: any) => {
        if(field.textValue){
            if(field.textValue.includes("|")){
                // it is a file field
                const fileValue = field.textValue.split('|');
                const fileName = fileValue[0];
                const fileId = fileValue[1];
                if (fileName){
                    return (<div className={classes.downloadBlock}>
                            <span>{fileName}</span>
                            <a className={classes.downloadLink} onClick={(e:any) => downloadFile(event.id,fileId,fileName)}>
                                <GetAppIcon fontSize='small' style={{marginLeft:'10px'}} />
                            </a>
                        </div>)
                }
                return(<></>)
            }
            return field.textValue;
        }
        if(field.numericValue){
            return field.numericValue;
        }
        if(field.dateValue){
            return localeFormattedDateOnly(field.dateValue);
        }
        return "";
    }
    const isExpanded = expanded === true || expanded === 'panel' + event.id;
    const currency = getCurrency(org.countryCode);

    return (
        <div className={clsx(classes.root, 'test-evnt-panel')}>
            <ExpansionPanel expanded={isExpanded}
                onChange={props.handleChange('panel' + event.id)}>
                <ExpansionPanelSummary className={clsx(classes.summery, 'test-summary-panel')} classes={{ content: classes.content }}
                    expandIcon={<Expand className={`test-expand-${isExpanded}`} fill={isExpanded ? theme.palette.secondary.main : "#8C8C8C"} />}>
                    <Grid container alignItems="center">
                        <EventIcon type={event.type} fill={isExpanded ? "#1E2226" : "#8C8C8C"} />
                        <Typography component="span" className={classes.heading}>
                            {event.customer.name}
                            <EventStatus status={event.status} />
                        </Typography>
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    <Grid container spacing={1} xs={12}>
                        <Detail heading={t("AppointmentDetails.Email")}>
                            <a href={`mailto:${event.customer.email}`} className={classes.refLink}>
                                {event.customer.email}
                            </a>
                        </Detail>
                        <Detail heading={t("AppointmentDetails.Phone")}>
                            <a href={`tel:${event.customer.mobilePhone}`} className={classes.refLink}>
                                {formatPhoneNumber(event.customer.mobilePhone)}
                            </a>
                        </Detail>
                        <Detail heading={t("AppointmentDetails.Type")}>
                            {t(getTypeStringFromType(event.type))}
                        </Detail>
                        <Detail heading={t("AppointmentDetails.Status")}>
                            <span style={{ color: getColorByEnvStats(event.status), fontWeight: 'bold' }}>
                                {event.status}
                            </span>
                        </Detail>
                        <Detail heading={t("AppointmentDetails.Time")} sm={7}>
                            {localeFormattedDate(event.appointmentTime)}
                            {/* {moment.utc(event.appointmentTime).local().format('DD-MM-YYYY hh:mm A')} */}
                        </Detail>
                        <Detail heading={t("AppointmentDetails.Duration")} sm={5}>
                            {humanizeMinsToHr(event.appointmentDuration)}
                        </Detail>
                        {event.appointmentPrice > 0 ?
                            <Detail heading={t("AppointmentDetails.Paid")}>
                                <span>
                                    {getCurrencySymbol(currency)}
                                    {event.appointmentPrice}{' '}
                                    {getCurrencySuffix(currency)}
                                </span>
                            </Detail>
                        : null}
                        <Detail heading={t("AppointmentDetails.Host")}>
                            {event.host ? event.host.name : '-'}
                        </Detail>
                        <Detail heading={t("AppointmentDetails.Message")}>
                            {event.reason ? event.reason : '-'}
                        </Detail>
                        {event.joinUrl &&
                            <Detail heading={t("AppointmentDetails.ClientURL")}>
                                <TextField size="small" value={event.joinUrl} disabled fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ height: 'auto' }} className={classes.subTextLink}>
                                                <CopyToClipboard text={event.joinUrl} onCopy={() => alertHelper.showDone(t('Alerts.LinkCopy'))}>
                                                    <FileCopy fontSize="small" />
                                                </CopyToClipboard>
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                    }}
                                    inputProps={{
                                        style: { padding: 0, textOverflow: 'ellipsis', fontSize: '14px' },
                                        className: classes.refLink
                                    }}
                                />

                            </Detail>}
                        {event.status === AppointmentStatus.Cancelled &&
                            <Detail heading={t("AppointmentDetails.CancelReason")}>
                                {event.cancellationReason}
                            </Detail>
                        }
                        {event.status === AppointmentStatus.Rejected &&
                            <Detail heading={t("AppointmentDetails.RejectReason")}>
                                {event.rejectionReason}
                            </Detail>
                        }
                        {event.status === AppointmentStatus.Completed &&
                            <Detail heading={t("AppointmentDetails.CompletionNotes")}>
                                {event.completionNotes}
                            </Detail>
                        }
                        {event.formFields && event.formFields.length > 0 && <Divider variant="fullWidth" style={{width:'100%'}}/>}
                        {event.formFields && event.formFields.length > 0 && event.formFields.map((field: any) => (
                            <Detail heading={`${field.name} :`}>
                                {showValue(field)}
                            </Detail>
                        ))}
                    </Grid>
                </ExpansionPanelDetails>
                <Divider variant="fullWidth" />
                {eventActions && <ExpansionPanelActions style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <EventButtons event={event} eventActions={eventActions} storeData={storeData} />
                </ExpansionPanelActions>}
            </ExpansionPanel>
        </div>
    );
}
export default React.memo(EventPanel);
