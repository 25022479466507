import { Grid, InputAdornment, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
//@ts-ignore
import { allCountries } from 'material-ui-phone-number/src/country_data';
import React from 'react';
import useCustomTranslation from '../../container/useCustomTranslation';

export default function CountryCode(props: any) {
    const { t } = useCustomTranslation();
    const [countryCodeField, , countryCodeHelpers] = useField('countryCode');
    const [, , countryNameHelpers] = useField('country');

    return (
        <Autocomplete
            id="country-code"
            size="small"
            fullWidth
            disabled={props.disabled}
            value={allCountries.find((c: any) => c.iso2 === countryCodeField.value)}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(option: any, value: any) => { return option.iso2 === value.iso2 }}
            options={allCountries}
            renderOption={(country) => (
                <Grid container alignItems="center">
                    <span className={`flag ${country.iso2} margin`} />
                    {country.name} ({country.iso2}) +{country.dialCode}
                </Grid>
            )}
            onChange={(event, newValue) => {
                if (newValue) {
                    countryCodeHelpers.setValue(newValue.iso2);
                    countryNameHelpers.setValue(newValue.name);
                }
            }}
            renderInput={(params) => (
                <TextField {...params}
                    color="secondary"
                    label={t('OrgSettings.CountryCode')}
                    variant="outlined"
                    required={props.required}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment:
                            (<InputAdornment position="end" style={{ paddingRight: '4px' }}>
                                <div className={`flag ${countryCodeField.value}`} />
                            </InputAdornment>),

                    }}

                />
            )}
        />
    );
}
