import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { Add, Check, Clear, Delete, Edit } from "@material-ui/icons";
import { Field, Formik, getIn, useField } from "formik";
import { TextField } from "formik-material-ui";
import MaterialTable, { MTableEditField, MTableEditRow, MTableToolbar } from 'material-table';
import moment from 'moment';
import React, { forwardRef, useRef, useState } from "react";
import { getLocalTimeFormat, getUTCTimeFormat } from "../../utils/eventUtils";
import Loader from "../Loader";


export default function StoreHolidays(props: any) {
    const tableRef = useRef(null);
    const { storeHolidays, isReadOnly } = props;
    const initialData = storeHolidays;
    const [holidays, setHolidays] = useState(initialData);
    const classes = useStyles();
    const [, , helpers] = useField("holidays")
    const { setValue } = helpers;

    const handleHolidayUpdate = (data: any, isAdd: boolean) => {
        //  updateSettings.run({ Holidays: data });
        setValue(data);
        setHolidays(data);
    }


    const FormikEditRow = ({ onEditingApproved, ...props }: any) => {
        const initialValues = {
            date: props.data ? moment(props.data.date).format('YYYY-MM-DD') : '',
            reason: props.data.reason,
            workingHours: props.data.workingHours ? {
                startTime: getLocalTimeFormat(props.data.workingHours.startTime),
                endTime: getLocalTimeFormat(props.data.workingHours.endTime)
            } : { startTime: "", endTime: "" },
            break: props.data.break ? {
                startTime: getLocalTimeFormat(props.data.break.startTime),
                endTime: getLocalTimeFormat(props.data.break.endTime)
            } : { startTime: "", endTime: "" }
        }

        return (
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: any = {};
                    if (!values.date || !moment(values.date).isValid()) {
                        errors.date = "Required";
                    }
                    if (!values.reason || values.reason.trim() === '') {
                        errors.reason = "Required";
                    }
                    return errors;
                }}
                onSubmit={(data: any) => {
                    if (props.mode === "add" || props.mode === "update") {
                        const { tableData, ...newData } = data;
                        const updateData = {
                            date: newData.date,
                            reason: newData.reason.trim(),
                            workingHours: newData.workingHours.startTime ?
                                {
                                    startTime: getUTCTimeFormat(newData.workingHours.startTime),
                                    endTime: getUTCTimeFormat(newData.workingHours.endTime)
                                } :
                                undefined,
                            break: newData.break.startTime ?
                                {
                                    startTime: getUTCTimeFormat(newData.break.startTime),
                                    endTime: getUTCTimeFormat(newData.break.endTime)
                                } :
                                undefined,
                        }
                        onEditingApproved(props.mode, updateData, props.data);
                    } else {
                        onEditingApproved(props.mode, data, props.data);
                    }
                }}>
                {({ submitForm }: any) => (
                    <MTableEditRow {...props} onEditingApproved={submitForm} />
                )}
            </Formik>
        );
    };

    const FormikEditField = (props: any) => (
        <Field name={props.columnDef.field}>
            {({ field, form }: any) => {
                const { name } = field;
                const { errors, setFieldValue } = form;

                const showError = !!getIn(errors, name);

                return (
                    <div>
                        <MTableEditField
                            {...props}
                            {...field}
                            error={showError}
                            onChange={(newValue: any) => setFieldValue(name, newValue)}
                        />
                        {errors[field.name] && (
                            <div style={{ color: "#f44336", clear: "both" }}>{errors[field.name]}</div>
                        )}
                    </div>
                );
            }}
        </Field>);


    return (
        <Grid>
            <MaterialTable
                tableRef={tableRef}
                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    paging: false,
                    sorting: false,
                    draggable: false,
                    showTitle: false,
                    toolbar: !isReadOnly,
                    headerStyle: {
                        backgroundColor: '#f1f1f1',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        padding: '8px',
                        paddingLeft: '4px',
                    },
                }}
                //localization={{ body: { editRow: { deleteText: 'Customized Delete Message' } } }}
                columns={[
                    {
                        title: 'Date', field: 'date', type: "date", cellStyle: { padding: '4px' },
                        render: (rowData: any) => <div>{new Date(rowData.date).toLocaleDateString()}</div>,
                        editComponent: props => (
                            <Field type="date" name="date" required component={TextField}
                                variant="outlined" size="small" color="secondary" />
                        )
                    },
                    {
                        title: 'Reason', field: 'reason', type: "string", cellStyle: { padding: '4px' },
                        editComponent: props => (<Field component={TextField} name="reason" type="text"
                            required variant="outlined" size="small" color="secondary" fullWidth />)
                    },
                    {
                        title: 'Hours', field: 'workingHours', cellStyle: { padding: '4px' },
                        render: (rowData: any) => <Timer workinghr={rowData.workingHours} />,
                        editComponent: props => (
                            <TimerEdit  {...props} editType="workingHours" />
                        )
                    },
                    {
                        title: 'Break', field: 'break', cellStyle: { padding: '4px' },
                        render: (rowData: any) => <Timer workinghr={rowData.break} />,
                        editComponent: props => (
                            <TimerEdit  {...props} editType="break" />
                        )
                    },
                ]}
                data={holidays}
                editable={isReadOnly ? {} : {
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                handleHolidayUpdate([...holidays, newData], true);

                                resolve();
                            }, 100)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...holidays];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                handleHolidayUpdate([...dataUpdate], false);

                                resolve();
                            }, 100)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...holidays];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                handleHolidayUpdate([...dataDelete], false);

                                resolve()
                            }, 100)
                        }),
                }}
                icons={{
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Add: forwardRef((props, ref) => {
                        return (<Grid container spacing={1} alignItems="center"> <Add {...props} ref={ref} />
                            <Typography variant="subtitle1">Holiday</Typography>
                        </Grid>)
                    }),
                }}
                components={{
                    EditRow: FormikEditRow,
                    EditField: FormikEditField,
                    Container: props => <Paper {...props} elevation={0} />,
                    OverlayLoading: props => <Loader useBackdrop open={true} />,
                    Toolbar: (props) => <div className={classes.toolbarWrapper}><MTableToolbar {...props} /></div>,
                }}
                //@ts-ignore
                initialFormData={{
                    date: "",
                    reason: "",
                    workingHours: undefined,
                    break: undefined
                }}
            />
            {/* {updateSettings.error && <div>{JSON.stringify(updateSettings.error, null, 1)}</div>} */}
        </Grid>
    )
}

function TimerEdit(props: any) {
    const { editType } = props;

    return (
        <Grid container>
            <Grid item>
                <Field type="time" name={editType === 'break' ? 'break.startTime' : 'workingHours.startTime'} />
            </Grid>
            <Grid item>
                <label>To</label>
            </Grid>
            <Grid item>
                <Field type="time" name={editType === 'break' ? 'break.endTime' : 'workingHours.endTime'} />
            </Grid>
        </Grid>);
}
function Timer(props: any) {
    const { workinghr } = props;
    if (!workinghr) {
        return (
            <Grid container spacing={1}>
                -
            </Grid>
        );

    }
    return (
        <Grid container spacing={1}>
            <Grid item>
                <div>{moment.utc(`${workinghr.startTime}:00`, 'HH:mm').local().format("HH:mm")}</div>
            </Grid>
            <Grid item>
                <label>To</label>
            </Grid>
            <Grid item>
                <div>{moment.utc(`${workinghr.endTime}:00`, 'HH:mm').local().format("HH:mm")}</div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    toolbarWrapper: {
        '& .MuiToolbar-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
            lineHeight: 'normal',
            minHeight: 0
        },
        '& .MuiIconButton-root': {
            padding: 0,
        }
    },
}))