import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
     Grid, Theme, createStyles, TableRow
} from '@material-ui/core';
import { Field } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as Delete } from '../../../assets/delete.svg';
import { CustomFormInputValueType } from '../../../utils/types'
import { CustomSelectField }from './CustomSelectField'
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MuiTableCell from "@material-ui/core/TableCell";
import { setOptionsViewArray } from './CustomFormHelper'

interface Item {
    type: string
    id: string
    originalIndex: string
}

const TableCell = withStyles({
    root: {
      borderBottom: "none",
      padding: '5px 0px',
    }
  })(MuiTableCell);

export default function Card({ id, data, moveCard, findCard, index, fieldArrayHelpers, updateOrder, updateCards, fieldName, values, setOptionRows, optionRows }: any) {
    const classes = useStyles();
    const originalIndex = findCard(id).index
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: 'card', id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => {
            CloseAllOptionRows(setOptionRows, optionRows);
            updateCards();
        },
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem()
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                moveCard(droppedId, originalIndex)
            }
            if (didDrop) {

                updateOrder();
            }
        },
        canDrag: true
    })

    const [, drop] = useDrop({
        accept: 'card',
        canDrop: () => false,
        hover({ id: draggedId }: Item) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id)
                moveCard(draggedId, overIndex)
                //  updateOrder();
            }
        },
    })

    const deleteCard = (index: any) => {
        fieldArrayHelpers.remove(index)
    }
    const opacity = isDragging ? 0.8 : 1;
    const showHideOptionRow = (index: any)=>{
        let rowdata = [...optionRows];
        rowdata[index] = optionRows[index] ? false : true ;
        setOptionRows(rowdata);
    }
    return (<>
        <TableRow ref={(node) => drag(drop(node))} style={{ backgroundColor: 'white', opacity, margin: '0px' }}>
            <TableCell style={{width:'20px'}}>
                {values.formFields[index] && values.formFields[index].fieldType === CustomFormInputValueType.Dropdown ? 
                    <Grid className={classes.ActionIcons} >
                        {optionRows[index] ? <ExpandLessIcon fontSize="small" onClick={() => showHideOptionRow(index)} 
                        style={{color:'#7719aa'}}/> :
                        <ExpandMoreIcon fontSize="small" onClick={() => showHideOptionRow(index)} />}
                    </Grid>:
                    <Grid style={{width:'35px'}}></Grid>
                }
            </TableCell>
            <TableCell>
                <Field component={TextField} name={`${fieldName}.${index}.name`} fullWidth
                    required variant="outlined" size="small" color="secondary" placeholder="Enter Field Name"
                    className={classes.startCell}
                />
            </TableCell>
            <TableCell>
                <Field component={CustomSelectField} name={`${fieldName}.${index}.fieldType`} fullWidth
                    required variant="outlined" size="small" color="secondary" placeholder="Enter Field Type"
                    className={classes.endCell}
                />
            </TableCell>
            <TableCell align='center'> 
                <Field component={Checkbox} type="checkbox" checked={data.isRequired} 
                        classes={{ root: classes.checkboxes }} name={`${fieldName}.${index}.isRequired`}
                        
                />
            </TableCell>
            <TableCell> 
                <Field component={TextField} name={`${fieldName}.${index}.helpText`} fullWidth
                    variant="outlined" size="small" color="secondary" placeholder="Enter Help Text"
                />
            </TableCell>
            <TableCell align="center" >
                <div className={classes.fieldsRow}>
                    <div className={classes.ActionIcons}>
                        <Delete fontSize="small" onClick={() => deleteCard(index)} />
                    </div>
                    <div className={classes.ActionIcons}>
                        <OpenWithIcon fontSize="small" />
                    </div>
                </div>
            </TableCell>
        </TableRow>
        {optionRows[index] && <TableRow style={{backgroundColor: '#f1f1f1'}}>
            <TableCell></TableCell>
            <TableCell colSpan={4}> 
                <Field component={TextField} name={`${fieldName}.${index}.options`} fullWidth
                    variant="outlined" size="small" color="secondary" style={{marginLeft:'5px',backgroundColor:'#fff'}}
                    placeholder="Enter comma separated options"
                />
            </TableCell>
            <TableCell></TableCell>
        </TableRow>}
    </>)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        dragPad:{
            width:'100%',
            backgroundColor: 'green',
            border: '1px solid red',
            display: 'flex'
        },
        fieldsRow:{
            display: 'flex',
            justifyContent: 'center',
        },
        ActionIcons:{
            cursor: 'pointer',
            padding: '2px 5px',
        },
        checkboxes:{

        },
        startCell:{
            paddingRight:'5px',
            paddingLeft: '5px',
        },
        endCell:{
            paddingLeft: '5px',
        },
    }),
);


function CloseAllOptionRows(setOptionRows:any, optionRows:any){
    setOptionRows(setOptionsViewArray(optionRows.length));
}