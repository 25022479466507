import React from "react";

interface AlertProps {
    open: boolean;
    type?: string;
    msg: string;
    title?: string;
    duration?: number;
    action?: any;
    icon?: any;
    anchorOrigin?: any;
    onClose?: any;
}
const initState = { open: false, msg: '' };
export default () => {
    let [modalContent, setModalContent] = React.useState<AlertProps>(initState);

    const showModal = (content: any) => {
        setModalContent({ ...content, open: true });
    };
    const closeModal = () => {
        setModalContent(initState);
    }

    const showErr = (msg: string, title?: string) => {
        setModalContent({ open: true, type: 'error', msg, title });
    }
    const showInfo = (msg: string, title?: string) => {
        setModalContent({ open: true, type: 'info', msg, title });
    }
    const showDone = (msg: string, title?: string) => {
        setModalContent({ open: true, type: 'success', msg, title });
    }

    return { modalContent, showModal, closeModal, showErr, showInfo, showDone };
};
