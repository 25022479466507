import React, { useEffect, useState } from "react";
import { getStoreList } from '../api/calendarService';
import Loader from "../component/Loader";
import StoreSchedule from "../component/Store/StoreSchedule";
import Page from "../container/Page";


export default function CalendarSchedule(props: any) {
    const storeId = props.location && props.location.state ? props.location.state.stores : undefined;
    const [storeData, setStoreData] = useState<any>();

    useEffect(() => {
        if (storeId) {
            setStoreData(storeId);
        } else {
            const fetchData = async () => {
                const apiStores = await getStoreList();
                setStoreData(apiStores.models);
            };
            fetchData();
        }
    }, []);

    if (storeData) {
        return <Page useFullBody Body={() => <StoreSchedule stores={storeData} />} />
    } else {
        return <Loader useBackdrop open={true} />
    }
}
