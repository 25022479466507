import { Button, createStyles, IconButton, IconButtonProps, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { Add, Save } from '@material-ui/icons';
import React from 'react';

interface AddButtonProps {
    fieldId: string;
    btnLabel: string;
    onClick?: any;
    ref?: any;
    style?: any;
}

interface IconTextButtonProps extends IconButtonProps {
    fieldId: string;
    btnLabel: string;
    Icon: any;
    //onClick?: any;
    //ref?: any;
    //style?: any;
    iconCSS?: any;
    component?: any;
    to?: any;

}
export default function AddButton(props: AddButtonProps) {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    if (isSmallScreen) {
        return (
            <IconButton id={props.fieldId} color="primary" className={classes.button}
                disableRipple disableTouchRipple disableFocusRipple {...props}>
                <Add />
            </IconButton>
        );

    } else {
        return (
            <Button id={props.fieldId} variant="contained" color="secondary" classes={{ startIcon: classes.spacing, root: classes.buttonRoot }}
                disableRipple disableTouchRipple disableFocusRipple {...props}
                startIcon={<Add />}>
                {props.btnLabel}
            </Button>
        );
    }
}

export function SaveButton(props: any) {
    const classes = useStyles();
    const { btnLabel, Icon } = props;
    const label = btnLabel ? btnLabel : "SAVE"
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    if (isSmallScreen) {
        return <IconButton id={props.fieldId} color="primary" className={classes.button}
            disableRipple disableTouchRipple disableFocusRipple {...props}>
            {Icon ? <Icon /> : <Save />}
        </IconButton>
    } else {
        return (
            <Button id={props.fieldId} variant="contained" color="secondary"
                classes={{ startIcon: classes.spacing, root: classes.buttonRoot }} {...props}>
                {label}
            </Button>
        );
    }
}


export function IconTextButton(props: IconTextButtonProps) {
    const classes = useStyles();
    const { btnLabel, Icon, iconCSS } = props;
    const iconStyle = iconCSS || classes.spacing;
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    if (isSmallScreen) {
        return (
            <IconButton id={props.fieldId} color="primary" className={classes.button}
                disableRipple disableTouchRipple disableFocusRipple {...props}>
                {Icon && <Icon />}
            </IconButton>
        );

    } else {
        return (
            <Button id={props.fieldId} variant="contained" color="secondary" disableRipple disableTouchRipple disableFocusRipple {...props}
                startIcon={Icon && <Icon />} classes={{ startIcon: iconStyle, root: classes.buttonRoot }}>
                {btnLabel}
            </Button>
        );
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: '4px',
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: '#bbb',
                backgroundColor: theme.palette.secondary.main,
            }
        },
        spacing: {
            marginRight: 4
        },
        buttonRoot: {
            padding: '4px 8px'
        }
    }),
);