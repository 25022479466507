import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useAsync } from "react-async";
import { Link } from "react-router-dom";
import { getStores } from '../api/calendarService';
import { ReactComponent as EventNoteIcon } from '../assets/Calendar.svg';
import BreadcrumbNav from "../component/BreadcrumbNav";
import Loader from '../component/Loader';
import { IconTextButton } from '../component/Settings/AddBtn';
import StoreList from '../component/Store/StoreList';
import Page from "../container/Page";
import useCustomTranslation from "../container/useCustomTranslation";

const getAllStores = async ({ searchText }: any, { signal }: any) => {
  const res = await getStores();
  if (!res.ok) throw new Error(res.statusText)
  return res.json()
}


export default function Stores() {
  const { data, isPending } = useAsync({ promiseFn: getAllStores })
  const { t } = useCustomTranslation();

  const classes = useStyles();
  return (
    <Page
      Header={() => <Grid container justify="space-between" alignItems="center">
        <Grid item xs style={{ display: 'flex' }}>
          <BreadcrumbNav />
        </Grid>
        <Grid item style={{ marginRight: '8px' }}>
          <IconTextButton fieldId='view-schedules' btnLabel={t('Home.locationsCalendar')}
            Icon={EventNoteIcon} iconCSS={classes.spacing} disabled={!data || !data.models}
            component={Link}
            to={{ pathname: '/locations/locationsCalendar', state: { stores: data ? data.models : null } }}
          />
        </Grid>
      </Grid>}
      Body={() => data ? <StoreList stores={data.models} /> :
        isPending ? <Loader width={300} height={30} textHeight={40} /> : null} />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      margin: '0 4px',
      paddingRight: '4px',
    }
  }),
);