import { useAsync } from "react-async";
import client, { clientAsync } from './api-client';

export const getSubscriptionsPlans = async () => {
    try {
        let url = process.env.REACT_APP_SUBSCRIPTION_JSON || "https://proappointmentsdev.blob.core.windows.net/public/SubscriptionPlans.json";
        return fetch(url)
        .then(async (response) => {
            const data = await response.json();
            if (response.ok) {
                return Promise.resolve(data);
            } else {
              return Promise.reject(data);
            }
          });
    }
    catch (e) {
        return Promise.reject(e);
    }
};

export async function subscribePlan(plan:any) {
    let endpoint = 'organizations/me/subscriptionPlan'
    let urlencoded = new URLSearchParams();
    if (plan) {
        urlencoded.append('subscriptionPlanType', plan.subscriptionPlanType);
        urlencoded.append('subscriptionPlanFrequency', plan.subscriptionPlanFrequency);
        if (plan.autoRenewalEnabled) {
            urlencoded.append('autoRenewalEnabled', plan.autoRenewalEnabled);
        } else {
            urlencoded.append('autoRenewalEnabled', 'false');
        }
    }
    var requestOptions: any = {
        method: 'PUT',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = await clientAsync(endpoint, { ...requestOptions });
    return metadataResponse.text();
}

export function subscribeSMSPlan(smsPackageType:any) {
    let endpoint = 'organizations/me/smsPackage'
    let urlencoded = new URLSearchParams();
    if (smsPackageType) {
        urlencoded.append('packageType', smsPackageType);
    }

    var requestOptions: any = {
        method: 'PUT',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: urlencoded,
        redirect: 'follow'
    }

    const metadataResponse = client(endpoint, { ...requestOptions });
    return metadataResponse;
}

export async function cancelSubscribePlan() {
    let endpoint = 'organizations/me/subscriptionPlan/cancel'
    var requestOptions: any = {
        method: 'PUT',
    }
    const metadataResponse = await clientAsync(endpoint, { ...requestOptions });
    return metadataResponse.text();
}
export function useGetSubscriptionsPlans(callback?: any, errCallback?: any) {
    return useAsync({ promiseFn:getSubscriptionsPlans, onResolve: callback, onReject: errCallback });
}