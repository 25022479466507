import { useAuth0 } from "@auth0/auth0-react";
import { createMuiTheme, Theme, ThemeProvider } from "@material-ui/core";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import Topbar from '../component/Topbar';
import OrgContext, { OrgContextProvider } from "../container/OrgContext";
import { isOrgPresent } from "../utils/eventUtils";
import { LOGIN_PATH } from "../utils/types";

export default function Layout(props: any) {
    const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();

    const acc = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
                scope: "read:current_user",
            });
            localStorage.setItem('authHeader', accessToken);
            return accessToken;
        } catch (e) {
            throw e;
        }
    };
    if (localStorage.getItem('authHeader') === null && isOrgPresent(user)) {
        acc();
    }
    if ((!isAuthenticated && !isLoading) || (isAuthenticated && !isOrgPresent(user))) {
        return <Redirect to={{ pathname: LOGIN_PATH, state: { from: props.location } }} />
    }
    return (
        <OrgContextProvider>
            <Topbar />
            {props.children}
        </OrgContextProvider>
    )
}

function CustomTheme(props: any) {
    const [org] = useContext(OrgContext);
    return (
        <ThemeProvider theme={(theme: Theme) =>
            createMuiTheme({
                ...theme,
                palette: {
                    ...theme.palette,
                    secondary: {
                        main: org.primaryColor || theme.palette.secondary.main,
                    },
                },
            })
        }>
            {props.children}
        </ThemeProvider>
    );

}