import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import React from "react";
import BreadcrumbNav from '../component/BreadcrumbNav';

interface PageProps {
    Header?: any;
    Body: any;
    Footer?: any;
    useFullBody?: boolean;
}

export default function Page(props: PageProps) {
    const { Header, Body, Footer, useFullBody } = props;
    const classes = useStyles();
    if (useFullBody) {
        return <Body />
    } else {
        return (
            <>
                <main className={classes.main}>
                    <Grid container className={classes.miniHeader}>
                        {Header ? <Header /> : <BreadcrumbNav />}
                    </Grid>
                    <Container maxWidth={false} className={classes.baseContainer}>
                        <Body />
                    </Container >
                </main>
                <footer>
                    {Footer && <Footer />}
                </footer>
            </>
        )
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        alignItems: 'center'
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },
    main: {
        overflow: 'hidden',
        height: 'calc(100vh - 60px)',
        display: 'flex',
        flexDirection: 'column'
    },
    miniHeader: {
        zIndex: 11,
        boxShadow: "0px 1px 3px 1px #b3b3b3",
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignContent: 'center'
    },
    baseContainer: {
        overflowY: 'auto',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    }
}));
