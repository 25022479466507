import React from 'react';
import { makeStyles, Theme} from '@material-ui/core/styles';
import { Box, Typography,Button } from '@material-ui/core';

function UpgradeToSubscription(props: any) {
    const classes = useStyles();
    const { handleChange } = props;
            return (
                <Box className={classes.subscriptionText}>
                <Typography variant="subtitle1">
                Upgrade to Basic, Intermediate, Premium or Enterprise plan to enable this feature.
                   <Button className={classes.button} onClick={e => handleChange(e ,10) }>
                        <Typography variant="subtitle1">Subscribe</Typography>
                   </Button>
               </Typography>
               </Box>
            );
}

export default React.memo(UpgradeToSubscription);

const useStyles = makeStyles((theme: Theme) => ({
    subscriptionText: {
        justifyContent:'center',
        alignItems:'center',
        alignContent:'centre',
        textAlign:'center',
        padding:'10px',
        marginTop:'10px',
        backgroundColor:theme.palette.secondary.main,
        borderRadius:'5px',
        color:'#FEF634'
    },
    button: {
        backgroundColor: '#FEF634',
        borderRadius: '3px',
        color: '#1E2226',
        height: '30px',
        padding: '4px 12px',
        margin:'0px 10px',
        '&:hover': {
            cursor:'pointer',
            backgroundColor: '#FEF634',
        },
        [theme.breakpoints.down('sm')]: {
            height: '24px',
            backgroundColor: '#FEF634',
            padding: '4px 10px',
            color: '#1E2226',
        }
    },
}));