import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomeContainer from "../container/HomeContainer";
import { ModalProvider } from "../container/ModalContainer";
import Layout from '../pages/Layout';
import { Login } from "./Login";
import OrgForm from './OrgForm';
import { LOGIN_PATH } from "../utils/types";
import Signup from "./Signup";
import { LinearProgress } from "@material-ui/core";

import CustomerContainer from '../pages/Customers';
import StoreContainer from '../pages/Stores';
import SettingsContainer from '../container/SettingsContainer';
import ReportContainer from '../container/ReportContainer';
import CalendarPage from '../pages/CalendarPage';
import CalendarSchedulePage from '../pages/CalendarSchedule';

// const CustomerContainer = React.lazy(() => import('../pages/Customers'));
// const StoreContainer = React.lazy(() => import('../pages/Stores'));
// const SettingsContainer = React.lazy(() => import('../container/SettingsContainer'));
// const ReportContainer = React.lazy(() => import('../container/ReportContainer'));
// const CalendarPage = React.lazy(() => import('../pages/CalendarPage'));
// const CalendarSchedulePage = React.lazy(() => import('../pages/CalendarSchedule'));


export default function Routes() {
    return (
        <BrowserRouter>
            <ModalProvider>
                <Switch>
                    <Route exact path={LOGIN_PATH} component={Login} />
                    <Route exact path={'/signup'} component={Signup} />
                    <Route exact path="/form" render={() => {
                        return (<OrgForm />)
                    }
                    } />

                    <Layout>
                        <Suspense fallback={<LinearProgress color="secondary" />}>
                            <Route exact path="/home" render={() => {
                                return (<HomeContainer />)
                            }
                            } />
                            <Route exact path="/locations" render={() => {
                                return (<StoreContainer />)
                            }
                            } />
                            <Switch>
                                <Route exact path="/locations/locationsCalendar" render={(props) => {
                                    return (
                                        <CalendarSchedulePage {...props} />)
                                }
                                } />
                                <Route path="/locations/:name" render={(props) => {
                                    return (
                                        <CalendarPage {...props} />)
                                }
                                } />
                            </Switch>
                            <Route exact path="/customers" render={() => {
                                return (<CustomerContainer />)
                            }
                            } />
                            <Route exact path="/reports" render={() => {
                                return (<ReportContainer />)
                            }
                            } />
                            <Route exact path="/settings" render={(props) => {
                                return (<SettingsContainer {...props} />)
                            }
                            } />
                        </Suspense>
                    </Layout>
                </Switch>
            </ModalProvider>
        </BrowserRouter>
    );
};
