import { CircularProgress, Divider, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, useField } from 'formik';
import { TextField as TF } from 'formik-material-ui';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { getCustomers } from '../../api/customerService';
import useCustomTranslation from '../../container/useCustomTranslation';
import { PhoneNumberField } from "../FormFields/PhoneNumberField";

const searchCustomers = async ([{ searchText }]: any, { signal }: any) => {
    const res = await getCustomers(searchText, 0, 10);
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

export default function AutocompleteCutomerDetails(props: any) {
    const { t } = useCustomTranslation();
    const [options, setOptions] = useState([]);
    const { storeData } = props;
    const [, , nameHelpers] = useField('customerName');
    const [, , emailHelpers] = useField('customerEmail');
    const [, , phoneHelpers] = useField('customerPhone');

    const { data, isLoading, run } = useAsync({
        deferFn: searchCustomers,
    })


    const loading = isLoading && options.length === 0;

    useEffect(() => {
        setOptions(data ? data.models : []);
    }, [data]);

    function handleChange(value: any) {
        if (value && value.trim() !== '') {
            run({ searchText: value })
        } else {
            setOptions([]);
        }
    }

    const setFields = (customer: any) => {
        if (customer) {
            nameHelpers.setValue(customer.name);
            emailHelpers.setValue(customer.email);
            phoneHelpers.setValue(customer.mobilePhone);
        } else {
            nameHelpers.setValue('');
            emailHelpers.setValue('');
            phoneHelpers.setValue('');
        }

    }

    return (
        <>
            <Grid item xs={12}>
                <Autocomplete
                    id="customer-details"
                    size="small"
                    fullWidth freeSolo
                    getOptionSelected={(option: any, value: any) => option.name === value.name}
                    getOptionLabel={(option: any) => option.name}
                    options={options}
                    renderOption={(option) => (
                        <Grid container alignItems="center">
                            <Grid item xs>
                                {option.name}
                                <div style={{ fontSize: '12px', color: '#666' }}>{option.email}</div>
                                <div style={{ fontSize: '12px', color: '#666' }}>{option.mobilePhone}</div>
                            </Grid>
                            <Divider style={{ width: "100%" }} variant="fullWidth" />
                        </Grid>
                    )}
                    loading={loading}
                    onChange={(event, newValue) => setFields(newValue)}
                    onInputChange={(e, value) => { handleChange(value) }}
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                        <Field  {...params} component={TF} name="customerName" type="text" label={t("EmployeeSettings.Name")}
                            required variant="outlined" size="small" color="secondary"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Field component={TF} name="customerEmail" type="email" label={t("EmployeeSettings.Email")}
                    variant="outlined" size="small" color="secondary" fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <PhoneNumberField name="customerPhone" type="text" label={t("EmployeeSettings.Phone")}
                    variant="outlined" size="small" color="secondary" fullWidth required
                    countryCode={storeData.store.countryCode} />
            </Grid>
        </>
    );
}