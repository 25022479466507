import {
    DialogContent, DialogTitle,
    Divider, Drawer, FormControlLabel, Grid, Radio, RadioGroup
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { approveAppointment, assignAppointment, completeAppointment, deleteAppointment, notify, rejectAppointment, rescheduleAdminAppointment, updateLinks } from "../../api/calendarService";
import { ModalContext } from "../../container/ModalContainer";
import OrgContext from "../../container/OrgContext";
import useCustomTranslation from '../../container/useCustomTranslation';
import { slotModel } from "../../utils/slotModel";
import { Appointment, AppointmentStatus, AppointmentType } from "../../utils/types";
import Loader from "../Loader";
import EventPanel from './EventPanel';


interface EventListProps {
    events: Appointment[];
    handleClose: any;
    refreshEvents?: any;
    open: boolean;
    storeData?: any;
    openedFrom?: any;
}

export default function EventList(props: EventListProps) {
    const { t } = useCustomTranslation();
    const { events, open, handleClose, refreshEvents, storeData } = props;
    const [expanded, setExpanded] = useState<string | boolean>(events.length === 1);
    const [filterByType, setFilterByType] = useState(AppointmentType.All);
    const [eventList, setEventList] = useState(events);
    const [isLoading, setLoading] = useState(false);
    const alertHelper = useContext(ModalContext);
    const [org] = useContext(OrgContext);
    //const filterVisible = props.openedFrom && (props.openedFrom === 'cust' || props.openedFrom === 'report');

    const isHomeVisit = org.isHomeVisitEnabled || (storeData && storeData.store && storeData.store.isHomeVisitEnabled);
    const isStoreVisit = org.isLocationVisitEnabled || (storeData && storeData.store && storeData.store.isLocationVisitEnabled);
    const isVideoVisit = org.isVideoCallEnabled || (storeData && storeData.store && storeData.store.isVideoCallEnabled);

    // const isOnlyOne = isHomeVisit && !isStoreVisit && !isVideoVisit ||
    //     !isHomeVisit && isStoreVisit && !isVideoVisit || !isHomeVisit && !isStoreVisit && isVideoVisit;

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleRadioChange = (evt: any) => {
        setFilterByType(evt.target.value);
    }

    useEffect(() => {
        if (filterByType === AppointmentType.All) {
            setEventList(events);
        } else {
            let filteredEvents = events.filter(e => e.type === filterByType);
            setEventList(filteredEvents);
        }

    }, [events, filterByType]);

    const eventButtonActions = (textBox: any, id: any, refresh = false) => {
        setLoading(true);
        if (textBox.type === 'notify') {
            // Here textBoxs text is message & reminderType is reminder type
            notify(id, +textBox.reminderType, textBox.text).then(() => {
                setLoading(false);
                alertHelper.showDone(t("Alerts.Events.NotifySuccess"));
            }).catch(() => {
                setLoading(false);
                alertHelper.showErr(t("Alerts.Events.NotifyErr"));
            });
        } else if (textBox.type === 'cancel') {
            rejectAppointment(id, textBox.text).then((res) => {
                setLoading(false);
                const updatingEvent: any = events.find((e: any) => e.id === id);
                updatingEvent.cancellationReason = textBox.text;
                updatingEvent.status = AppointmentStatus.Cancelled;
                alertHelper.showDone(t("Alerts.Events.CancelSuccess"));
            }).catch((e) => {
                setLoading(false);
                alertHelper.showErr(t("Alerts.Events.CancelErr"));

            });
        } else if (textBox.type === 'delete') {
            console.log(id);
            deleteAppointment(id, textBox.context.isRecurring).then((res) => {
                setLoading(false);
                refreshEvents();
                handleClose();
                alertHelper.showDone(t("Alerts.Events.DeleteSuccess"));
            }).catch((e) => {
                setLoading(false);
                alertHelper.showErr(t("Alerts.Events.DeleteErr"));

            });
        } else if (textBox.type === 'approve') {
            // Here textBoxs text is host id & reminderType is host's name
            const hostId = textBox.text && textBox.text !== '-1' ? textBox.text : undefined;
            const hostName = textBox.reminderType ? textBox.reminderType : "-";
            approveAppointment(id, hostId).then((res) => {
                setLoading(false);
                const updatingEvent: any = events.find((e: any) => e.id === id);
                updatingEvent.status = AppointmentStatus.Scheduled;
                if (!hostId) {
                    updatingEvent.host = undefined;
                } else {
                    updatingEvent.host = {
                        id: hostId,
                        name: hostName
                    }
                }
                alertHelper.showDone(t("Alerts.Events.AcceptSuccess"));
            }).catch(() => {
                setLoading(false);
                alertHelper.showErr(t("Alerts.Events.AcceptErr"));
            });
        } else if (textBox.type === 'assign') {
            // Here textBoxs text is host id & reminderType is host's name
            const hostId = textBox.text && textBox.text !== '-1' ? textBox.text : undefined;
            const hostName = textBox.reminderType ? textBox.reminderType : "-";

            assignAppointment(id, hostId).then((res) => {
                const updatingEvent: any = events.find((e: any) => e.id === id);
                if (!hostId) {
                    updatingEvent.host = undefined;
                } else {
                    updatingEvent.host = {
                        id: hostId,
                        name: hostName
                    }
                }
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        } else if (textBox.type === 'complete') {
            completeAppointment(id, textBox.text).then((res) => {
                const updatingEvent: any = events.find((e: any) => e.id === id);
                updatingEvent.completionNotes = textBox.text;
                updatingEvent.status = AppointmentStatus.Completed;

                setLoading(false);
                alertHelper.showDone(t("Alerts.Events.CompleteSuccess"));
            }).catch((e) => {
                setLoading(false);
                alertHelper.showErr(t("Alerts.Events.CompleteErr"));
            });
        } else if (textBox.type === 'reschedule') {
            rescheduleAdminAppointment(id, textBox.date, textBox.slot, textBox.appointmentDuration).then((res) => {
                const updatingEvent: any = events.find((e: any) => e.id === id);
                const slot = slotModel(textBox.slot);
                const appointmentTime = slot.getLocalTime(new Date(textBox.date)).toISOString();
                updatingEvent.appointmentTime = appointmentTime;
                updatingEvent.appointmentDuration = textBox.appointmentDuration;
                setLoading(false);
                alertHelper.showDone(t("Alerts.Events.RescheduleSuccess"));
            }).catch((e) => {
                setLoading(false);
                alertHelper.showErr(t("Alerts.Events.RescheduleErr"));
            });
        } else if (textBox.type === 'updateLink') {
            // Here textBoxs text is start url & reminderType is join url
            const startURL = textBox.text && textBox.text !== '-1' ? textBox.text : undefined;
            const joinURL = textBox.reminderType ? textBox.reminderType : undefined;
            updateLinks(id, joinURL, startURL).then((res) => {
                const updatingEvent: any = events.find((e: any) => e.id === id);
                updatingEvent.startUrl = startURL;
                updatingEvent.joinUrl = joinURL;
                setLoading(false);
                alertHelper.showDone(t("Alerts.Events.UpdateLinkSuccess"));
            }).catch((err) => {
                setLoading(false);
                if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                    alertHelper.showModal({ msg: err.ErrorMessages.join(', '), type: 'error', duration: 5000 });
                }
                else {
                    alertHelper.showErr(t("Alerts.Events.UpdateLinkFail"));
                }
            });
        }
        else {
            setLoading(false);
        }
    }


    return (
        <Drawer
            open={open}
            anchor='right'
            onClose={handleClose}
            PaperProps={{ variant: 'outlined', style: { minWidth: '40vw', maxWidth: '500px', width: '100%' } }}
        >

            <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {t('AppointmentDetails.Title')}
                    <Cancel id="cloz-aptms" onClick={handleClose} /></div>
            </DialogTitle>
            <div style={{ display: 'flex', padding: '0px 10px', alignItems: 'center' }}>
                <span style={{ whiteSpace: 'nowrap' }}>{t('AppointmentDetails.ViewBy')}</span>
                <RadioGroup style={{ marginLeft: '10px', color: '#333', }} row value={filterByType} onChange={handleRadioChange}>
                    <Grid container direction="row" justify="flex-start">
                        <Grid item xs={6} sm style={{ flexBasis: 'auto' }}>
                            <FormControlLabel label={<div style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{t('AppointmentDetails.FilterType.All')}</div>}
                                value={AppointmentType.All}
                                control={<Radio size="small" id="radio-type-all" color="secondary" />}
                            />
                        </Grid>
                        {isStoreVisit && <Grid item xs={6} sm style={{ flexBasis: 'auto' }}>
                            <FormControlLabel label={<div style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{t('AppointmentDetails.FilterType.Store')}</div>}
                                value={AppointmentType.LocationVisit}
                                control={<Radio size="small" id="radio-type-store" color="secondary" />}
                            />
                        </Grid>}
                        {isHomeVisit && <Grid item xs={6} sm style={{ flexBasis: 'auto' }}>
                            <FormControlLabel label={<div style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{t('AppointmentDetails.FilterType.Home')}</div>}
                                value={AppointmentType.HomeVisit}
                                control={<Radio size="small" id="radio-type-home" color="secondary" />}
                            />
                        </Grid>}
                        {isVideoVisit && <Grid item xs={6} sm style={{ flexBasis: 'auto' }}>
                            <FormControlLabel label={<div style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{t('AppointmentDetails.FilterType.Video')}</div>}
                                value={AppointmentType.VideoCall}
                                control={<Radio size="small" id="radio-type-video" color="secondary" />}
                            />
                        </Grid>}
                    </Grid>
                </RadioGroup>
            </div>
            <Divider variant="fullWidth" />
            <DialogContent style={{ padding: '0px 5px' }}>
                {eventList.map((e, i) => {
                    return <EventPanel key={i} event={e} expanded={expanded}
                        storeData={storeData} handleChange={handleChange} eventActions={eventButtonActions} />
                })}
            </DialogContent>
            {isLoading && <Loader useBackdrop open={isLoading} />}
        </Drawer>
    )

}