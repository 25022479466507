import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import useCustomTranslation from '../../container/useCustomTranslation';
import { getTypeStringFromType } from '../../utils/eventUtils';
import { AppointmentReportGroup } from '../../utils/types';


const headerStyle: any = {
    backgroundColor: '#f1f1f1',
    fontWeight: 'bold'
}
function CountLink(props: any) {
    const { rowData, onCountClick } = props;
    return (
        <div id='view' color="inherit" className="hovar-bold" style={{ color: "#069", textTransform: 'capitalize', cursor: "pointer" }}
            onClick={() => onCountClick(rowData)}>
            {rowData.count}
        </div>
    );
}

export default function ReportGraphics(props: any) {
    const { report, cols, locations, onCountClick } = props;
    const { t } = useCustomTranslation();
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    if (!report) {
        return null;
    }

    const getLocationName = (id: string) => {
        if (id && locations && locations.length > 0) {
            const res = locations.find((s: any) => id === s.id);
            return res ? res.name : t('Reports.DeletedObject');
        }
        return id;
    }
    const columnTypeOrderMapping = () => {
        const mappedCols: any = {};
        mappedCols[AppointmentReportGroup.locationId] = {
            title: t('Reports.TableHeaders.Location'), field: AppointmentReportGroup.locationId, align: 'left',
            render: (rowData: any) => getLocationName(rowData.locationId)
        };
        mappedCols[AppointmentReportGroup.type] = {
            title: t('Reports.TableHeaders.Type'), field: AppointmentReportGroup.type, align: 'left',
            render: (rowData: any) => t(getTypeStringFromType(rowData.type))
        };
        mappedCols[AppointmentReportGroup.status] = {
            title: t('Reports.TableHeaders.Status'), field: AppointmentReportGroup.status, align: 'left',
            render: (rowData: any) => rowData.status
        };
        mappedCols[AppointmentReportGroup.reason] = {
            title: t('Reports.TableHeaders.Reason'), field: AppointmentReportGroup.reason, align: 'left',
        };
        return mappedCols;
    };

    const tableCol = () => {
        const tableCols = [];
        for (let t in cols) {
            tableCols.push(columnTypeOrderMapping()[cols[t]]);
        }
        tableCols.push({
            title: t('Reports.TableHeaders.Count'), field: 'count', align: 'center',

            render: (rowData: any) => <CountLink rowData={rowData} onCountClick={onCountClick} />
        });
        return tableCols;
    }


    return (
        <Grid item xs={12} md={widthStyle(cols)} style={{ margin: '16px auto' }}>
            {/* <BarChart
                height={300}
                width={600}
        data={processData()}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="l0"/>
        <YAxis />
        <Tooltip />
        <Legend />
                <Bar dataKey="l0l" stackId="a" fill="#8884d8" />
        </BarChart> */}
            <MaterialTable
                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    paging: false,
                    draggable: false,
                    toolbar: false,
                    sorting: false,
                    thirdSortClick: false,
                    headerStyle: headerStyle,
                    padding: isSmallDevices ? 'dense' : 'default',
                    // minBodyHeight: '50vh',
                    // maxBodyHeight: '70vh'
                }}

                columns={report && tableCol()}
                data={report}
            />

        </Grid>
    );
}

function widthStyle(cols: any[]) {
    if (!cols) {
        return 6;
    }
    if (cols.length <= 1) {
        return 6;
    } else if (cols.length <= 2) {
        return 8;
    } else {
        return 12;
    }
}