import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, Formik, Field } from 'formik';
import Box from '@material-ui/core/Box';
import { PhoneNumberField } from '../component/FormFields/PhoneNumberField';
import { ModalContext } from '../container/ModalContainer';
import { TextField as TF, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { useLocation } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '30%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  boxheader: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    width: '380px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  boxbody: {
    padding: theme.spacing(1),
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    width: '380px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  textfields: {

  },
  label: {
    fontWeight: 600,
    fontSize: '16px'
  },
  bodycss:
  {
    backgroundColor: '#f9f9f9',
    height: '100vh',
    width: '100vw',
  },
  submitMessage: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  selectPadding: {
    padding: '12px 14px'
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SignUp() {
  const query = useQuery();
  const classes = useStyles();
  const alertHelpers = useContext(ModalContext);
  const [register, setRegister] = useState(false);
  const plans = ["Basic", "Intermediate", "Premium"];
  const getPlan = query.get("plan") ? query.get("plan") : "";
  const plantype = getPlan && plans.includes(getPlan) ? getPlan : 'Free';
  const initialValues = { name: '', email: '', mobileNumber: '', password: '', confirmPassword: '', plan: plantype };
  const handlesignup = (values: any) => {
    const requestOptions: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "client_id": process.env.REACT_APP_CLIENT_ID,
        "email": values.email,
        "password": values.password,
        "name": values.name,
        "connection": process.env.REACT_APP_CONNECTION,
        "user_metadata": {
          "mobilePhone": values.mobileNumber,
          "subscriptionPlan": values.plan,
        },
      }),
    };
    fetch(`https://${process.env.REACT_APP_DOMAIN}/dbconnections/signup`, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          return data;
        }
        else {
          return Promise.reject(data);
        }
      })
      .then(result => {
        setRegister(true);
      })
      .catch(error => {
        alertHelpers.showErr("Email already exists, please try again with different email")
      });
  }

  return (
    <div className={classes.bodycss} >
      <Container component="main" >
        <div className={classes.paper}>
          <Box border={1} className={classes.boxheader}>
            <img src={process.env.REACT_APP_LOGO} alt="Not Found" />
          </Box>
          <Box className={classes.boxbody} color="secondary">
            <p className={classes.label} >Register Organisation Admin</p>
            <Formik
              initialValues={initialValues}
              validate={(values) => {
                const errors: any = {};
                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.mobileNumber)) {
                  errors.mobileNumber = 'Invalid mobile number'
                }
                if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(values.password)) {
                  errors.password = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                }
                if (values.confirmPassword !== values.password) {
                  errors.confirmPassword = 'Password Doesnot Match'
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handlesignup(values);
              }}>
              {({ isSubmitting }) => (
                <Form className={classes.form}>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field className={classes.textfields}
                        component={TF}
                        autoComplete="name"
                        name="name"
                        variant="outlined"
                        color="secondary"
                        required
                        fullWidth
                        size="small"
                        id="vendorName"
                        label="Name"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field className={classes.textfields}
                        component={TF}
                        name="email"
                        type="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        required
                        id="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field className={classes.textfields}
                        component={TF}
                        variant="outlined"
                        color="secondary"
                        required
                        fullWidth
                        size="small"
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field className={classes.textfields}
                        component={TF}
                        variant="outlined"
                        color="secondary"
                        required
                        fullWidth
                        size="small"
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneNumberField className={classes.textfields}
                        name="mobileNumber"
                        id="mobileNumber"
                        type="text"
                        label="Phone"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth color="secondary" required style={{ textAlign: 'left' }}>
                        <InputLabel id="storeLabel" variant="outlined" margin="dense">Plan</InputLabel>
                        <Field className={classes.textfields}
                          component={Select}
                          variant="outlined"
                          color="secondary"
                          required
                          fullWidth
                          size="small"
                          name="plan"
                          type="text"
                          label="Plan"
                          labelId='storeLabel'
                          classes={{ select: classes.selectPadding }}
                        >
                          <MenuItem value='Free'>Free</MenuItem>
                          <MenuItem value='Basic'>Basic</MenuItem>
                          <MenuItem value='Intermediate'>Intermediate</MenuItem>
                          <MenuItem value='Premium'>Premium</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >{register ?
                    <p className={classes.submitMessage}>Varification email sent on registered mail address</p> :
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                    >
                      REGISTER
              </Button>}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </div>
      </Container>
    </div>
  );
}