import {
    Button,
    createStyles, DialogContent, DialogTitle,
    Divider, Drawer,
    Grid,
    makeStyles,
    Theme
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useContext } from 'react';
import { useAddCustomer, useDeleteCustomer, useUpdateCustomer } from '../../api/customerService';
import { ModalContext } from '../../container/ModalContainer';
import useCustomTranslation from '../../container/useCustomTranslation';
import { PhoneNumberField } from '../FormFields/PhoneNumberField';
import Loader from '../Loader';
interface CustomerFormProps {
    type: 'add' | 'edit' | 'view' | undefined;
    id?: string;
    selected?: any;
    handleClose: any;
    open: boolean;
    title: string;
}

export default function CustomerForm(props: CustomerFormProps) {
    const { open, handleClose, title } = props;

    return (
        <Drawer open={open} onClose={handleClose}
            PaperProps={{ variant: 'outlined', style: { minWidth: '40vw', maxWidth: '500px', width: '100%' } }}
            anchor='right'>

            <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title}
                    <Cancel id="cloz-aptms" onClick={handleClose} /></div>
            </DialogTitle>
            <DialogContent dividers style={{ padding: '10px' }}>
                <CustomerInputs {...props} />
            </DialogContent>
        </Drawer>
    );
}

function CustomerInputs(props: any) {
    const classes = useStyles();
    const { selected, handleClose, type }: any = props;
    const { t } = useCustomTranslation();
    const isReadOnly = type === 'view' || type === 'delete';
    const alertHelper = useContext(ModalContext);

    const closeFlyout = () => {
        handleClose(null, true);
    }
    const alertErr = (err: any) => {
        if (err && err.ErrorMessages) {
            alertHelper.showErr(err.ErrorMessages[0]);
        } else {
            alertHelper.showErr("Error occurred while saving data");
        }
    }
    const updateCustomer = useUpdateCustomer(closeFlyout, alertErr);
    const addCustomer = useAddCustomer(closeFlyout, alertErr);
    const deletCustomer = useDeleteCustomer(closeFlyout, alertErr);

    const updateSettings = type === 'edit' ? updateCustomer : addCustomer;

    const initialValues = selected ?
        { name: selected.name, email: selected.email, mobilePhone: selected.mobilePhone } :
        { name: '', email: '', mobilePhone: '' };

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const errors: any = {};
                if (isReadOnly) {
                    return errors;
                }
                if (!values.name || values.name.trim() === '') {
                    errors.name = 'Required';
                }
                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                if (!values.mobilePhone) {
                    errors.mobilePhone = 'Required';
                } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.mobilePhone)) {
                    errors.mobilePhone = 'Invalid mobile number'
                }
                return errors;
            }}
            onSubmit={(values) => {
                if (type === 'delete') {
                    deletCustomer.run(selected.id);
                } else {
                    updateSettings.run(values, selected.id)
                }
            }
            }>
            {({ dirty, setFieldValue }) => (<Form>
                <Loader useBackdrop open={updateSettings.isLoading || deletCustomer.isLoading} />

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field component={TextField} name="name" type="text" label={t("CustomerForm.Name")}
                            required variant="outlined" size="small" disabled={isReadOnly} color="secondary" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Field component={TextField} name="email" type="email" label={t("CustomerForm.Email")}
                            variant="outlined" size="small" color="secondary" disabled={isReadOnly} fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneNumberField name="mobilePhone" type="text" label={t("CustomerForm.Phone")}
                            variant="outlined" size="small" color="secondary" disabled={isReadOnly} fullWidth required />
                    </Grid>
                </Grid>
                <div className={classes.buttons}>
                    <Divider variant="fullWidth" className={classes.hrLine} />

                    {!isReadOnly && <Button id="create-customer" variant="contained" type="submit"
                        disabled={!dirty} className={classes.button} color="secondary">
                        {props.type === 'add' ? t('CustomerForm.CreateBtn') : t('CustomerForm.EditBtn')}
                    </Button>}
                    {type === 'delete' && <Button id="delete-customer" variant="contained"
                        type="submit" className={classes.button} color="secondary">
                        {t('CustomerForm.DeleteBtn')}
                    </Button>}
                    <Button id="cancel-create-customer" onClick={handleClose} className={classes.button} color="inherit" variant="contained">
                        {!isReadOnly ? t('CustomerForm.CancelBtn') : t("CustomerForm.CloseBtn")}
                    </Button>
                </div>
            </Form>)}
        </Formik >
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(0.5, 1),
            borderRadius: '3px',
            width: '100px',
            height: '35px',
            fontSize: '16px',
            lineHeight: '19px',
        },
        menuItem: {
            "& li:hover": {
                background: 'skyblue'
            },
            "& li.Mui-selected:hover": {
                background: "skyblue"
            }
        },
        hrLine: {
            width: '100%',
            margin: theme.spacing(1, 0),
        }
    }));