import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, createStyles, Divider, IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import React, { useContext } from 'react';
import { ReactComponent as Key } from '../assets/Password.svg';
import { ModalContext } from '../container/ModalContainer';


interface MenubarProps {
    username: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        avatar: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            width: '30px',
            height: '30px',
            fontSize: '16px',
        },
        menuBorder: {
            filter: 'drop-shadow(3px 4px 2px grey)',
            border: '1px solid lightgrey'
        },
        menuBarText: {
            cursor: "default",
            fontSize: "14px",
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        menuClickable: {
            cursor: "pointer",
            fontSize: "16px",
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                textDecoration: 'underline'
            }
        }
    }),
);

export default function Menubar({ username }: MenubarProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const { user, logout } = useAuth0();
    const alerts = useContext(ModalContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePasswordChange = () => {
        const requestOptions: any = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "client_id": process.env.REACT_APP_CLIENT_ID,
                "email": user.email,
                "connection": process.env.REACT_APP_CONNECTION,
            }),
        };
        fetch(`https://${process.env.REACT_APP_DOMAIN}/dbconnections/change_password`, requestOptions)
            .then(response => response.text())
            .then(result => {
                alerts.showDone(result);
            })
            .catch(error => {
            });
    }

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
        handleClose();
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="logout-menu-btn"
                aria-haspopup="true"
                id="user-avatar"
                onClick={handleClick}
                style={{ padding: '5px' }}
            >
                <Avatar className={classes.avatar}>{user && user.name ? user.name.toUpperCase().charAt(0) : ''}</Avatar>
            </IconButton>
            <Menu
                id="logout-menu"
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menuBorder }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                {user &&
                    <MenuItem className={classes.menuBarText} disableRipple>
                        <Typography variant="subtitle1">{user.name}</Typography>
                        <Typography variant="caption">{user.email}</Typography>
                    </MenuItem>}
                <MenuItem className={classes.menuClickable} onClick={handlePasswordChange}>
                    <Key style={{ margin: "0 5px" }} fill="#333" />
                    Change Password
                    </MenuItem>
                <Divider variant="fullWidth" />
                <MenuItem id="logout-menu-item" className={classes.menuClickable} onClick={handleLogout}>
                    <ExitToApp style={{ margin: "0 5px", color: "#333" }} />
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}
