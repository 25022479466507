import { Timelapse } from '@material-ui/icons';
import React from 'react';
import { ReactComponent as HomeFilled } from '../../assets/HomeFilled.svg';
import { ReactComponent as StoreFilled } from '../../assets/StoreFilled.svg';
import { ReactComponent as VideoFilled } from '../../assets/VideoFilled.svg';
import { ReactComponent as VideoOutlined } from '../../assets/VideoOutlined.svg';

interface EventIconType {
    type: string;
    fill: string;
    fontSize?: any;
}

export function EventIcon({ type, fill }: EventIconType) {
    switch (type) {
        case 'LocationVisit':
        case 'StoreVisit':
            return <StoreFilled fill={fill} />
        case 'VideoCall':
            return <VideoFilled fill={fill} />
        case 'HomeVisit':
            return <HomeFilled fill={fill} />
        case 'Multi':
            return <VideoOutlined fill={fill} />
        default:
            return <Timelapse color="secondary" />
    }
}
