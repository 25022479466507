import { useAsync } from 'react-async';
import client, { clientAsync } from './api-client';

const TEMPLATE_ENDPOINT = 'organizations/me/notificationTemplates'


const getTemplates = async () => {
    return client(TEMPLATE_ENDPOINT);

}

export function useNotificationTemplates() {
    return useAsync({ promiseFn: getTemplates, deferFn: getTemplates });
}

const getWritableURL = async ([type]: any) => {
    let url = `${TEMPLATE_ENDPOINT}/writableEmailTemplateUrl`;
    if (type) {
        url += `?templateType=${type}`
    }
    return client(url);
};

export const getWritableURLForUpload = async (type: any) => {
    let url = `${TEMPLATE_ENDPOINT}/writableEmailTemplateUrl`;
    if (type) {
        url += `?templateType=${type}`
    }
    return await (await clientAsync(url)).text();
    //return client(url);
};


export function useGetWritableURL() {
    return useAsync({ deferFn: getWritableURL });
}

const deleteTemplate = async ([type]: any) => {
    let deleteURL = `${TEMPLATE_ENDPOINT}/${type}`
    return client(deleteURL, { method: 'DELETE' });
};

export function useDeleteTemplate(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: deleteTemplate, onResolve: onResolve, onReject: onReject });
}

const updateTemplate = async ([templateObj]: any) => {
    let deleteURL = TEMPLATE_ENDPOINT
    return client(deleteURL, { data: templateObj, method: 'PUT' });
};

export function useUpdateTemplate(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: updateTemplate, onResolve: onResolve, onReject: onReject });
}