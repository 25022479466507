import React, { useMemo } from 'react';
import { MenuItem, TextField } from "@material-ui/core";
import 'react-datepicker/dist/react-datepicker.css';
import useCustomTranslation from '../../container/useCustomTranslation';
import { getTimeDurationSlots, IDuration } from '../.././utils/eventUtils';


export default function SelectDuration(props: any) {
    const { dayWorkingPlan, value, onChange, durationBasedOnType } = props;

    const availableDurations = useMemo(() => { return getTimeDurationSlots(durationBasedOnType, dayWorkingPlan.maximumSlot) },
        [durationBasedOnType, dayWorkingPlan.maximumSlot]);
   
        const newVal = useMemo(() => {
            const availableDurationsValues = availableDurations &&
                availableDurations.map((availabelDuration: IDuration, index: number) => {
                    return availabelDuration.duration
                });
            if (availableDurations && availableDurations.length > 0) {
                return availableDurationsValues.reduce(function (prev: any, curr: any) {
                    return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
                    
                })
            }
            else {
                return null
            }
               
        }, [availableDurations, value]);
    


    const { t } = useCustomTranslation();

    return (
        <TextField select id="cust-duration" label={t('Duration')} size="small" color="secondary" fullWidth
            variant="outlined" onChange={(e) => onChange(e.target.value)}
            value={newVal ? newVal : ''}
            disabled={!availableDurations || availableDurations.length === 0}>
            {availableDurations && availableDurations.map((availabelDuration: IDuration, index: number) => {
                return <MenuItem id={index + ''} key={index} value={availabelDuration.duration}>{availabelDuration.label}</MenuItem>;
            })}
        </TextField>
    );

};