import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { getTimeDurationSlots, IDuration } from '../.././utils/eventUtils';

export function DurationContainer({
  data,
  dayWorkingPlan,
  durationBasedOnType,
  setSelectedDuration,
  durationValue
}: any) {
  const availableDurations = getTimeDurationSlots(durationBasedOnType, dayWorkingPlan.maximumSlot);

  return (
    <TextField select id="aptmt-duration" label={'Duration'}
      size="small" color="secondary" fullWidth variant="outlined"
      value={durationValue}
      onChange={(e) => setSelectedDuration(e.target.value)}
      disabled={availableDurations.length === 0}>
      {availableDurations && availableDurations.map((availabelDuration: IDuration, index: number) => {
        return <MenuItem id={index + ''} key={index} value={availabelDuration.duration}>{availabelDuration.label}</MenuItem>;
      })}
    </TextField>
  );

}
