import React, { useState, useContext, useMemo } from 'react';
import { makeStyles, Theme, withStyles, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, Paper, useMediaQuery } from '@material-ui/core';
import OrgSetting from '../component/Settings/OrgSetting'
import Appointment from '../component/Settings/Appointment'
import BusinessHours from '../component/Settings/BusinessHours'
import Employees from '../component/Settings/Employees'
import Holidays from '../component/Settings/Holidays'
import Stores from '../component/Settings/Stores'
import VideoConferencing from '../component/Settings/VideoConferencing'
import { useAsync } from 'react-async';
import client from '../api/api-client';
import Loader from '../component/Loader';
import OrgContext from '../container/OrgContext';
import useCustomTranslation from '../container/useCustomTranslation';
import Terminology from '../component/Settings/Terminology';
import Notification from '../component/Settings/Notification';
import About from '../component/Settings/About';
import { ModalContext } from '../container/ModalContainer';
import Subscription from '../component/Settings/Subscription';
import UpgradeToSubscription from '../component/Settings/subscriptionComponent/UpgradeToSubscription';
import PaymentGateway from '../component/Settings/PaymentGateway'

interface TabPanelProps {
    value: number;
    org: any;
    refreshFn: any;
    errPopup: any;
}


const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: '16px',
            lineHeight: '20px',
            margin: theme.spacing(0, 1),
            minWidth: 0
        },
        wrapper: {
            textAlign: 'left',
            alignItems: 'flex-start'
        },
        selected: {
            fontWeight: 600
        }
    }))((props: any) => {
        return (
            <Tab disableRipple {...props} />
        );
    });

function SelectedTab(props: TabPanelProps) {
    const { value } = props;
    switch (value) {
        case 1:
            return <Stores {...props} />
        case 2:
            return <Employees {...props} />
        case 3:
            return <BusinessHours {...props} />
        case 4:
            return <Holidays {...props} />
        case 5:
            return <Appointment {...props} />
        case 6:
            return <VideoConferencing {...props} />
        case 7:
            return <About {...props} />
        case 8:
            return <Notification {...props} />
        case 9:
            return <Terminology {...props} />
        case 10:
            return <Subscription {...props} />
        case 11:
            return <PaymentGateway {...props} />
        case 0:
        default:
            return <OrgSetting {...props} />
    };
}

const useVerticalStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        textAlign: 'left',
        marginTop: theme.spacing(2)
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '20vw',
        minWidth: '64px'
    },
    content: {
        margin: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        width: '100%',
        maxWidth: '100%'
    },

}));

const useHorizontalStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        marginTop: theme.spacing(2)
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: '100%',
        minWidth: '64px'
    },
    content: {
        margin: theme.spacing(0, 1),
        marginBottom: theme.spacing(1),
        maxWidth: '100%'
    },

}));


const fetchSettings = async () => {
    return client('organizations/me');
};

export default function Settings(props: any) {
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const defaultTab = props.location && props.location.state
        && props.location.state.tabIndex ? props.location.state.tabIndex : 0;
    const horizontalStyle = useHorizontalStyles();
    const verticalStyle = useVerticalStyles();
    const classes = isSmallDevices ? horizontalStyle : verticalStyle;
    const { t } = useCustomTranslation();
    const [value, setValue] = useState(defaultTab);
    const [org, setData] = useContext(OrgContext);
    const alertHelpers = useContext(ModalContext);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const isFreePlan = useMemo(() => {
        return org && (!org.subscriptionPlan || org.subscriptionPlan.subscriptionPlanType === 'Free')
    }, [org]);
    const onRes = (data: any) => {
        setData(data);
    }

    const onErr = (err: any) => {
        if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
            alertHelpers.showErr(err.ErrorMessages.join(', '));
        }
        else {
            alertHelpers.showErr("Error occurred while Saving data");
        }
    };

    const { isPending, data, error, run } = useAsync({ deferFn: fetchSettings, onResolve: onRes });
    if (!org.name && !error) {
        return <Loader useBackdrop open={true} />
    } else if (error) {
        return <div>{t('Settings.ErrSettings')}</div>

    }
    return (
        <Paper variant="outlined" className={classes.root}>
            <Tabs
                textColor='secondary' className={classes.tabs}
                orientation={isSmallDevices ? "horizontal" : "vertical"}
                variant="scrollable"
                scrollButtons={isSmallDevices ? "on" : "off"}
                value={value} onChange={handleChange}>
                <StyledTab id="org" label={t('Settings.Organisation')} value={0} />
                <StyledTab id="stores" label={t('Settings.Stores')} value={1} />
                <StyledTab id="employees" label={t('Settings.Employees')} value={2} />
                <StyledTab id="busines-hrs" label={t('Settings.BusinessHours')} value={3} />
                <StyledTab id="holidays" label={t('Settings.Holidays')} value={4} />
                <StyledTab id="aptmnt" label={t('Settings.Appointment')} value={5} />
                <StyledTab id="vc" label={t('Settings.VideoConferencing')} value={6} />
                <StyledTab id="about" label={t('Settings.About')} value={7} />
                <StyledTab id="notification" label={t('Settings.Notification')} value={8} />
                <StyledTab id="terminology" label={t('Settings.Terminology')} value={9} />
                <StyledTab id="paymentGateway" label={t('Settings.PaymentGateway')} value={11} />
                <StyledTab id="subscription" label={t('Settings.Subscription')} value={10} />
            </Tabs>
            <Box className={classes.content}>
                {isFreePlan && [6, 8, 11].includes(value) ?
                    <UpgradeToSubscription handleChange={handleChange} /> :
                    <SelectedTab org={org} value={value} refreshFn={run} errPopup={onErr} />
                }
            </Box>
            <Loader useBackdrop open={isPending} />
        </Paper>
    )
}