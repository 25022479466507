import { Container, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { createAdminAppointment, createBulkAppointment } from "../../api/calendarService";
import { useFetchSchedules } from "../../api/useFetchSchedules";
import { ModalContext } from "../../container/ModalContainer";
import OrgContext from '../../container/OrgContext';
import useCustomTranslation from "../../container/useCustomTranslation";
import { getMinMaxStoreTime, getUTCTimeFormatForTimeSlot, mapAppointmentSchedles } from "../../utils/eventUtils";
import BookingDialog from "../Calendar/BookingDialog";
import CalendarUI from "../Calendar/CalendarUI";
import EventList from "../Calendar/EventListDialog";
import Loader from "../Loader";
import StoreCalHeader from "./StoreCalHeader";
import { uploadAllFiles } from '../Calendar/FormHelper';

const bookingInit = { open: false, bulk: false };

export default function StoreSchedule(props: any) {
    const { stores } = props;
    const [org] = useContext(OrgContext);
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const alert = React.useContext(ModalContext);

    const [detailsOpen, setDetailsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<any>();
    const [bookingOpen, setBookingOpen] = useState(bookingInit);
    const [isLoading, setLoading] = useState(false);
    const [calVal, setCalVal] = useState({ date: new Date(), view: 'day' });
    const [events, setEvents] = useState<any>([]);
    const [calEvents, setCalEvents] = useState<any>([]);
    const [eventTimes, setEventTimes] = useState<any>({
        startDate: moment().startOf('week').toISOString(),
        endDate: moment().endOf('week').toISOString()
    });

    const dstate: any = {
        view: 'day'
    };
    const [state, setState] = useState(dstate);

    const minSlot = useMemo(() => {
        let durations = [];
        for (let i = 0; i < stores.length; i++) {
            if (stores[i]['homeAppointmentDuration'] > 0) {
                durations.push(stores[i]['homeAppointmentDuration']);
            }
            if (stores[i]['locationAppointmentDuration'] > 0) {
                durations.push(stores[i]['locationAppointmentDuration']);
            }
            if (stores[i]['videoAppointmentDuration'] > 0) {
                durations.push(stores[i]['videoAppointmentDuration']);
            }
        }
        if (durations.length === 0) { return 15; }
        return Math.min.apply(null, durations);
    }, [stores]);

    const resourceMap = useMemo(() => {
        return stores.map((s: any) => {
            return { locationId: s.id, locationName: s.name }
        })
    }, [stores]);

    const schedule = useMemo(() => {
        return getMinMaxStoreTime(stores, org, calVal.date);
    }, [stores, calVal.date]);

    const { isPending, data, schedules } = useFetchSchedules(eventTimes.startDate, eventTimes.endDate);
    useEffect(() => {
        if (data && data.models) {
            setEvents(data.models);
        }
    }, [data]);

    useEffect(() => {
        setCalEvents(mapAppointmentSchedles(events, minSlot));
    }, [events]);

    const handleDeatilClose = (value: any) => {
        setDetailsOpen(false);
    };
    const handleEventOpen = (value: any) => {
        setSelectedValue(value);
        setDetailsOpen(true);
    };

    const handleBookingOpen = (value: any) => {
        if (value.resourceId) {
            const store = stores.find((s: any) => s.id === value.resourceId);
            const start = moment.utc(value.start);
            const end = moment.utc(value.end);
            const minutes = end.diff(start, 'minutes')
            const storeData = { storeId: value.resourceId, storeData: { store }, start: value.start, end: value.end, minutes };

            setSelectedValue(storeData);
        }
        setBookingOpen({ bulk: value === true, open: true });
    };

    const handleSlotSelect = (value: any) => {
        if (value.action && value.action !== 'click') {
            handleBookingOpen(value);
        }
    }

    const handleBookingClose = (value: any) => {
        setBookingOpen(bookingInit);
        setSelectedValue(null);
        if (value === true) {
            refreshAll();
        }
    };

    const handleCreate = (aptData: any) => {
        setLoading(true);
        const createAppointment = bookingOpen.bulk ? createBulkAppointment : createAdminAppointment;

        createAppointment(aptData).then(async (response: any) => {
            setLoading(false);
            const aptData = await response.json();
            if (response.ok) {
                return aptData;
            } else {
                return Promise.reject(aptData);
            }
        }).then(result =>{
            uploadAllFiles(result, aptData.formFields);
            return result;
        }).then(result => {
            handleBookingClose(false);
            alert.showDone(t('Alerts.BookingDone'));
            if (result) {
                setEvents(events.concat(result));
            }
        }).catch(err => {
            if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                alert.showModal({ msg: err.ErrorMessages.join(', '), type: 'error' });
            }
            else {
                alert.showModal({ msg: "Error occurred while creating appointment", type: 'error' });
            }
        });
    }

    const refreshAll = () => {
        changeCalView({ isForcedRefresh: true });
    }

    const changeCalView = ({ startDate, endDate, isForcedRefresh }: any) => {
        if (isForcedRefresh) {
            schedules({ startDate: eventTimes.startDate, endDate: eventTimes.endDate });
            return;
        }

        const isStartChanged = moment(startDate).isBefore(moment(eventTimes.startDate));
        const isEndChanged = moment(endDate).isAfter(moment(eventTimes.endDate));
        // Later change mechanisum to append data instead of calling huge data
        if (isStartChanged) {
            setEventTimes({ startDate, endDate: eventTimes.endDate })
            schedules({ startDate, endDate: eventTimes.endDate });
        } else if (isEndChanged) {
            setEventTimes({ startDate: eventTimes.startDate, endDate })
            schedules({ startDate: eventTimes.startDate, endDate });
        }
    }

    const handleRangeChange = (range: any, view: any) => {
        let startDate = moment(range[0]).toISOString();
        let endDate = moment(range[0]).toISOString();

        if (range.start) {
            startDate = moment(range.start).toISOString();
            endDate = moment(range.end).toISOString();
            setCalVal(range.start)
        }
        else if (range.length > 1) {
            endDate = moment(range[range.length - 1]).toISOString()
        } else if (range.length === 1) {
            endDate = moment(range[0]).add(1, 'day').toISOString()
        }
        setCalVal({ date: new Date(startDate), view: view })

        changeCalView({ startDate, endDate });
    }

    return (
        <main className={classes.main}>
            <Grid container alignItems="center" className={classes.miniHeader}>
                <StoreCalHeader refresh={refreshAll}
                    onAddClick={handleBookingOpen}
                    handleRangeChange={handleRangeChange}
                    datepassed={calVal.date}
                />
            </Grid>
            <Container maxWidth={false} className={classes.baseContainer}>
                <CalendarUI
                    handleEventOpen={handleEventOpen}
                    handleSlotSelect={handleSlotSelect}
                    handleRangeChange={handleRangeChange}
                    events={calEvents}
                    timings={{ slot: minSlot, startTime: schedule.startTime, endTime: schedule.endTime }}
                    resourcing={{ map: resourceMap, resourceIdAccessor: 'locationId', resourceAccessor: 'locationName' }}
                    calVal={calVal}
                    state={state} setState={setState}
                />
                {detailsOpen && <EventList events={selectedValue.events}
                    handleClose={handleDeatilClose} open={detailsOpen} />}
                {bookingOpen.open &&
                    <BookingDialog
                        selectedSlot={selectedValue && getUTCTimeFormatForTimeSlot(moment(selectedValue.start).format('HH:mm'))}
                        duration={selectedValue && selectedValue.minutes}
                        datepassed={new Date() > calVal.date ? new Date() : calVal.date}
                        open={bookingOpen.open}
                        bulk={bookingOpen.bulk}
                        storeList={stores}
                        storeId={selectedValue && selectedValue.storeId ? selectedValue.storeId : null}
                        storeData={selectedValue && selectedValue.storeData ? selectedValue.storeData : null}
                        handleClose={handleBookingClose}
                        onCreate={handleCreate} />}
                <Loader useBackdrop open={isLoading || isPending} />
            </Container>
        </main>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            overflow: 'hidden',
            height: 'calc(100vh - 60px)',
            display: 'flex',
            flexDirection: 'column'
        },
        miniHeader: {
            zIndex: 11,
            boxShadow: "0px 1px 3px 1px #b3b3b3",
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignContent: 'center'
        },
        baseContainer: {
            //  overflowY: 'auto',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '8px',
                paddingRight: '8px'
            }
        }
    }),
);
