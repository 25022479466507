import React from 'react';
import { TextField, MenuItem, CircularProgress } from '@material-ui/core';
import { slotModel } from '../../utils/slotModel';

export function SlotsContainer({
  isPending,
  error,
  data,
  setSelectedSlot,
  slotValue,
  dateValue
}: any) {
  if (isPending) {
    return <CircularProgress color="secondary" size="2rem" />
  }
  return (
    <TextField select id="aptmt-slot" label={data && data.length > 0 ? "Time Slot" : "No Time Slot"}
      size="small" color="secondary" fullWidth variant="outlined" value={!data || data.length === 0 ? '-1' : slotValue}
      onChange={(e) => setSelectedSlot(e.target.value)} disabled={!data || data.length === 0}
    >
      {data && data.length > 0 && data.map((d: any, index: number) => {
        return <MenuItem id={index + ''} key={index} value={d}>
          {slotModel(d).getFormattedValue(dateValue ? dateValue : new Date())}
        </MenuItem>;
      })}
    </TextField>
  );

}
