import { Grid, Typography } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import { getDescUploadURL } from '../../api/settingService';
import useCustomTranslation from '../../container/useCustomTranslation';
import Loader from '../Loader';
import { SaveButton } from './AddBtn';
import SunTemplateEditor from './SunTemplateEditor';

export default function About(props: any) {
  const { org, refreshFn } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useCustomTranslation();
  const [docContent, setDocContent] = useState<any>();

  const downloadTemplate = () => {
    if (org && org.descriptionUrl) {
      setLoading(true);
      fetch(org.descriptionUrl, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })
        .then((res) => res.text())
        .then((res) => {
          setLoading(false);
          setDocContent(res);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  useLayoutEffect(() => {
    downloadTemplate();
  }, []);

  const onSave = async () => {
    try {
      setLoading(true);
      const uploadURL = await getDescUploadURL();
      const blobData = new Blob([docContent]);

      var myHeaders = new Headers();
      myHeaders.append('x-ms-blob-type', 'BlockBlob');
      myHeaders.append('Access-Control-Allow-Origin', '*');

      let requestOptions: any = {
        method: 'PUT',
        headers: myHeaders,
        body: blobData,
        redirect: 'follow'
      };
      fetch(uploadURL, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setLoading(false);
          refreshFn();
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        style={{ padding: '16px 0', marginBottom: '16px' }}
      >
        <Grid item>
          <Typography variant="h6">{t('Settings.About')} </Typography>
        </Grid>
        <Grid item>
          <SaveButton
            id="save-about-settings"
            btnLabel={'Save'}
            onClick={onSave}
          />
        </Grid>
      </Grid>
      <Loader useBackdrop open={loading} />
      <SunTemplateEditor model={docContent} onModelChange={setDocContent} />
    </>
  );
}
