import { useAsync } from 'react-async';
import { getUTCDate } from '../utils/eventUtils';
import { getScheduledAppointments } from './calendarService';
const STORE_ENDPOINT = "locations";


export const getSlotEndpoint = (selectedDate: any, selectedStore: any, appointmentType: any) => {
  const formattedDate = getUTCDate(selectedDate).toISOString();
  return `${STORE_ENDPOINT}/${selectedStore}/appointments/availableSlots?appointmentDate=${formattedDate}&appointmentType=${appointmentType}`;
};


function useFetchSchedules(startDate: any, endDate: any) {

  const { isPending, data, error, run } = useAsync({
    promiseFn: fetchSchedules,
    deferFn: fetchSchedulesDef,
    startDate,
    endDate,
  });

  const schedules = ({ startDate, endDate }: any) => {
    return run({
      startDate,
      endDate
    });
  };

  return {
    isPending,
    data,
    error,
    schedules
  };
}

export { useFetchSchedules };

const fetchSchedules = async ({
  startDate, endDate
}: any) => {
  return getScheduledAppointments(startDate, endDate);
};

const fetchSchedulesDef = async ([
  { startDate, endDate }
]: any) => {
  return getScheduledAppointments(startDate, endDate);
};
