import { CircularProgress } from '@material-ui/core';
import React, { Suspense } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const toolbarButtons = [
  ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
  [
    'bold',
    'underline',
    'italic',
    'strike',
    'subscript',
    'superscript',
    'removeFormat'
  ],
  [
    'fontColor',
    'hiliteColor',
    'outdent',
    'indent',
    'align',
    'horizontalRule',
    'list',
    'table'
  ],
  [
    'link',
    'image',
    'video',
    'fullScreen',
    'showBlocks',
    'codeView',
    'preview',
    'print'
  ]
];

const sunEditorSettings = {
  height: 'auto',
  buttonList: toolbarButtons,
  charCounter: true,
  showPathLabel: false
};

export default function SunTemplateEditor(props: any) {
  const { model, onModelChange } = props;

  return (
    <Suspense fallback={<CircularProgress color="secondary" />}>
      <SunEditor
        setContents={model}
        placeholder="Type Something"
        onChange={onModelChange}
        setOptions={sunEditorSettings}
      />
    </Suspense>
  );
}
