import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Theme, createStyles, Grid, useMediaQuery, TableContainer, Table, TableHead,
    TableBody
} from '@material-ui/core';
import { useField } from 'formik';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import Card from './CustomFormCards';
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableRow from "@material-ui/core/TableRow";

const TableCell = withStyles({
    root: {
        backgroundColor : 'rgb(241, 241, 241)',
        fontWeight: 'bold',
        fontSize: '14px',
        padding: '7px 0px',
    }
})(MuiTableCell);

const TableRow = withStyles({
    root: {
        marginBottom:'5px'
    }
})(MuiTableRow);

export default function DnDCustomForm(props: any) {
    const classes = useStyles();
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const { fieldArrayHelpers, values, fieldName, setOptionRows, optionRows} = props;
    const [cards, setCards] = useState<any>([]);
    const [, , arHelper] = useField(fieldName);
    useEffect(() => {
        updateCards();
    }, [values[fieldName].length])

    const updateCards = () => {
        const cardArr: any = [];
        values[fieldName].forEach((element: any, i: any) => {
            cardArr.push({
                id: i + 1,
                data: element
            })
        });
        setCards(cardArr);
    }

    const moveCard = (id: any, atIndex: number) => {
        const { card, index } = findCard(id)
        const updateC = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
        setCards(updateC);
    }

    const findCard = (id: string) => {
        const card = cards.filter((c: any) => `${c.id}` === id)[0]
        return {
            card,
            index: cards.indexOf(card),
        }
    }

    const updateOrder = () => {
        const arVal = cards.map((c: any) => c.data);
        arHelper.setValue(arVal);
        updateCards();
    }

    const [, drop] = useDrop({ accept: 'card' })
    return ( <>
        <Grid container item xs={12} alignItems="center" ref={drop}>
        <TableContainer>
            <Table className={classes.table} aria-label="simple table" padding='none'>
                <TableHead>
                    <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="left" style={{paddingLeft:'5px'}}>Field Name</TableCell>
                        <TableCell align="left" style={{paddingLeft:'5px'}}>Field Type</TableCell>
                        <TableCell align="center">Required</TableCell>
                        <TableCell align="left" style={{paddingLeft:'5px'}}>Help Text</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cards.map((card: any, index: any) => (
                        <Card
                            key={card.id}
                            id={`${card.id}`}
                            data={card.data}
                            moveCard={moveCard}
                            findCard={findCard}
                            updateOrder={updateOrder}
                            updateCards={updateCards}
                            fieldArrayHelpers={fieldArrayHelpers}
                            index={index}
                            fieldName={fieldName}
                            values={values}
                            setOptionRows={setOptionRows}
                            optionRows={optionRows}
                        />
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Grid>
    </>)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        table:{

        },
    }),
);