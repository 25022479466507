import { Grid, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import useCustomTranslation from '../../container/useCustomTranslation';
import SunTemplateEditor from './SunTemplateEditor';

// function TemplateEditor(props: any) {
//     const { template, model, onModelChange } = props;
//     const [editorState, setEditorState] = React.useState(
//         model
//     );
//     const onEditorStateChange = (editorState: any) => {
//         setEditorState(editorState)
//     };

//     // useEffect(() => {
//     //     if (model) {
//     //         console.log(model);
//     //         const blocksFromHtml = htmlToDraft(model);
//     //         const { contentBlocks, entityMap } = blocksFromHtml;
//     //         const contentState = ContentState.createFromBlockArray(contentBlocks);
//     //         const editorState = EditorState.createWithContent(contentState);
//     //         setEditorState(model)
//     //     }
//     // }, [model]);
//     return (
//         // <Editor
//         //     editorState={editorState}
//         //     wrapperClassName="demo-wrapper"
//         //     editorClassName="demo-editor"
//         //     onEditorStateChange={onEditorStateChange}
//         // />
//         <Richtext value={model} onEdit={onModelChange} isEditMode={true} />
//     );
//     //value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
// }

export default function EmailEditor(props: any) {
  const { selectedTemplate, setSelectedTemplate, model, onModelChange } = props;
  const { t } = useCustomTranslation();

  return (
    <Grid container spacing={2} style={{ paddingRight: 0 }}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          color="secondary"
          label={t('NotificationSettings.SMS')}
          size="small"
          fullWidth
          value={selectedTemplate ? selectedTemplate.sms : ''}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          color="secondary"
          label={t('NotificationSettings.EmailSubject')}
          size="small"
          fullWidth
          value={selectedTemplate ? selectedTemplate.emailSubject : ''}
          onChange={(e) =>
            setSelectedTemplate({
              ...selectedTemplate,
              emailSubject: e.target.value
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel
          htmlFor="editor"
          shrink
          style={{
            padding: '0px 0px 8px 8px',
            background: 'white',
            width: 'max-content',
            fontSize: '1.2rem'
          }}
        >
          {t('NotificationSettings.EmailBody')}
        </InputLabel>
        <div id="editor">
          <SunTemplateEditor
            id="editor"
            model={model}
            onModelChange={onModelChange}
          />
        </div>
      </Grid>
    </Grid>
  );
}
