import React from "react";
import {
    Grid, makeStyles, createStyles
} from '@material-ui/core'
import Store from './Store'

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            margin: '10px 0'
        },
        gridList: {
            border: 0,
            textAlign: 'left',
        },

    }),
);

export default function StoreList(props: any) {
    const classes = useStyles();
    const { stores } = props;
    return (
        <div className={classes.root}>
            <Grid container spacing={1} className={classes.gridList}>
                {stores.map((store: any) => (
                    <Store key={store.id} store={store} />
                ))}
            </Grid>
        </div >
    );

};
