import React from 'react';
import {
    createStyles, Grid, Typography, Radio, Theme, Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useCustomTranslation from '../../container/useCustomTranslation';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { SaveButton } from "./AddBtn";
import Loader from "../Loader";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useUpdatePaymantGateway } from '../../api/settingService';
import { PaymentGatewayType } from '../../utils/types';

export default function PaymentGateway(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { org, refreshFn } = props;
    const updateSettings = useUpdatePaymantGateway(refreshFn);
    const [checked, setChecked] = React.useState(getEnvironmentValue(org));

    const initialValues = {
        paymentGatewayType: (org.paymentGatewaySettings && org.paymentGatewaySettings.paymentGatewayType) || PaymentGatewayType.None,
        environment: org.paymentGatewaySettings && org.paymentGatewaySettings.environment,
        apiKey: org.paymentGatewaySettings && org.paymentGatewaySettings.apiKey,
        apiSecret: org.paymentGatewaySettings && org.paymentGatewaySettings.apiSecret,
    }

    const handleChange = (e: any, values: any, setValues: any) => {
        const selectedValue = e.target.value;
        setValues({ ...values, paymentGatewayType: selectedValue });
    }

    const handleCheckedChange = (event: any, values: any, setValues: any) => {
        var val;
        setChecked(event.target.checked);
        if(event.target.checked){ val = 'Production'; }
        else{ val = 'Sandbox'; }
        setValues({ ...values, environment: val });
    }

    return (<>
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                updateSettings.run({ paymentGatewaySettings: values });
            }}>
            {({ setValues, values, dirty }) => (
                <Form>
                    <Loader useBackdrop open={updateSettings.isLoading} />
                    <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0', marginBottom: '8px' }}>
                        <Grid item>
                            <Typography variant="h6">{t('Settings.PaymentGateway')} </Typography>
                        </Grid>
                        <Grid item>
                            <SaveButton type="submit" id="save-basic-settings" disabled={!dirty} btnLabel={"Save"} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid container item xs={12} justify="flex-start">
                            <Field component={RadioGroup} row name="videoConferenceType"
                                value={values.paymentGatewayType} onChange={(e: any) => handleChange(e, values, setValues)}>
                                <FormControlLabel value={PaymentGatewayType.None} control={<Radio />} label={t("PaymentGateway.None")} />
                                <FormControlLabel value={PaymentGatewayType.Razorpay} control={<Radio />} label={t("PaymentGateway.RazorPay")} />
                                <FormControlLabel value={PaymentGatewayType.PayPal} control={<Radio />} label={t("PaymentGateway.PayPal")} />
                            </Field>
                        </Grid>

                        {values.paymentGatewayType !== PaymentGatewayType.None && <>
                            <Grid item xs={12} md={5}>
                                <Grid container item spacing={3}>
                                    {values.paymentGatewayType === PaymentGatewayType.PayPal && <Grid item xs={12} >
                                    {checked ? <Typography variant="body2" style={{ fontWeight: 'lighter' }} display="inline"> SandBox </Typography>
                                        : <Typography variant="subtitle2" color='secondary' display="inline"> SandBox </Typography>}
                                    <Typography variant="caption" display="inline" style={{ marginLeft: '10px' }} >
                                        <Switch
                                            checked={checked}
                                            onChange={(e: any) => handleCheckedChange(e, values, setValues)}
                                            size='small'
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </Typography>
                                    {checked ? <Typography variant="subtitle2" color='secondary' display="inline"> Production</Typography>
                                        : <Typography variant="body2" style={{ fontWeight: 'lighter' }} display="inline"> Production</Typography>}
                                    </Grid>}
                                    <Grid item xs={12}>
                                        <Field component={TextField} name="apiKey" type="text" label={t("PaymentGateway.ApiKey")}
                                            required variant="outlined" size="small" color="secondary" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field component={TextField} name="apiSecret" type="text" label={t("PaymentGateway.ApiSecret")}
                                            required variant="outlined" size="small" color="secondary" fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container item spacing={2} className={classes.helpboxBorder}>
                                    <Grid item className={classes.headerText}>
                                        Steps to get Razorpay credentials :
                        </Grid>
                                    <Grid item className={classes.labelText}>
                                        <ol className={classes.orderList}>
                                            <li>
                                                Login to <a href="https://dashboard.razorpay.com/#/access/signin" target="_blank" rel="noopener noreferrer">Razorpay account</a>. If you don't have account, you need to go through the Sign Up process.
                                </li>
                                            <li>
                                                Navigate to Settings on the left panel and go to API keys tab on the top.
                                </li>
                                            <li>
                                                Click on 'Generate Key' button to generate ApiKey and ApiSecret.
                                </li>
                                            <li>
                                                Copy ApiKey and ApiSecret here and click on 'Save' button.
                                </li>
                                        </ol>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>}
                    </Grid>
                </Form>
            )}
        </Formik>
    </>);

}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        helpboxBorder: {
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                paddingLeft: '0px'
            },
            borderLeft: '1px solid #aaa',
            paddingLeft: theme.spacing(1),
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px'
        },
        labelText: {
            fontSize: '14px',
            color: '#6C6C6C',
            lineHeight: '21px'
        },
        orderList: {
            margin: theme.spacing(0, 2),
            padding: theme.spacing(0, 1),
            '& li': {
                margin: theme.spacing(0.75, 0),
                '&::marker': {
                    fontWeight: 'bold',
                }
            }
        },
    }),
);


function getEnvironmentValue(org : any){
    const env = org.paymentGatewaySettings && org.paymentGatewaySettings.environment
    return env === 'Production' ? true : false ;
}