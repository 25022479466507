//@ts-nocheck
import { red, amber } from "@material-ui/core/colors";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const fontFamilyRoboto = {
  fontFamily: [
    "Segoe UI",
    "Roboto",
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(",")
};

const fontFamilyMetropolis = {
  fontFamily: [
    "Segoe UI",
    "Metropolis",
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  letterSpacing: "0.015rem"
};

// A custom theme for this app
const lightMuiTheme = createMuiTheme({
  type: "light",
  palette: {
    primary: {
      main: "#FFF"
    },
    secondary: {
      main: '#7719aa',
    },
    error: {
      main: '#CB070D'
    },
    background: {
      default: "#FFF",
      highlight: "#F1F3F4"
    },
    text: {
      primary: "#1E2226",
      secondary: "#6C6C6C"
    }
  },
  typography: {
    ...fontFamilyRoboto,
    overline: {
      fontWeight: 600,
      fontSize: "0.7rem",
      fontStyle: 'normal'
    },
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: "5px"
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  mixins: {
    drawer: {
      minWidth: 280
    }
  },
  overrides: {
    MuiListItemText: {
      primary: {
        ...fontFamilyMetropolis,
        fontWeight: 600,
        fontSize: "0.87rem",
        fontStyle: 'normal'
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#7719aa',
        },
        '&$active': {
          color: '#7719aa',
        }
      },
      text: {
        fill: '#FFF',
      }
    }
  },
  custom: {
    fontFamily: {
      roboto: fontFamilyRoboto,
      metropolis: fontFamilyMetropolis
    },
    palette: {
      primaryDark: '#0d0e10',
      primary: '#1e2226',
      primaryLight: '#f9fafa',
      primaryLighter: '#e3e6e9',
      surface: '#fff',
      secondaryDark: '#470f66',
      secondary: '#7719aa',
      secondaryLight: '#a137da',
      secondaryLighter: '#e9e6eb',
      shadowColor: 'rgba(52,56,60,0.28)',
      fontColorLight: '#818181',
      fontColorDark: '#6c6c6c',
      borderColor: '#DFE3E9',
    }
  }
});

const darkMuiTheme = createMuiTheme({
  type: "dark",
  palette: {
    primary: {
      main: "#202124"
    },
    secondary: {
      main: amber[500],
      light: "#41331C"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#202124",
      paper:"#202124",
      highlight: "#535456"
    },
    text: {
      primary: "#19bac5",
      secondary: "#aaa"
    }
  },
  typography: {
        ...fontFamilyRoboto,
    overline: {
      fontWeight: 600,
      fontSize: "0.7rem",
      fontStyle: 'normal'
    },
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: "5px"
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  mixins: {
    drawer: {
      minWidth: 280
    }
  },
  overrides: {
    MuiListItemText: {
      primary: {
        ...fontFamilyMetropolis,
        fontWeight: 600,
        fontSize: "0.87rem",
        fontStyle: 'normal'
      }
    }
  },
  custom: {
    fontFamily: {
      roboto: fontFamilyRoboto,
      metropolis: fontFamilyMetropolis
    },
    palette: {
      primaryDark: '#0d0e10',
      primary: '#1e2226',
      primaryLight: '#f9fafa',
      primaryLighter: '#e3e6e9',
      surface: '#aaa',
      secondaryDark: '#470f66',
      secondary: '#7719aa',
      secondaryLight: '#a137da',
      secondaryLighter: '#e9e6eb',
      shadowColor: 'rgba(52,56,60,0.28)',
      fontColorLight: '#818181',
      fontColorDark: '#6c6c6c',
      borderColor: '#DFE3E9',
    }
  }
});

export const light = responsiveFontSizes(lightMuiTheme);
export const dark = responsiveFontSizes(darkMuiTheme);