import { format, isSameDay, startOfDay, add } from 'date-fns';

function slotModel(slotStr) {
  const getDDHHMMUTC = () => {
    const [s1, s2] = slotStr.split(':');
    const s1DDHH = s1.split('.');
    if (s1DDHH.length === 2) {
      const dd = parseInt(s1DDHH[0]);
      const hh = parseInt(s1DDHH[1]);
      const mm = getMinutes(s1DDHH[1], s2);
      return {
        dd,
        hh,
        mm
      };
    }
    const hh = parseInt(s1);
    const mm = getMinutes(s1, s2);
    return { dd: 0, hh, mm };
  };

  const isNegative = (slotVal) => {
    return slotStr.startsWith('-');
  };

  const getMinutes = (hhStr, mmStr) => {
    const mm = parseInt(mmStr);
    if (isNegative(hhStr)) {
      return mm * -1;
    }
    return mm;
  };

  const getLocalTime = (date) => {
    const { dd, hh, mm } = getDDHHMMUTC();
    const minsAdded = add(startOfDay(date), {
      days: dd,
      hours: hh,
      minutes: mm
    });
    return new Date(
      Date.UTC(
        minsAdded.getFullYear(),
        minsAdded.getMonth(),
        minsAdded.getDate(),
        minsAdded.getHours(),
        minsAdded.getMinutes()
      )
    );
  };

  const getFormattedValue = (date) => {
    const localDate = getLocalTime(date);
    if (isSameDay(date, localDate)) {
      return format(localDate, 'h:mm a');
    }
    return format(localDate, 'hh:mm a (d MMM)');
  };

  return {
    slotStr,
    getLocalTime,
    getFormattedValue
  };
}

function getPaddedValue(val) {
  return val.toString().padStart(2, '0');
}

export { slotModel, getPaddedValue };