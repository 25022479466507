import { isSameDay, parseISO,differenceInMinutes } from 'date-fns';
import { max } from 'lodash';
import { slotModel } from './slotModel';

const DAYS_OF_WEEK:any = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};


function workdayModel(weeklyPlan = [], holidays:any) {
  const getWeeklyOffDays = () => {
    const offDays = weeklyPlan
      .filter((p:any) => !p.workingHours)
      .map((h:any) => DAYS_OF_WEEK[h.weekday]);
    return offDays;
  };

  const isWeeklyOff = (date:any) => {
    const day = date.getDay();
    return getWeeklyOffDays().includes(day);
  };

  const isWeekday = (date:any) => {
    return !isWeeklyOff(date);
  };

  const getHolidayWorkingPlan = (date:any) => {
    if (!holidays) {
      return null;
    }

    const holiday = holidays.find((h:any) => isSameDay(parseISO(h.date), date));
    if (holiday) {
      if (
        holiday.workingHours &&
        holiday.workingHours.startTime &&
        holiday.workingHours.endTime
      ) {
        return {
          holidayReason: holiday.reason,
          startHour: slotModel(holiday.workingHours.startTime).getFormattedValue(
            date
          ),
          endHour: slotModel(holiday.workingHours.endTime).getFormattedValue(date),
          isWorkingHoliday: true,
          isFullHoliday: false
        };
      }
      return {
        holidayReason: holiday.reason,
        isWorkingHoliday: false,
        isFullHoliday: true
      };
    }
    return null;
  };

  const isWorkingDay = (date:any) => {
    return isWeekday(date);
  };

  const nextWorkingDay :any = (date:any) => {
    const next = getNextDay(date);
    if (isWeekday(next)) {
      return next;
    }
    return nextWorkingDay(next);
  };

  const getDayWorkingPlan:any = (date:any) => {
    const holidayWorkingPlan = getHolidayWorkingPlan(date);
    if (holidayWorkingPlan) {
      return holidayWorkingPlan;
    }

    const day = date.getDay();
    const dayStr = Object.keys(DAYS_OF_WEEK)[day];
    const weekDay:any = weeklyPlan.find((d:any) => d.weekday === dayStr);
    if (!weekDay || !weekDay.workingHours) {
      return {
        holidayReason: 'Closed.',
        isWorkingHoliday: false,
        isFullHoliday: true
      };
    }
    let breakConfig: any = {};
    let maximumSlot: any = differenceInMinutes(slotModel(weekDay.workingHours.endTime).getLocalTime(date),
                  slotModel(weekDay.workingHours.startTime).getLocalTime(date));
    if (weekDay.break) {
      breakConfig.startHour = slotModel(weekDay.break.startTime).getFormattedValue(date);      
      breakConfig.endHour = slotModel(weekDay.break.endTime).getFormattedValue(date);
      maximumSlot = max([
        differenceInMinutes(slotModel(weekDay.break.startTime).getLocalTime(date),
          slotModel(weekDay.workingHours.startTime).getLocalTime(date)),
        differenceInMinutes(slotModel(weekDay.workingHours.endTime).getLocalTime(date),
          slotModel(weekDay.break.endTime).getLocalTime(date))
     ]);
    }
    return {
      startHour: slotModel(weekDay.workingHours.startTime).getFormattedValue(date),
      endHour: slotModel(weekDay.workingHours.endTime).getFormattedValue(date),
      breakConfig: breakConfig,
      isWorkingHoliday: false,
      isFullHoliday: false,
      maximumSlot
    };
  };

  return {
    isWeeklyOff,
    isWorkingDay,
    nextWorkingDay,
    getDayWorkingPlan
  };
}

export { workdayModel };

function getNextDay(now:any) {
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0, //TODO: MIN_HOUR required, if last slot of the day needs to be checked
    0,
    0
  );
}
