import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button, CircularProgress, createStyles,
  Divider, Grid, makeStyles,
  Step, StepLabel, Stepper, Table, TableBody,
  TableHead, TableRow, Theme,
  Tooltip, useMediaQuery,
  withStyles
} from '@material-ui/core';
import MuiTableCell from "@material-ui/core/TableCell";
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@material-ui/icons';
import { Field, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { TextField as TF } from 'formik-material-ui';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { createOrg, validateDomain } from '../api/orgCreateService';
import { ReactComponent as Information } from '../assets/Information.svg';
import DomainValidateField from '../component/FormFields/DomainValidateField';
import Loader from "../component/Loader";
import CountryCode from '../component/Settings/CountryCode';
import { ModalContext } from '../container/ModalContainer';
import useCustomTranslation from '../container/useCustomTranslation';
import { isOrgPresent } from '../utils/eventUtils';


export default function OrgForm() {
  const classes = useStyles();
  const { t } = useCustomTranslation();
  const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const padding = isSmallDevices ? 'none' : 'default';
  const { isAuthenticated, isLoading, user,
    loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
  const [token, setToken] = useState<string>();
  const alertHelpers = useContext(ModalContext);

  const initialValues = {
    organisationName: '', country: '', domain: '',
    organizationSingular: '', organizationPlural: '',
    locationSingular: '', locationPlural: '',
    employeeSingular: '', employeePlural: '',
    customerSingular: '', customerPlural: '',
    hostSingular: '', hostPlural: '',
  }

  const validateDomainPath = async (domain: string) => {
    const res = await validateDomain(domain, token)
    return res;
  }
  const getPlan = (user: any) => {
    if (user && user['https://prorigo.com/user_sub']) {
      const plan = user['https://prorigo.com/user_sub']
      return plan;
    } else {
      return null;
    }
  }
  const createOrganisation = (values: any, setFieldError: any) => {
    const orgCreateData = {
      "Name": values.organisationName,
      "Country": values.country,
      "DomainPath": values.domain,
      "countryCode": values.countryCode,
      "Terminology": {
        "Organization": {
          "Singular": values.organizationSingular,
          "Plural": values.organizationPlural
        },
        "Location": {
          "Singular": values.locationSingular,
          "Plural": values.locationPlural
        },
        "Employee": {
          "Singular": values.employeeSingular,
          "Plural": values.employeePlural
        },
        "Customer": {
          "Singular": values.customerSingular,
          "Plural": values.customerPlural
        },
        "Host": {
          "Singular": values.hostSingular,
          "Plural": values.hostPlural
        }
      },
    }
    const subscriptionPlan = getPlan(user);
    createOrg(token, orgCreateData, subscriptionPlan)
      .then(result => {
        alertHelpers.showModal({
          msg: "Your organisation has created successfully", type: 'success',
          closeAction: () => { logout({ returnTo: window.location.origin }) }
        });
      })
      .catch(err => {
        if (err && err.ErrorMessages) {
          alertHelpers.showErr(err.ErrorMessages[0]);
        } else {
          alertHelpers.showErr("Error in Create Organisation")
        }
      });
  }
  const getToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE,
        scope: "read:current_user",
      });
      return accessToken;
    } catch (e) {
      throw e;
    }
  };
  useLayoutEffect(() => {
    if (isAuthenticated) {
      getToken().then((token) => {
        setToken(token)
      })
    }
    else if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <Loader useBackdrop open={isLoading} />
  }
  if (isAuthenticated && !isOrgPresent(user)) {
    return (
      <div className={classes.bodycss} >
        <div className={classes.paper}>
          <Grid xs={12} md={4}>
            <Box className={classes.boxbody} color="secondary">
              <Box border={1} className={classes.boxheader}>
                <img src={process.env.REACT_APP_LOGO} alt="Not Found" />
              </Box>
              <FormikStepper
                initialValues={initialValues}
                onSubmit={(values: any, { setSubmitting, setFieldError }) => {
                  createOrganisation(values, setFieldError);
                  setSubmitting(false);
                }}>
                <FormikStep
                  label="Organization Details"
                  validate={(values) => {
                    const errors: any = {};
                    if (!values.valid || values.valid === '') {
                      errors.domain = 'Please check availablity of Domain Path'
                    }
                    return errors;
                  }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field className={classes.textfields}
                        component={TF} autoComplete="organisationName" name="organisationName"
                        variant="outlined" color="secondary" required
                        fullWidth size="small" id="organisationName"
                        label="Organisation Name" autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CountryCode required />
                    </Grid>
                    <Grid item xs={12}>
                      <DomainValidateField name='domain' validate={validateDomainPath} className={classes.textfields} />
                    </Grid>
                  </Grid>
                </FormikStep>
                <FormikStep label="Terminology">
                  <Grid container spacing={2} style={{ marginTop: 'auto' }}>
                    <Grid item xs={12}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align='left' colSpan={2} padding={padding} className={classes.headerText}>
                              {t('Terminology.Term')}
                            </StyledTableCell>
                            <StyledTableCell align='left' className={classes.headerText}>
                              {t('Terminology.Singular')}
                            </StyledTableCell>
                            <StyledTableCell align='left' className={classes.headerText}>
                              {t('Terminology.Plural')}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                              {t("Terminology.Org")}
                            </TableCell>
                            <TableCell padding='none' className={classes.infocell} >
                              <StyledTooltip title={<div>Please enter synonym for Organization that you use in your business terminology.
                                <br />Example: Company, University, Hospital etc..</div>} placement="right-start">
                                <Information />
                              </StyledTooltip>
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="organizationSingular" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="organizationPlural" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                              {t("Terminology.Location")}
                            </TableCell>
                            <TableCell padding='none' className={classes.infocell}>
                              <StyledTooltip title={<div>Please enter synonym for Location that you use in your business terminology.
                                <br />Example: Office, School, Hospital etc..</div>} placement="right-start">
                                <Information />
                              </StyledTooltip>
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="locationSingular" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="locationPlural" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                              {t("Terminology.Employee")}
                            </TableCell>
                            <TableCell padding='none' className={classes.infocell}>
                              <StyledTooltip title={<div>Please enter synonym for Employee that you use in your business terminology.
                                <br />Example: Worker, Teacher, Doctor etc..</div>} placement="right-start">
                                <Information />
                              </StyledTooltip>
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="employeeSingular" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="employeePlural" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                              {t("Terminology.Customer")}
                            </TableCell>
                            <TableCell padding='none' className={classes.infocell}>
                              <StyledTooltip title={<div>Please enter synonym for Customer that you use in your business terminology.
                                <br />Example: Customer, Student, Patient etc..</div>} placement="right-start" >
                                <Information />
                              </StyledTooltip>
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="customerSingular" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="customerPlural" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='left' padding={padding} className={classes.labelText}>
                              {t("Terminology.Host")}
                            </TableCell>
                            <TableCell padding='none' className={classes.infocell}>
                              <StyledTooltip title={<div>Please enter synonym for Host that you use in your business terminology.
                                <br />Example: Host, Salesperson, Coach etc..</div>} placement="right-start" >
                                <Information />
                              </StyledTooltip>
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="hostSingular" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                            <TableCell align='left' className={classes.textBoxPadding}>
                              <Field component={TF} name="hostPlural" type="text"
                                required variant="outlined" size="small" color="secondary" />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </FormikStep>
              </FormikStepper >
            </Box>
          </Grid>
        </div>
      </div>
    );
  } else if (isAuthenticated && isOrgPresent(user)) {
    return <Redirect to='/home' />
  }
  return null;
}


export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validate'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }: FormikConfig<FormikValues>) {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const classes = useStyles();

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      validate={currentChild.props.validate}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <Stepper alternativeLabel activeStep={step} style={{ padding: '0px' }}>
            {childrenArray.map((child, index) => (
              <Step key={child.props.label} completed={step > index || completed}>
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className={classes.subHeader}></div>
          <Divider className={classes.dividercss} />
          <div className={classes.stepperchild} >{currentChild}</div>
          <Grid container>
            {step > 0 ? (
              <Grid xs style={{ textAlign: "left" }}>
                <Button
                  disabled={isSubmitting}
                  variant="text"
                  color="secondary"
                  className={classes.backlink}
                  startIcon={<KeyboardArrowLeftOutlined fontSize="large" />}
                  onClick={() => setStep((s) => s - 1)}
                >
                  BACK
                </Button>
              </Grid>
            ) : null}
            {isSubmitting ? 'Submitting' : isLastStep() ? (
              <Grid xs style={{ textAlign: "right" }}>
                <Button
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={classes.submit}
                >
                  CREATE
                </Button>
              </Grid>
            ) : (
                <Grid xs style={{ textAlign: "right" }}>
                  <Button
                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                    disabled={isSubmitting}
                    variant="text"
                    color="secondary"
                    type="submit"
                    className={classes.nextlink}
                    endIcon={<KeyboardArrowRightOutlined fontSize="large" />}
                  >
                    CONTINUE
                </Button>
                </Grid>
              )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      fontWeight: 'bold',
      fontSize: '14px'
    },
    labelText: {
      fontWeight: 600,
      fontSize: '14px',
      paddingRight: '4px',
    },
    label: {
      fontWeight: 600,
      fontSize: '16px'
    },
    textBoxPadding: {
      paddingRight: '6px',
      [theme.breakpoints.down('sm')]: {
        padding: '4px',
      },
      '&:last-child': {
        paddingRight: '4px',
      }
    },
    boxheader: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      border: 'transparent',
    },
    boxbody: {
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
    },
    paper: {
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0px',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    bodycss:
    {
      backgroundColor: '#f9f9f9',
      width: '100vw',
      height: '100vh',
    },
    subHeader: {
      paddingTop: "20px",
      [theme.breakpoints.down('sm')]: {
        paddingTop: '8px',
      },
    },
    textfields: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '100%',
      padding: theme.spacing(3, 3, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 3, 0),
      },
    },
    infocell: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px 5px',
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
    backlink: {
      margin: theme.spacing(3, 0, 0),
    },
    nextlink: {
      margin: theme.spacing(2, 0, 1),
    },
    dividercss: {
      marginLeft: "-24px",
      marginRight: '-24px',
    },
    stepperchild: {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
    }
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none"
    },
    head: {
      backgroundColor: '#F5F8FA',
    },
    body: {
      fontSize: 14,
    },
  }),
)(MuiTableCell);

const StyledTooltip = withStyles({
  tooltip: {
    color: 'black',
    fontSize: 14,
    backgroundColor: '#F5F8FA',
    border: '1px solid #DEE2E6',
  }
})(Tooltip);

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);