import React from 'react'
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ModalContext } from '../container/ModalContainer';

export default function AlertMsg() {
    let { modalContent, closeModal } = React.useContext(ModalContext);
    const { type, msg, title, action, icon, anchorOrigin, open, duration, closeAction } = modalContent;
    const onClose = () => {
        if (closeAction) {
            closeAction();
        }
        closeModal();
    }
    if (open) {
        return (
            <Snackbar open={open} autoHideDuration={duration ? duration : 3000}
                anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: 'top', horizontal: 'center' }}
                onClose={onClose}>
                <MuiAlert elevation={4} variant="filled" onClose={onClose} action={action}
                    icon={icon ? icon : undefined}
                    severity={type}>
                    {title && <AlertTitle>{title}</AlertTitle>}
                    {msg}
                </MuiAlert>
            </Snackbar>
        );
    }
    return null;

}