import { Grid } from "@material-ui/core";
import React from "react";
import useCustomTranslation from '../../container/useCustomTranslation';
import { EventIcon } from "./EventIcon";

export default function Event(props: any) {
  const { event, view } = props;
  const { t } = useCustomTranslation();
  if (view === 'day') {
    return (
      <Grid container spacing={1} alignItems="center" style={{ height: "100%" }}>
        {event.events.length === 1 ?
          <><Grid item>
            <EventIcon type={event.events[0].type} fill="black" /></Grid>
            <Grid item>{event.events[0].customer.name}
            </Grid> </> : <Grid item>
            <b>{event.events.length}</b> {t('Calendar.Booking')}
          </Grid>}
      </Grid>)
  }

  return (
    <Grid container spacing={1} alignItems="center" style={{ height: "100%" }}>
      {event.events.length === 1 ? <>
        {view === 'day' && <Grid item><EventIcon type={event.events[0].type} fill="black" /></Grid>}
        <Grid item>{event.events[0].customer.name}</Grid></> :
        <Grid item><b>{event.events.length}</b> {t('Calendar.Booking')}</Grid>}
    </Grid>
  )
}