import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useContext, useState } from "react";
import { useClientAppointments } from "../../api/customerService";
import { ModalContext } from '../../container/ModalContainer';
import { Appointment } from "../../utils/types";
import EventListDialog from "../Calendar/EventListDialog";
import Loader from '../Loader';
import Customer from './Customer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0, 1),
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
        gridList: {
            border: 0,
            textAlign: 'left',
        },
    }),
);

export default function CustomerList(props: any) {
    const classes = useStyles();
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [events, setEvents] = useState<Appointment[]>([]);
    const { data, onEdit, onDelete } = props;
    const alertHelper = useContext(ModalContext);

    const handleDeatilClose = (value: any) => {
        setDetailsOpen(false);
    };

    const successCallback = (data: any) => {
        if (data && data.models.length > 0) {
            setEvents(data.models);
            setDetailsOpen(true)
        } else {
            alertHelper.showInfo("No appointments");
        }
    }

    const clientApt = useClientAppointments(successCallback);


    const handleEventOpen = (value: any) => {
        clientApt.run({ id: value });
    };



    return (
        <>
            <Grid container spacing={1} className={classes.gridList}>
                {data && data.map((e: any) => {
                    return (
                        <Customer key={e.id} customer={e} onCardClick={handleEventOpen} onEdit={onEdit} onDelete={onDelete} />
                    )
                })}
            </Grid>
            {detailsOpen && <EventListDialog events={events ? events : []} openedFrom="cust"
                handleClose={handleDeatilClose} open={detailsOpen} />}

            <Loader width={300} height={30} textHeight={40} useBackdrop open={clientApt.isPending} />
        </>
    );
};
