import { IconButton, InputAdornment, Popover } from '@material-ui/core';
import { FiberManualRecord, Refresh } from '@material-ui/icons';
import { Field, useField } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useRef } from 'react';
import { ChromePicker } from 'react-color';
import { ReactComponent as Palette } from '../../assets/paint-palette.svg';
export default function ColorPickerField(props: any) {
    const [field, meta, helpers] = useField(props.name || 'coloring');
    const { setValue } = helpers;
    const picker = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
        setAnchorEl(picker.current);
    };

    const reset = () => {
        setValue('')
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const updateColor = (event: any) => {
        if (Boolean(anchorEl)) {
            handleClose();
        } else {
            handleClick(event);
        }
    }

    return (
        <>
            <Field component={TextField} name={field.name} type="text" label={props.label}
                placeholder='Default' variant="outlined" size="small" color="secondary" fullWidth
                inputProps={{ disabled: true }}
                InputProps={{
                    ref: picker,
                    startAdornment:
                        (<InputAdornment position="start" style={{ paddingRight: '4px' }} onClick={updateColor}>
                            <FiberManualRecord fontSize='large' color="secondary" style={{ color: meta.value || undefined }} />
                        </InputAdornment>),
                    endAdornment:
                        (<>
                            <InputAdornment position="start">
                                <IconButton disableRipple color='secondary' edge="start" style={{ padding: '0px 4px', marginRight: '8px' }}
                                    onClick={reset} title="Reset Primary Color" >
                                    <Refresh />
                                </IconButton>
                                <IconButton disableRipple onClick={updateColor} edge="end" style={{ padding: '0px 4px' }}>
                                    <Palette />
                                </IconButton>
                            </InputAdornment>
                        </>),
                }}
            />

            <Popover open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <ChromePicker
                    color={meta.value}
                    onChange={c => {
                        setValue(c.hex)
                    }}
                />
            </Popover>

        </>
    )
}