import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import { getWritableURLForUpload, useDeleteTemplate, useNotificationTemplates, useUpdateTemplate } from '../../api/templateService';
import useCustomTranslation from '../../container/useCustomTranslation';
import Loader from '../Loader';
import { SaveButton } from './AddBtn';
import TemplateEditor from './TemplateEditor';


export default function Notification(props: any) {
    const [selectedTemplate, setSelectedTemplate] = useState<any>();
    const [docContent, setDocContent] = useState<any>();
    const { t } = useCustomTranslation();
    const { isLoading, data, reload } = useNotificationTemplates();
    const updateTemplate = useUpdateTemplate();
    const deleteTemplate = useDeleteTemplate(() => {
        reload();
        setSelectedTemplate({});
    });
    const showLoading = isLoading || updateTemplate.isLoading || deleteTemplate.isLoading;

    const setEmailTemplate = (type: any) => {
        const templateObj = data.find((d: any) => d.type === type);
        if (templateObj && templateObj.emailBody) {
            setSelectedTemplate(templateObj);
            fetch(templateObj.emailBody, {
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            })
                .then(res => res.text())
                .then(res => setDocContent(res))
                .catch(e => console.log(e));
        } else {
            setSelectedTemplate(undefined);
        }
    }

    const onSave = async () => {
        try {
            const uploadURL = await getWritableURLForUpload(selectedTemplate.type);
            const blobData = new Blob([docContent]);

            var myHeaders = new Headers();
            myHeaders.append("x-ms-blob-type", "BlockBlob");
            myHeaders.append("Access-Control-Allow-Origin", "*");

            let requestOptions: any = {
                method: 'PUT',
                headers: myHeaders,
                body: blobData,
                redirect: 'follow',
            };
            updateTemplate.run(selectedTemplate);
            fetch(uploadURL, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log(result)
                })
                .catch(error => {
                    console.log('error', error)
                });
        } catch (e) {
            console.log('e', e)
        }

    }

    const onDelete = () => {
        if (selectedTemplate) {
            deleteTemplate.run(selectedTemplate.type);
        }
    }
    //backgroundColor: '#CB070D'
    return (
        <>
            <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0', marginBottom: '8px' }}>
                <Grid item>
                    <Typography variant="h6">
                        {t('Settings.Notification')}
                    </Typography>
                </Grid>
                <Grid item>
                    <SaveButton btnLabel="Delete" Icon={Delete} style={{ margin: '2px 4px' }}
                        onClick={() => { onDelete() }} disabled={!selectedTemplate} />
                    <SaveButton btnLabel="Save" style={{ margin: '2px 4px' }}
                        onClick={() => { onSave() }} disabled={!selectedTemplate} />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ margin: '0 0 16px 0' }}>
                <TextField select id="notification-type" label={t("NotificationSettings.Label")} size="small" color="secondary" fullWidth
                    value={selectedTemplate ? selectedTemplate.type : 0}
                    variant="outlined" onChange={(e: any) => { setEmailTemplate(e.target.value) }}>
                    <MenuItem id={'0'} value={0}>{t("NotificationSettings.DefaultSelect")}</MenuItem>
                    {data && data.map((d: any, index: number) => {
                        return <MenuItem id={index + ''} key={index} value={d.type}>
                            {t(`NotificationSettings.NotificationType.${d.type}`)}
                        </MenuItem>
                    })}
                </TextField>
            </Grid>

            <Grid item xs={12}>
                {selectedTemplate && selectedTemplate.emailSubject &&
                    <TemplateEditor selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
                        model={docContent} onModelChange={setDocContent} />}
            </Grid>


            <Loader useBackdrop open={showLoading} />
        </>)

}