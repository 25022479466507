import {
    Switch, Typography
} from '@material-ui/core';
import React from 'react';

export default function PlanSwitch(props: any) {

    const { SetContext, context } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetContext({ ...context, planType: event.target.checked });
    };
    return (
        <>
        {context.planType ? <Typography variant="body2" style={{ fontWeight: 'lighter' }} display="inline"> Monthly </Typography>
                : <Typography variant="body2" color='secondary' display="inline"> Monthly </Typography>}
            <Typography variant="caption" display="inline" >
                <Switch
                    checked={context.planType}
                    onChange={handleChange}
                    name="paymentType"
                    size='small'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </Typography>
            {context.planType ? <Typography variant="body2" color='secondary' display="inline"> Annual</Typography>
                : <Typography variant="body2" style={{ fontWeight: 'lighter' }} display="inline"> Annual</Typography>}
        </>
    );

}