import {
  Box, createStyles, Grid, makeStyles, Radio, Theme, Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from "react";
import { useUpdateConference } from '../../api/settingService';
import useCustomTranslation from '../../container/useCustomTranslation';
import Loader from "../Loader";
import { SaveButton } from "./AddBtn";
import GoogleLoginAuth from './GoogleLogin';
import { PhoneNumberField } from '../FormFields/PhoneNumberField';


export default function VideoConferencing(props: any) {
  const classes = useStyles();
  const { t } = useCustomTranslation();
  const { org, refreshFn } = props;
  const updateSettings = useUpdateConference(refreshFn);

  const initialValues = {
    videoConferenceType: ((org.conferenceSettings && org.conferenceSettings.videoConferenceType) || "None"),
    userId: org.conferenceSettings && org.conferenceSettings.userId,
    apiKey: org.conferenceSettings && org.conferenceSettings.apiKey,
    apiSecret: org.conferenceSettings && org.conferenceSettings.apiSecret,
    mobilePhone: org.conferenceSettings && org.conferenceSettings.mobilePhone
  }

  const handleChange = (e: any, values: any, setValues: any) => {
    const selectedValue = e.target.value;
    setValues({ ...values, videoConferenceType: selectedValue });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (values.videoConferenceType !== 'Zoom' && values.videoConferenceType !== 'GoogleMeet') {
          values.userId = "";
        }
        if (values.videoConferenceType !== 'Zoom') {
          values.apiKey = "";
          values.apiSecret = "";
        }
        if (values.videoConferenceType !== 'WhatsApp') {
          values.mobilePhone = "";
        }
        setSubmitting(false);
        updateSettings.run({ conferenceSettings: values });
      }}
    >
      {({ setValues, values, dirty }) => (
        <Form>
          <Loader useBackdrop open={updateSettings.isLoading} />
          <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0', marginBottom: '8px' }}>
            <Grid item>
              <Typography variant="h6">
                {t('Settings.VideoConferencing')} </Typography>
            </Grid>
            <Grid item>
              <SaveButton type="submit" id="save-basic-settings" disabled={!dirty} btnLabel={"Save"} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid container item xs={12} justify="flex-start">
              <Field component={RadioGroup} row name="videoConferenceType" value={values.videoConferenceType} onChange={(e: any) => handleChange(e, values, setValues)}>
                <FormControlLabel value="None" control={<Radio />} label={t("ConfSettings.None")} />
                <FormControlLabel value="GoogleMeet" control={<Radio />} label={t("ConfSettings.Meet")} />
                <FormControlLabel value="Zoom" control={<Radio />} label={t("ConfSettings.Zoom")} />
                <FormControlLabel value="WhatsApp" control={<Radio />} label={t("ConfSettings.WhatsApp")} />
                <FormControlLabel value="Other" control={<Radio />} label={t("ConfSettings.Other")} />
              </Field>
            </Grid>
            {values.videoConferenceType === 'Zoom' && <>
              <Grid item xs={12} md={5}>
                <Grid container item spacing={3}>
                  <Grid item xs={12}>
                    <Field component={TextField} name="userId" type="text" label={t("ConfSettings.UserId")}
                      required variant="outlined" size="small" color="secondary" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} name="apiKey" type="text" label={t("ConfSettings.ApiKey")}
                      required variant="outlined" size="small" color="secondary" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} name="apiSecret" type="text" label={t("ConfSettings.ApiSecret")}
                      required variant="outlined" size="small" color="secondary" fullWidth />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container item spacing={2} className={classes.helpboxBorder}>
                  <Grid item className={classes.headerText}>
                    Steps to get ZOOM API credentials :
                </Grid>
                  <Grid item className={classes.labelText}>
                    <ol className={classes.orderList}>
                      <li>
                        Go to <a href="https://marketplace.zoom.us/" target="_blank" rel="noopener noreferrer">marketplace.zoom.us</a> and sign in with your Zoom account. If you don't have Zoom account, you need to go through the Sign Up process.
                    </li>
                      <li>
                        Click on the "Develop" dropdown from the banner and select "Build App" option.
                    </li>
                      <li>
                        Click on "Create" button in the "JWT" tile from "Choose your app type" section.
                    </li>
                      <li>
                        Provide App Name as "ProAppointments" and click on "Create" button.
                    </li>
                      <li>
                        Fill the mandatory information for basic and developer contact information.
                    </li>
                      <li>
                        Select continue link, copy generated API key and API Secret, and update them in the Video Conferencing Settings page of ProAppointments. You need to use email address you used to Sign In to Zoom as User Id in the settings page.
                    </li>
                    </ol>
                    <Box border={1} bgcolor="#f1f1f1" borderColor="rgba(223, 227, 233, 0.8)" borderRadius="5px"
                      fontStyle="italic" lineHeight="21px" textAlign="center"
                      p={1} marginTop={1}>
                      No action is needed on Feature option. Ensure when you select Activation option,
                      you should see message like "Your app is activated on the account".
                      Remember, don't Deactivate your app. You are done and can Sign Out from your Zoom account.
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>}
            {values.videoConferenceType === 'Other' && <>
              <Grid item xs={12}>
                <Grid container item spacing={2}>
                  <Grid item className={classes.headerText}>
                    Steps to link video call:
                </Grid>
                  <Grid item className={classes.labelText}>
                    <ol className={classes.orderList}>
                      <li>
                        Go to your desired video call app/website and sign in with your account. If you don't have account, you need to go through the Sign Up process.
                      </li>
                      <li>
                        Create New meeting and Get the 2 meeting links to share (organiser link and attendend link).
                      </li>
                      <li>
                        Copy the meeting link and paste it to '{t("AppointmentForm.StartURL")}' and '{t("AppointmentForm.JoinURL")}' fields of the "Video Call" appointment creation fly-out or "Update Links" action on the existing appointment.
                      </li>
                    </ol>
                  </Grid>
                </Grid>
              </Grid>
            </>}
            {values.videoConferenceType === 'GoogleMeet' && <>
              <GoogleLoginAuth email={values.userId} refreshFn={refreshFn} />
            </>
            }
            {
              values.videoConferenceType === 'WhatsApp' && <>
                <Grid item xs={12} md={5}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      <PhoneNumberField name="mobilePhone" type="text" label={t("ConfSettings.MobilePhone")}
                        variant="outlined" size="small" color="secondary" fullWidth required />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  {/* <Grid container item spacing={2} className={classes.helpboxBorder}>
                    <Grid item className={classes.headerText}>
                      WhatsApp Share Guide:
                    </Grid>
                    <Grid item className={classes.labelText}>
                      <ol className={classes.orderList}>
                        Step goes here
                      </ol>
                    </Grid>
                  </Grid> */}
                </Grid>
              </>
            }
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      fontWeight: 600,
      fontSize: '16px'
    },
    labelText: {
      fontSize: '14px',
      color: '#6C6C6C',
      lineHeight: '21px'
    },
    helpboxBorder: {
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        paddingLeft: '0px'
      },
      borderLeft: '1px solid #aaa',
      paddingLeft: theme.spacing(1),
    },
    orderList: {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(0, 1),
      '& li': {
        margin: theme.spacing(0.75, 0),
        '&::marker': {
          fontWeight: 'bold',
        }
      }
    }
  }),
);
