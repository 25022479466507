import { createStyles, Grid, Link, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { cancelSubscribePlan } from '../../../api/subscriptionService';
import { ModalContext } from '../../../container/ModalContainer';
import OrgContext from '../../../container/OrgContext';
import useCustomTranslation from '../../../container/useCustomTranslation';
import { localeFormattedDateOnly, getColorBySmsBalance, getColorByPlanType, getColorByPaymentStats } from '../../../utils/eventUtils';
import { PaymentStatus } from '../../../utils/types';

export default function CurrentSubscription(props: any) {
    const classes = useStyles();
    const [org] = useContext(OrgContext);
    const { SetSubscriptionState, subscriptionState } = props;
    const { t } = useCustomTranslation();
    const alertHelpers = useContext(ModalContext);

    const subDetails = {
        planFreq: org.subscriptionPlan ? org.subscriptionPlan.subscriptionPlanFrequency : 'Trial',
        expiryDate: org.subscriptionPlan ? org.subscriptionPlan.expiryDate : undefined,
        planName: org.subscriptionPlan ? org.subscriptionPlan.subscriptionPlanType : '-',
        autoRenew: org.subscriptionPlan ? org.subscriptionPlan.autoRenewalEnabled ? "Yes" : "No" : undefined,
        startDate: org.subscriptionPlan ? org.subscriptionPlan.startDate : undefined,
        locations: org.subscriptionPlan ? org.subscriptionPlan.locationsLimit : undefined,
        payment: org.subscriptionPlan ? org.subscriptionPlan.paymentStatus : undefined,
        prePlan: org.subscriptionPlan ? org.subscriptionPlan.previousSubscriptionPlanType : '-',
        paymentLink: org.subscriptionPlan ? org.subscriptionPlan.paymentLink : undefined,
        smsPurcheseDate: org.smsPackage ? org.smsPackage.purchaseDate : undefined,
        smsPayment: org.smsPackage ? org.smsPackage.paymentStatus : undefined,
        smsPlan: org.smsPackage ? org.smsPackage.smsPackageType : undefined,
    }

    const topupsmsHandler = (event: React.SyntheticEvent) => {
        event.preventDefault();
        SetSubscriptionState({ ...subscriptionState, topup: true, showCurrentPlan: true });
    };

    const cancelSubscription = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const resp = await cancelSubscribePlan();
            alertHelpers.showInfo("Cancellation request sent");
        } catch (err) {
            if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                alertHelpers.showErr(err.ErrorMessages.join(', '));
            } else {
                alertHelpers.showErr("Error occurred in Cancellation");
            }
        }
        finally {
            setLoading(false);
        }
    };

    const setLoading = (show: boolean) => {
        SetSubscriptionState({ ...subscriptionState, loading: show })
    }

    const makePayment = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (subDetails.paymentLink) {
            window.open(subDetails.paymentLink, '_blank', 'width');
        }
        else {
            alertHelpers.showErr("Subscription not found");
        }
    };

    return (
        <>
            <Grid container>
                <Grid container sm className={classes.outerBox} style={{marginRight:'10px'}}>
                    <Grid sm container className={classes.section}>
                        {subDetails.planName && <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.SubscriptionPlanType")} :</Grid>
                            <Grid className={classes.text} style={{color: getColorByPlanType(subDetails.planName)}}>
                                {`${subDetails.planName.toUpperCase()}`}</Grid>
                        </Grid>}
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.StartDate")} :</Grid>
                            <Grid className={classes.text}>{`${localeFormattedDateOnly(subDetails.startDate)}`}</Grid>
                        </Grid>
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.ExpiryDate")} :</Grid>
                            <Grid className={classes.text}>{`${localeFormattedDateOnly(subDetails.expiryDate)}`}</Grid>
                        </Grid>
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.PaymentStatus")} :</Grid>
                            <Grid className={classes.text} style={{color: getColorByPaymentStats(subDetails.payment)}}>
                                {`${subDetails.payment}`}</Grid>
                        </Grid>
                        {subDetails.payment === PaymentStatus.Pending && <Grid container>
                            <span className={classes.refLink} onClick={makePayment} >
                                Complete Payment
                                </span>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid container sm className={classes.outerBox} style={{marginRight:'10px'}}>
                    <Grid sm container className={classes.section}>
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.SubscriptionPlanFrequency")} :</Grid>
                            <Grid className={classes.text}>{`${subDetails.planFreq}`}</Grid>
                        </Grid>
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.AutoRenewalEnabled")} :</Grid>
                            <Grid className={classes.text}>{`${subDetails.autoRenew}`}</Grid>
                        </Grid>
                        {subDetails.prePlan && <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.PreviousSubscriptionPlanType")} :</Grid>
                            <Grid className={classes.text} style={{color: getColorByPlanType(subDetails.prePlan)}}>
                                {`${subDetails.prePlan.toUpperCase()}`}</Grid>
                        </Grid>}
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SubscriptionPlan.LocationsLimit")} :</Grid>
                            <Grid className={classes.text}>{`${subDetails.locations}`}</Grid>
                        </Grid>
                        {subDetails.planFreq !== "Trial" && (subDetails.payment !== PaymentStatus.Cancelled && subDetails.payment !== PaymentStatus.CancellationPending)
                            && subDetails.autoRenew === 'Yes' && <Grid container className={classes.pad}>
                                <span className={classes.refLink} onClick={cancelSubscription}>
                                    Cancel Subscription
                                </span>
                            </Grid>}
                    </Grid>
                </Grid>
                <Grid container sm className={classes.outerBox}>
                    <Grid sm container style={{ margin: '10px', display: 'block' }}>
                        <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SmsPackage.Balance")} :</Grid>
                            <Grid style={{ color: getColorBySmsBalance(org.smsBalance) }}>{`${org.smsBalance}`} </Grid>
                        </Grid>
                        {subDetails.smsPurcheseDate && <Grid container className={classes.pad}>
                            <Grid className={classes.title}>{t("SmsPackage.PurchaseDate")} :</Grid>
                            <Grid className={classes.text}>{`${localeFormattedDateOnly(subDetails.smsPurcheseDate)}`}</Grid>
                        </Grid>}

                        <Grid>
                            <span className={classes.refLink} onClick={topupsmsHandler}>
                                Top-up Balance
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        refLink: {
            color: theme.palette.secondary.main,
            fontWeight: 400,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
        },
        outerBox: {
            border: '1px solid #DEE2E6',
            borderRadius: '5px',
            backgroundColor: 'rgb(250 250 250 / 47%)',
        },
        section: {
            margin: '10px',
            display: 'block',
            [theme.breakpoints.down('sm')]: {
            },
        },
        title: {
            fontWeight: 400,
            marginRight: '3px'
        },
        text: {
            fontWeight: 600
        },
        pad: {
            padding: '2px 0px 7px'
        },

    }),
);