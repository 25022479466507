import React, { useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ModalContext } from '../../../container/ModalContainer';
import {
    Theme, createStyles, Grid, useMediaQuery, Checkbox,
    Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button
} from '@material-ui/core';
import { getCurrency, getCurrencySymbol } from '../../../utils/eventUtils';
import { PaymentStatus, SubscriptionPlanFrequency, SubscriptionPlanTypes } from '../../../utils/types';
import { subscribePlan } from '../../../api/subscriptionService';
import OrgContext from '../../../container/OrgContext';
import useCustomTranslation from '../../../container/useCustomTranslation';

export default function Payment(props: any) {
    const classes = useStyles();
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const alertHelpers = useContext(ModalContext);
    const { plan, SetContext, context, refreshFn, setButtonState } = props;
    const [org] = useContext(OrgContext);
    const { t } = useCustomTranslation();

    const subscribe = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setButtonState(true);
        setLoading(true);
        try {
            const plan = {
                subscriptionPlanType: context.selectedPlan.plan,
                subscriptionPlanFrequency: context.planType === true ?
                    SubscriptionPlanFrequency.Yearly : SubscriptionPlanFrequency.Monthly,
                autoRenewalEnabled: context.autorenewal
            }
            const resp = await subscribePlan(plan);
            let w = window.open(resp, '_blank', 'width');
            setTimeout(() => {
                refreshFn()
            }, 60000);
        } catch (err) {
            if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                alertHelpers.showErr(err.ErrorMessages.join(', '));
            } else {
                alertHelpers.showErr("Error occurred in subscription");
            }
        } finally {
            setLoading(false);
            SetContext({ ...context, popup: false, selectedPlan: null });
        }
    };

    const setLoading = (show: boolean) => {
        SetContext({ ...context, loading: show })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetContext({ ...context, autorenewal: event.target.checked });
    };

    const handleClose = () => {
        SetContext({ ...context, popup: false, selectedPlan: null });
    };

    return (<>
        <TableContainer component={Paper} className={classes.root}>
            <Table className={classes.mainTable}>
                <TableBody>
                    <TableRow key="subPlan">
                        <TableCell component="th" scope="row" className={classes.heading}>
                            {t("SubscriptionPlan.SubscriptionPlanType")}
                        </TableCell>
                        <TableCell component="th" scope="row" className={classes.planValue}>
                            {`${context.selectedPlan.plan.toUpperCase()}`}
                        </TableCell>
                    </TableRow>
                    <TableRow key="locations">
                        <TableCell component="th" scope="row" className={classes.heading}>
                            {t("SubscriptionPlan.LocationsLimit")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {`${context.selectedPlan.features.locationCalendarsLimit}`}
                        </TableCell>
                    </TableRow>
                    <TableRow key="price">
                        <TableCell component="th" scope="row" className={classes.heading}>
                            Price
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {context.planType ? <>
                            {getCurrencySymbol(context.currency) + context.selectedPlan.cost.yearly[context.currency].price} </>
                            :
                            <> {getCurrencySymbol(context.currency) + context.selectedPlan.cost.monthly[context.currency].price} </>}
                            {org.countryCode !== 'in' ? getCurrency(org.countryCode) : ""}
                        </TableCell>
                    </TableRow>
                    <TableRow key="type">
                        <TableCell component="th" scope="row" className={classes.heading}>
                            {t("SubscriptionPlan.SubscriptionPlanFrequency")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {context.planType ? "Annual" : "Monthly"}
                        </TableCell>
                    </TableRow>
                    {isSmallDevices ? <> <StyledTableRow key="select">
                        <StyledTableCell component="th" scope="row" colSpan={2} align='left'
                        style={{display:"flex",paddingLeft:'0px'}} size='small' >
                            <Checkbox
                                checked={context.autorenewal}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Grid item style={{ marginTop: '10px' }}>Auto-Renew</Grid>
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="select">
                        <StyledTableCell component="th" scope="row" size='small' 
                        align='right' className={classes.mobileButtons} colSpan={2}>
                            <Button color="secondary"
                                variant="contained"
                                name="cancel"
                                onClick={handleClose}
                                className={classes.cancelButton}
                                >
                                Cancel
                            </Button>
                            <Button color="secondary"
                                variant="contained"
                                name="cancel"
                                onClick={e => subscribe(e)}
                                className={classes.continueButton}
                                >
                                Continue
                            </Button>
                        </StyledTableCell>
                    </StyledTableRow> </> : <>
                    <StyledTableRow key="select">
                        <StyledTableCell component="th" scope="row" align='left'
                        style={{display:"flex",paddingLeft:'0px'}} size='small' >
                            <Checkbox
                                checked={context.autorenewal}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Grid item style={{ marginTop: '10px' }}>Auto-Renew</Grid>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" size='small' 
                        align='center' className={classes.mobileButtons}>
                            <div style={{marginTop:'45px'}}></div>
                            <Button color="secondary"
                                variant="contained"
                                name="cancel"
                                onClick={handleClose}
                                className={classes.cancelButton}
                                >
                                Cancel
                            </Button>
                            <Button color="secondary"
                                variant="contained"
                                name="cancel"
                                onClick={e => subscribe(e)}
                                className={classes.continueButton}
                                >
                                Continue
                            </Button>
                        </StyledTableCell>
                    </StyledTableRow>
                    </>}
                </TableBody>
            </Table>
        </TableContainer>
    </>);

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom:'10px',
            border: '1px solid #DFE3E9',
        },
        mainTable:{
            marginBottom:'5px',
        },
        heading:{
            fontWeight: 'bold',
        },
        planValue:{
            color: theme.palette.secondary.main,
        },
        cancelButton:{
            marginRight:'10px',
        },
        continueButton:{
            marginLeft:'10px',
        },
        mobileButtons:{
            [theme.breakpoints.down('sm')]: {
               
            },
        },
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: "none"
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
        },
    }),
)(TableRow);