import { Checkbox, createStyles, DialogTitle, Drawer, Grid, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import useCustomTranslation from '../../container/useCustomTranslation';
import { getJoinAppointment } from '../../api/calendarService';
import Loader from '../../component/Loader';
import { AppointmentStatus, AppointmentType } from '../../utils/types';
import EventButtonsActionBox from './EventButtonsActionBox';
import { ModalContext } from '../../container/ModalContainer';
import OrgContext from '../../container/OrgContext';
import { Cancel } from '@material-ui/icons';
import { Field, Formik, useField } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const initialState: any = {open: false, text: '', type: 'none', label: '', reminderType: '1', context: {}};
const emptyGuid = "00000000-0000-0000-0000-000000000000";
const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

export default function EventButtons(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { event, eventActions, storeData } = props;
    const local = moment.utc(event.appointmentTime).local();
    //const endTime = moment.utc(event.appointmentTime).add(event.appointmentDuration, 'minutes').local();
    //const isInTime = moment().local().isBefore(endTime)
    //const isInProgress = ;
    const [textBox, setTextBox] = useState(initialState);
    const [isDeleteDialog, setIsDeleteDialog] = useState(false);
    const [deleteRecurring, setDeleteRecurring] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const alert = React.useContext(ModalContext);
    const [org] = React.useContext(OrgContext);

    const openMeeting = () => {
        if (event.startUrl) {
            window.open(event.startUrl, '_blank');
            return;
        }

        setLoading(true);
        getJoinAppointment(event.id)
            .then(obj => {
                setLoading(false);
                if (obj.joinUrl) {
                    window.open(obj.joinUrl, '_blank');
                }
                else {
                    alert.showModal({ msg: "Error occurred while getting appointment link", type: 'error' });
                }
            }).catch((err) => {
                setLoading(false);
                if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                    alert.showModal({ msg: err.ErrorMessages.join(', '), type: 'error', duration: 5000 });
                }
                else {
                    alert.showModal({ msg: "Error occurred while getting appointment link", type: 'error' });
                }
            });
    }

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        return (
            <Button id="join-meeting-btn" size="small" variant="contained" color="secondary"
                className={classes.button} disabled={!completed} onClick={() => { openMeeting() }}>
                {t("AppointmentDetails.JoinBtn")} {!completed && hours < 2 ? `in ${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}` : null}
            </Button>)
    };

    const onDeleteClicked = () => { 
        setIsDeleteDialog(true);
        setTextBox({ ...textBox, open: false, type: 'delete', label: 'Delete Appointment', context: {isRecurring: deleteRecurring} }) 
    };

    const onCancelDeleteClick = () => {
        setIsDeleteDialog(false);
    }

    const sendAction = () => {
        eventActions(textBox, event.id);
    }

    const onIsRecurringChanged = () =>{
        setDeleteRecurring(!deleteRecurring);
        setTextBox({ ...textBox, open: false, type: 'delete', label: 'Delete Appointment', context: {isRecurring: !deleteRecurring} }) 
    }

    const showJoinBtn = useMemo(() => {
        return event.type === AppointmentType.VideoCall && !event.isPastDue && event.canStart;
    }, [event.status, event.canStart]);

    const showCancelBtn = useMemo(() => {
        return event.status !== AppointmentStatus.Requested &&
            event.status !== AppointmentStatus.Cancelled &&
            event.canCancel;
    }, [event, event.status]);

    const showUpdateLink = useMemo(() => {
        return !event.isPastDue && (
            event.status === AppointmentStatus.Created ||
            event.status === AppointmentStatus.InProgress ||
            event.status === AppointmentStatus.Scheduled
        ) && event.type === AppointmentType.VideoCall && !org.conferenceSettings;
    }, [event, event.type, event.status]);

    const showNotifyBtn = useMemo(() => {
        return !event.isPastDue && (
            event.status === AppointmentStatus.Created ||
            event.status === AppointmentStatus.InProgress ||
            event.status === AppointmentStatus.Scheduled
        )
    }, [event, event.status]);

    const showRescheduleBtn = useMemo(() => {
        return !event.isPastDue && (
            event.status === AppointmentStatus.Created ||
            event.status === AppointmentStatus.InProgress ||
            event.status === AppointmentStatus.Scheduled ||
            event.status === AppointmentStatus.Requested
        );
    }, [event, event.status]);

    const showAsssignBtn = useMemo(() => {
        return !event.isPastDue && (
            event.status === AppointmentStatus.Created ||
            event.status === AppointmentStatus.InProgress ||
            event.status === AppointmentStatus.Scheduled
        );
    }, [event, event.status]);

    const showCompleteBtn = useMemo(() => {
        return !event.isPastDue && (
            event.status === AppointmentStatus.Created ||
            event.status === AppointmentStatus.InProgress ||
            event.status === AppointmentStatus.Scheduled
        );
    }, [event, event.status]);

    const showRejectBtn = useMemo(() => {
        return event.status === AppointmentStatus.Requested;
    }, [event, event.status]);

    const showAcceptBtn = useMemo(() => {
        return !event.isPastDue && event.status === AppointmentStatus.Requested;
    }, [event, event.status]);

    return (
        <Grid container xs={12}>
            {!textBox.open && <>
                {showUpdateLink &&
                    <Button id="updatelink-btn" size="small" variant="contained" color="secondary" className={classes.button}
                        onClick={() => { setTextBox({ ...textBox, open: true, type: 'updateLink', label: '', reminderType: '' }) }}>
                        {t("AppointmentDetails.UpdateLink")}
                    </Button>}

                {showNotifyBtn && <Button id="notify-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={() => { setTextBox({ ...textBox, open: true, type: 'notify', label: '' }) }}>
                    {t("AppointmentDetails.NotifyBtn")}
                </Button>}

                {showRescheduleBtn && <Button id="reschedule-meeting-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={() => { setTextBox({ ...textBox, date: event.appointmentTime, appointmentDuration: event.appointmentDuration, open: true, type: 'reschedule', label: 'Time' }) }}>
                    {t("AppointmentDetails.RescheduleBtn")}
                </Button>}

                {showAsssignBtn && <Button id="assign-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={() => {
                        setTextBox({
                            ...textBox, open: true, type: 'assign', label: t("AppointmentForm.NoHost"),
                            text: event.host ? event.host.id : undefined
                        })
                    }}>
                    {t("AppointmentDetails.AsssignBtn")}
                </Button>}

                {showAcceptBtn && <Button id="approve-meeting-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={() => {
                        setTextBox({
                            ...textBox, open: true, type: 'approve', label: t("AppointmentForm.NoHost"),
                            text: event.host ? event.host.id : undefined
                        })
                    }}>
                    {t("AppointmentDetails.AcceptBtn")}
                </Button>}

                {showCancelBtn && <Button id="cancel-meeting-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={() => { setTextBox({ ...textBox, open: true, type: 'cancel', label: 'Cancel Reason' }) }}>
                    {t("AppointmentDetails.CancelBtn")}
                </Button>}

                {showCompleteBtn && <Button id="done-meeting-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={() => { setTextBox({ ...textBox, open: true, type: 'complete', label: 'Completion Notes' }) }}>
                    {t("AppointmentDetails.CompleteBtn")}
                </Button>}

                {showJoinBtn && <Countdown date={local.toDate()} renderer={renderer} />}

                {showRejectBtn && <Button id="reject-meeting-btn" size="small" variant="contained" color="secondary"
                    onClick={() => { setTextBox({ ...textBox, open: true, type: 'cancel', label: 'Cancel Reason' }) }}
                    className={classes.button}>
                    {t("AppointmentDetails.RejectBtn")}
                </Button>}

                <Button id="delete-meeting-btn" size="small" variant="contained" color="secondary" className={classes.button}
                    onClick={onDeleteClicked}>
                    Delete
                </Button>
            </>}

            { textBox.open &&
                <EventButtonsActionBox textBox={textBox} storeData={storeData} event={event}
                    onTextChange={(e: any) => setTextBox({ ...textBox, text: e.target.value })}
                    onRadioButtonClick={(e: any) => { setTextBox({ ...textBox, reminderType: e.target.value }) }}
                    onCloseButton={() => { setTextBox(initialState) }}
                    onSendButton={() => { sendAction(); setTextBox(initialState) }}
                    onTextBoxChange={setTextBox}
                />
            }
            <Drawer
            open={isDeleteDialog}
            anchor='right'
            onClose={onCancelDeleteClick}
            PaperProps={{ variant: 'outlined', style: { minWidth: '40vw', maxWidth: '500px', width: '100%' } }}
            >
                <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        Delete Appointment
                        <Cancel id="cloz-aptms" onClick={onCancelDeleteClick} />
                    </div>
                </DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                    Are you sure you want to delete this appointment?
                </div>
                {event.recurringId !== emptyGuid && 
                    <Grid item>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={deleteRecurring}
                            onChange={onIsRecurringChanged}
                        />Delete recurring appointments
                    </Grid>
                }
                
                <Grid container xs={12} sm={4}>
                    <Button id="delete-ok-btn" size="small" variant="contained" color="secondary" className={classes.button}
                        onClick={() => { sendAction(); setTextBox(initialState) }}>
                        Yes
                    </Button>
                    <Button id="delete-cancel-btn" size="small" variant="contained" color="secondary" className={classes.button}
                        onClick={onCancelDeleteClick}>
                        Cancel
                    </Button>
                </Grid>
            </Drawer>
            { isLoading && <Loader useBackdrop open={isLoading} />}
        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(0.5),
            borderRadius: '5px',
            height: '30px',
            fontSize: '14px',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
            },
            '&:focus': {
                boxShadow: 'none',
            },
        },
        checkboxes: {
            padding: '4px 8px',
        },
    }),
);
