import { Container, createStyles, Grid, IconButton, makeStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import SyncIcon from '@material-ui/icons/Sync';
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { createAdminAppointment, createBulkAppointment } from "../../api/calendarService";
import { ModalContext } from "../../container/ModalContainer";
import OrgContext from "../../container/OrgContext";
import useCustomTranslation from "../../container/useCustomTranslation";
import { getMinMaxHoliday, mapAppointment, getUTCTimeFormatForTimeSlot } from "../../utils/eventUtils";
import BreadcrumbNav from "../BreadcrumbNav";
import Loader from "../Loader";
import BookingDialog from "./BookingDialog";
import CalendarUI from "./CalendarUI";
import EventListDialog from "./EventListDialog";
import EventIcon from '@material-ui/icons/Event';
import DatePicker from 'react-datepicker';
import MenuButton from './MenuButton';
import 'react-datepicker/dist/react-datepicker.css';
import { uploadAllFiles } from './FormHelper';

const bookingInit = { open: false, bulk: false };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0, 1),
            padding: '4px',
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: 'rgb(83, 17, 118)',
                borderColor: 'none',
                boxShadow: 'none',
            },
        },
        main: {
            overflow: 'hidden',
            height: 'calc(100vh - 60px)',
            display: 'flex',
            flexDirection: 'column'
        },
        miniHeader: {
            zIndex: 11,
            boxShadow: "0px 1px 3px 1px #b3b3b3",
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignContent: 'center'
        },
        baseContainer: {
            // overflowY: 'auto',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '8px',
                paddingRight: '8px'
            }
        },
        datepickerLabel: {
            marginRight: '8px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: 'rgb(83, 17, 118)',
                borderColor: 'none',
                boxShadow: 'none',
            },
        }
    }),
);
const CustomDateInput = ({ value, onClick }: any) => (
    <IconButton color="primary" size="small" onClick={onClick}>
        <EventIcon />
    </IconButton>
);
export default function Calendar(props: any) {
    const classes = useStyles();
    const { storeId, storeData, evt } = props;
    const [events, setEvents] = useState<any>();
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState<any>();
    const [bookingOpen, setBookingOpen] = useState(bookingInit);
    const [calEvents, setCalEvents] = useState<any>([]);
    // const [datepassed,setDate]= useState(new Date());
    const [calVal, setCalVal] = useState({ date: new Date(), view: 'day' });
    // const[date,setCalDate]=useState(new Date())
    const [org,] = useContext(OrgContext);
    const { t } = useCustomTranslation();
    const alert = React.useContext(ModalContext);

    const dstate: any = {
        view: 'day'
    };
    const [state, setState] = useState(dstate);

    const calSettings = useMemo(() => getMinMaxHoliday(org, storeData, state.view, calVal.date), [calVal, state]);

    useEffect(() => {
        setCalEvents(mapAppointment(events, calSettings.slot));
    }, [events]);

    useEffect(() => {
        setEvents(evt);
    }, [evt]);

    const handleDeatilClose = (value: any) => {
        setDetailsOpen(false);
    };
    const handleEventOpen = (value: any) => {
        setSelectedValue(value);
        setDetailsOpen(true);
    };

    const handleBookingOpen = (isBulk: any) => {
        setSelectedValue(null);
        setBookingOpen({ bulk: isBulk, open: true });
    };

    const handleBookingClose = (value: any) => {
        //causing ssue in slotValues showing on calendar
        // setCalVal({ ...calVal, date: new Date() });
        setBookingOpen(bookingInit);
        if (value === true) {
            refreshEvt();
        }
    };

    const handleCreate = (data: any) => {
        setLoading(true);
        const createAppointment = bookingOpen.bulk ? createBulkAppointment : createAdminAppointment;

        createAppointment(data).then(async (response: any) => {
            setLoading(false);
            const data = await response.json();
            if (response.ok) {
                return data;
            } else {
                return Promise.reject(data);
            }
        }).then(result =>{
            uploadAllFiles(result, data.formFields);
            return result;
        })
        .then(result => {
            handleBookingClose(false);
            alert.showDone(t('Alerts.BookingDone'));
            if (result) {
                setEvents(events.concat(result));
            }
        }).catch(err => {
            if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                alert.showModal({ msg: err.ErrorMessages.join(', '), type: 'error' });
            }
            else {
                alert.showModal({ msg: "Error occurred while creating appointment", type: 'error' });
            }
        });
    }

    const handleSlotSelect = ({ start, end, action }: any) => {
        if (action !== 'click') {
            const mStart = moment.utc(start);
            const mEnd = moment.utc(end);
            const minutes = mEnd.diff(mStart, 'minutes')
            setSelectedValue({ start, end, minutes });
            setCalVal({ ...calVal, date: start })
            setBookingOpen({ ...bookingOpen, open: true });
        }
    }
    const refreshEvt = () => {
        props.fetchEv({ isForcedRefresh: true });
    }


    const handleRangeChange = (range: any, view: any) => {
        let startDate = moment(range[0]).toISOString();
        let endDate = moment(range[0]).toISOString();

        if (range.start) {
            startDate = moment(range.start).toISOString();
            endDate = moment(range.end).toISOString();
        }
        else if (range.length > 1) {
            endDate = moment(range[range.length - 1]).toISOString()
        } else if (range.length === 1) {
            endDate = moment(range[0]).add(1, 'day').toISOString()
        }
        setCalVal({ date: new Date(startDate), view: view })

        props.fetchEv({ startDate, endDate });
    }

    const onDateChange = (date: Date) => {
        //setCalVal({ date, view: 'fday' });
        const range = { start: date, end: date };
        handleRangeChange(range, 'fday');
    }

    const HeaderComp = () => {
        return (<Grid container justify="space-between" alignItems="center">
            <Grid item xs style={{ display: 'flex' }}>
                <BreadcrumbNav />
            </Grid>

            <Grid item style={{ display: 'flex', marginRight: '8px' }}>
                <IconButton id="refresh-aptmts" color="primary" size="medium" className={classes.margin}
                    onClick={refreshEvt}>
                    <SyncIcon />
                </IconButton>
                <label className={classes.datepickerLabel}>
                    <DatePicker
                        popperPlacement="left-start"
                        popperClassName="my-datepicker-opt"
                        selected={calVal.date}
                        onChange={(date: any) => onDateChange(date)}
                        customInput={<CustomDateInput />}
                    />
                </label>
                <MenuButton buttonLabel={t('Calendar.CreateAppointment')} handleMenuClick={handleBookingOpen} />
            </Grid>
        </Grid>)
    }

    return (
        <main className={classes.main}>
            <Grid container alignItems="center" className={classes.miniHeader}>
                <HeaderComp />
            </Grid>
            <Container maxWidth={false} className={classes.baseContainer}>
                <CalendarUI
                    handleEventOpen={handleEventOpen}
                    handleSlotSelect={handleSlotSelect}
                    handleRangeChange={handleRangeChange}
                    events={calEvents}
                    timings={calSettings}
                    storeId={storeId}
                    calVal={calVal}
                    state={state} setState={setState} />
                {detailsOpen && <EventListDialog events={selectedValue.events}
                    handleClose={handleDeatilClose} refreshEvents={refreshEvt} open={detailsOpen} storeData={storeData} />}
                {bookingOpen.open && <BookingDialog
                    selectedSlot={selectedValue && getUTCTimeFormatForTimeSlot(moment(selectedValue.start).format('HH:mm'))}
                    duration={selectedValue && selectedValue.minutes}
                    open={bookingOpen.open}
                    bulk={bookingOpen.bulk}
                    datepassed={new Date() > calVal.date ? new Date() : calVal.date} storeId={props.storeId} storeData={storeData}
                    handleClose={handleBookingClose} onCreate={handleCreate} />}
                {isLoading && <Loader useBackdrop open={isLoading} />}
            </Container >
        </main>
    );
}

