import { createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import SyncIcon from '@material-ui/icons/Sync';
import moment from 'moment';
import React, { useMemo } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useCustomTranslation from "../../container/useCustomTranslation";
import BreadcrumbNav from '../BreadcrumbNav';
import MenuButton from "../Calendar/MenuButton";
import AddButton from "../Settings/AddBtn";

interface StoreCalHeaderProps {
    refresh: any;
    onAddClick: any;
    handleRangeChange: any;
    datepassed: any;
}
const CustomDateInput = ({ value, onClick }: any) => (
    <IconButton color="primary" size="small" onClick={onClick}>
        <EventIcon />
    </IconButton>
);


function StoreCalHeader(props: StoreCalHeaderProps) {
    const { refresh, onAddClick, handleRangeChange, datepassed } = props;
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const date = useMemo(() => {
        return datepassed
    }, [datepassed])

    const onDateChange = (date: Date) => {
        const mDate = moment(date);
        const range = {
            start: mDate.startOf('day').toDate(),
            end: mDate.endOf('day').toDate()
        };
        handleRangeChange(range, 'fday');
    }

    return (<Grid container justify="space-between" alignItems="center">
        <Grid item xs style={{ display: 'flex' }}>
            <BreadcrumbNav />
        </Grid>
        <Grid item style={{ display: 'flex', marginRight: '8px' }}>
            <IconButton id="refresh-aptmts" color="primary" size="medium" className={classes.margin}
                onClick={refresh}>
                <SyncIcon />
            </IconButton>
            <label className={classes.datepickerLabel}>
                <DatePicker
                    popperPlacement="left-start"
                    popperClassName="my-datepicker-opt"
                    selected={date}
                    onChange={(date: any) => onDateChange(date)}
                    customInput={<CustomDateInput />}
                />
            </label>
            {onAddClick && <MenuButton buttonLabel={t('Calendar.CreateAppointment')} handleMenuClick={onAddClick} />}
        </Grid>
    </Grid>)
}

export default React.memo(StoreCalHeader);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0, 1),
            padding: '4px',
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: 'rgb(83, 17, 118)',
                borderColor: 'none',
                boxShadow: 'none',
            },
        },
        datepickerLabel: {
            marginRight: '8px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: 'rgb(83, 17, 118)',
                borderColor: 'none',
                boxShadow: 'none',
            },
        }
    }),
);
