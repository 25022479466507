import { ButtonBase, Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { Forward } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { diffInDays } from '../../../utils/eventUtils';

function ExpiryAlert(props: any) {
    const classes = useStyles();
    const { currentPlan } = props;
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    if (currentPlan && currentPlan.expiryDate) {
        const diff = diffInDays(currentPlan.expiryDate);
        if (diff.isBelowWeek) {
            const msg = diff.days <= 0 ? 'Your subscription is expired!' : `Your subscription expires in ${diff.days} days!`
            return (
                <ButtonBase id='subscribe' disableRipple style={{ width: '100%' }}
                    component={Link} to={{ pathname: '/settings', state: { tabIndex: 10 } }}>
                    <Grid container alignItems="center" justify="center" className={classes.banner}>
                        <Grid item className={classes.notice}>
                            {msg}
                        </Grid>
                        <Grid item className={classes.button}>
                            {isSmallDevices ? <Forward /> : 'Subscribe'}
                        </Grid>
                    </Grid>
                </ButtonBase>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default React.memo(ExpiryAlert);

const useStyles = makeStyles((theme: Theme) => ({
    notice: {
        fontSize: '20px',
        color: '#FEF634',
        marginRight: '8px',
        marginBottom: '4px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            color: '#1E2226',
            marginBottom: '0px',
        }
    },
    button: {
        backgroundColor: '#FEF634',
        borderRadius: '3px',
        color: '#1E2226',
        height: '30px',
        padding: '4px 12px',
        '&:hover': {
            backgroundColor: '#F0EC82',
        },
        [theme.breakpoints.down('sm')]: {
            height: '24px',
            padding: 0,
        }
    },
    banner: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#FEF634',
            height: '24px',
        }
    }
}));