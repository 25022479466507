import {
    createStyles,
    Dialog, DialogContent,
    IconButton, Theme, Typography
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import AddOn from "./AddOn";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: '16px 16px 16px 24px',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function TopUp(props: any) {
    const { plan, SetContext, context, refreshFn } = props;

    const handleClose = () => {
        SetContext({ ...context, topup: false });
    };

    return (
        <>
            <Dialog open={context.topup} onClose={handleClose}>
                <DialogTitle onClose={handleClose} >SMS Packages</DialogTitle>
                <DialogContent style={{ padding: '0px 24px 16px' }}>
                    <AddOn plan={plan} context={context} SetContext={SetContext} refreshFn={refreshFn} />
                </DialogContent>
            </Dialog>
        </>
    );
}