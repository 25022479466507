import { MenuItem, TextField } from "@material-ui/core";
import { useField } from 'formik';
import React, { useLayoutEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import client from '../../api/api-client';
import useCustomTranslation from '../../container/useCustomTranslation';

const STORE_ENDPOINT = "locations";


const fetchEmployees = async ({ storeId }: any) => {
    if (storeId) {
        return client(`${STORE_ENDPOINT}/${storeId}/employees`)
    } else {
        return client('employees');
    }
};

export default function SelectHost(props: any) {
    const { storeId, storeData } = props;
    const { t } = useCustomTranslation();
    const [employees, setEmployees] = useState<any>();

    const [, hostIdMeta, hostIdHelpers] = useField('hostId')
    const [, , hostNameHelpers] = useField('hostName')

    useLayoutEffect(() => {
        if (storeData && storeData.employees) {
            setEmployees(storeData.employees.models)
        } else {
            fetchEmployees(storeId)
                .then(r => { setEmployees(r.models) });
        }
    }, []);

    const onSlotSelected = (slotIndex: any) => {
        if (slotIndex !== '-1') {
            const matechedData = employees.find((e: any) => e.id === slotIndex);
            hostIdHelpers.setValue(slotIndex);
            if (matechedData) {
                hostNameHelpers.setValue(matechedData.name);
            }
        } else {
            hostIdHelpers.setValue(slotIndex);
            hostNameHelpers.setValue("");
        }
    }

    return (
        <TextField select id="cust-host" label={t('AppointmentForm.Host')} size="small" color="secondary" fullWidth
            value={hostIdMeta.value}
            variant="outlined" onChange={(e) => onSlotSelected(e.target.value)}>
            <MenuItem id={'0'} key={0} value={'-1'}>{t('AppointmentForm.NoHost')}</MenuItem>
            {employees && employees.map((d: any, index: number) => {
                return <MenuItem id={index + ''} key={index} value={d.id}>{d.name}</MenuItem>;
            })}
        </TextField>
    );

};