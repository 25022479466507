import { Grid, Paper, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { CheckBox, Clear, Delete, Edit } from "@material-ui/icons";
import MaterialTable from 'material-table';
import React, { useRef, useState } from "react";
import { getStores } from '../../api/calendarService';
import useCustomTranslation from '../../container/useCustomTranslation';
import Loader from "../Loader";
import AddButton from "./AddBtn";
import StoreForm from "./StoreForm";
import CustomFormFieldsTable from './CustomForm/CustomFormGenerator'
import editForm from '../../assets/editForm.png'

const initialState: any = { open: false, title: '', type: undefined, selected: {} };

export default function Stores(props: any) {
  const { t } = useCustomTranslation();
  const [flyout, setFlyout] = useState(initialState);
  const [formFlyout, setFormFlyout] = useState(initialState);
  const tableRef = useRef(null);
  const { org } = props;
  const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const handleClose = (e: any, doRefresh = false) => {
    if (doRefresh === true) {
      //@ts-ignore
      tableRef && tableRef.current && tableRef.current.onQueryChange()
    }
    setFlyout(initialState);
    setFormFlyout(initialState);
  };

  return (
    <Grid>
      <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0' }}>
        <Grid item>
          <Typography variant="h6">
            {t('Settings.Stores')} </Typography>
        </Grid>
        <Grid item>
          <AddButton fieldId="add-store-btn" btnLabel={t("StoreSettings.AddStore")}
            onClick={(event: any) => setFlyout({ open: true, title: t("StoreSettings.CreateLabel"), type: 'add', selected: {} })}
          />
        </Grid>
      </Grid>
      <MaterialTable
        title={t('Settings.Stores')}
        tableRef={tableRef}
        options={{
          search: false,
          actionsColumnIndex: -1,
          paging: false,
          sorting: false,
          draggable: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: '#f1f1f1',
            fontWeight: 'bold',
            fontSize: '14px'
          }
        }}
        components={{
          Container: props => <Paper {...props} elevation={0} />,
          OverlayLoading: props => <Loader useBackdrop open={true} />
        }}
        columns={[
          {
            title: t("StoreSettings.Name"), field: 'name',
            render: rowData => <NameLink rowData={rowData} setFlyout={setFlyout} />
          },
          {
            title: t("StoreSettings.TableHeaders.UseOrgContact"), field: 'useOrganizationContactDetails',
            align: "center", hidden: isSmallDevices,
            render: rowData => <RenderBox isEnabled={rowData.useOrganizationContactDetails} />
          },
          {
            title: t("StoreSettings.TableHeaders.UseOrgBusinessHrs"), field: 'useOrganizationWeeklyPlan',
            align: "center", hidden: isSmallDevices,
            render: rowData => <RenderBox isEnabled={rowData.useOrganizationWeeklyPlan} />
          },
          {
            title: t("StoreSettings.TableHeaders.UseOrgHolidays"), field: 'useOrganizationHolidays',
            align: "center", hidden: isSmallDevices,
            render: rowData => <RenderBox isEnabled={rowData.useOrganizationHolidays} />
          },
          {
            title: t("StoreSettings.TableHeaders.Store"), field: 'isLocationVisitEnabled',
            align: "center",
            render: rowData => <RenderBox isEnabled={rowData.isLocationVisitEnabled} />,
            hidden: !org.isLocationVisitEnabled
          },
          {
            title: t("StoreSettings.TableHeaders.Video"), field: 'isVideoCallEnabled',
            align: "center",
            render: rowData => <RenderBox isEnabled={rowData.isVideoCallEnabled} />,
            hidden: !org.isVideoCallEnabled
          },
          {
            title: t("StoreSettings.TableHeaders.Home"), field: 'isHomeVisitEnabled',
            align: "center",
            render: rowData => <RenderBox isEnabled={rowData.isHomeVisitEnabled} />,
            hidden: !org.isHomeVisitEnabled
          }
        ]}
        data={(query: any) =>
          new Promise((resolve, reject) => {
            getStores().then(response => response.json()).then((result) => {
              resolve({
                data: result.models,
                page: 0,
                totalCount: result.totalCount
              })
            }).catch(err => {
              resolve({
                data: [],
                page: 0,
                totalCount: 0
              })
            })
          })
        }
        actions={[
          {
            icon: () => <AddButton fieldId="add-location-btn" btnLabel={t("StoreSettings.AddStore")} />,
            isFreeAction: true,
            onClick: (event) => setFlyout({ open: true, title: t("StoreSettings.CreateLabel"), type: 'add', selected: {} })
          },
          {
            icon: () => <Edit style={{ color: '#6c6c6c' }} fontSize="small" />,
            onClick: (event, rowData: any) => setFormFlyout({ open: true, title: t("StoreSettings.CustomForm"), selected: rowData })
          },
          {
            icon: () => <Delete style={{ color: '#6c6c6c' }} fontSize="small" />,
            onClick: (event, rowData: any) => setFlyout({ open: true, title: t("StoreSettings.DeleteLabel"), type: 'delete', selected: rowData }),
          }
        ]}
      />
      {flyout.open && <StoreForm open={flyout.open} org={org} handleClose={handleClose} type={flyout.type} title={flyout.title}
        selected={flyout.type === 'add' ? undefined : flyout.selected} />}
      {formFlyout.open && <CustomFormFieldsTable open={formFlyout.open} org={org} handleClose={handleClose}  title={formFlyout.title}
        selected={formFlyout.selected} />}
    </Grid>
  )
}

export function RenderBox(props: any) {
  const { isEnabled } = props;
  return (
    isEnabled ? <CheckBox color="secondary" /> : <Clear color="disabled" />
  );
}

function NameLink(props: any) {
  const { t } = useCustomTranslation();
  const { rowData, setFlyout } = props;
  return (
    <div id={`view-${rowData.name}`} color="inherit" className="hovar-bold"
      style={{ color: "#069", textTransform: 'capitalize', cursor: "pointer" }}
      onClick={() => setFlyout({ open: true, title: t("StoreSettings.ViewLabel"), type: 'edit', selected: rowData })}>
      {rowData.name}
    </div>
  );
}