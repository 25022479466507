import React, { useContext } from 'react';
import { useField } from 'formik';

import MaterialUiPhoneNumber from 'material-ui-phone-number';
import { Typography } from '@material-ui/core';
import OrgContext from '../../container/OrgContext';

function PhoneNumberField(props: any) {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;
  const [org] = useContext(OrgContext);
  const defaultCode = props.countryCode ? props.countryCode :
    org && org.countryCode ? org.countryCode : "in"
  return (
    <>
      <MaterialUiPhoneNumber
        {...field}
        {...props}
        defaultCountry={!props.changecode && defaultCode}
        onChange={setValue}
        autoFormat={false}
        countryCodeEditable={false}
        disableAreaCodes
        disableDropdown={props.disabled}
      />
      {meta.error && meta.touched && (
        <Typography
          align={'left'}
          variant={'caption'}
          color={'error'}
        >
          {meta.error}
        </Typography>
      )}
    </>
  );
}
export { PhoneNumberField };
