import { Grid, InputAdornment } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import { ReactComponent as Drag } from '../../assets/dragable.svg';
import { Edit, Done } from '@material-ui/icons';

export interface CardProps {
    id: string
    text: string
    moveCard: (id: any, to: number) => void
    findCard?: any;
    updateOrder?: any;
}

interface Item {
    type: string
    id: string
    originalIndex: string
}

export function Card({ id, text, moveCard, findCard, index, fieldArrayHelpers, updateOrder, updateCards }: any) {
    const [editing, setEditing] = useState(false);
    const originalIndex = findCard(id).index
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: 'card', id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => {
            updateCards();
        },
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem()
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                moveCard(droppedId, originalIndex)
            }
            if (didDrop) {

                updateOrder();
            }
        },
        canDrag: !editing
    })

    const [, drop] = useDrop({
        accept: 'card',
        canDrop: () => false,
        hover({ id: draggedId }: Item) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id)
                moveCard(draggedId, overIndex)
                //  updateOrder();
            }
        },
    })

    const deleteCard = (index: any) => {
        if (editing) {
            setEditing(false);
        }
        fieldArrayHelpers.remove(index)
    }

    const opacity = isDragging ? 0.8 : 1
    return (
        <Grid item xs={12} ref={(node) => drag(drop(node))} style={{ backgroundColor: 'white', opacity, margin: '8px 0' }}>
            <Field component={TextField} name={`appointmentReasons.${index}`} fullWidth disabled={!editing}
                required variant="outlined" size="small" color="secondary" placeholder="Enter new Reason"
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={() => setEditing(!editing)}>
                                {!editing ? <Edit fontSize="small" /> : <Done fontSize="small" />}
                            </InputAdornment>
                            <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                <Delete fontSize="small" onClick={() => deleteCard(index)} />
                            </InputAdornment>
                        </>
                    ),
                    startAdornment: (
                        <InputAdornment position="start" style={{ cursor: 'all-scroll' }}>
                            <Drag fontSize="small" />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    )
}
