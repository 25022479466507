import { Box, createStyles, IconButton, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { Clear, Delete, Done } from '@material-ui/icons';
import React, { useState } from 'react';
import { deleteImage, getImageUploadURL } from '../../api/settingService';
import { ReactComponent as Upload } from '../../assets/Upload.svg';
import useCustomTranslation from '../../container/useCustomTranslation';
import Loader from '../Loader';

const initState = { file: undefined, preview: undefined };

export default function ImageUploader(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { org, refreshFn } = props;
    const [img, setImg] = useState<any>(initState);
    const [loading, setLoading] = useState(false);

    const previewImage = (e: any) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setImg({ file: file, preview: reader.result });
        }
        reader.readAsDataURL(file)
    }

    const onCancel = () => {
        setImg(initState);
    }

    const onDelete = () => {
        setLoading(true);
        deleteImage().then(res => {
            setLoading(false)
            if (res) {
                refreshFn();
            }
        }).catch(err => {
            setLoading(false)
        });
    }

    const onUpload = async () => {
        try {
            setLoading(true)
            const uploadURL = await getImageUploadURL();

            var myHeaders = new Headers();
            myHeaders.append("x-ms-blob-type", "BlockBlob");
            myHeaders.append("Content-Type", img.file.type);
            myHeaders.append("Access-Control-Allow-Origin", "*");

            var file = img.file;

            let requestOptions: any = {
                method: 'PUT',
                headers: myHeaders,
                body: file,
                redirect: 'follow',
            };

            fetch(uploadURL, requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false);
                    refreshFn();
                    setImg(initState);
                })
                .catch(error => {
                    setLoading(false);
                    setImg(initState);
                });
        } catch (e) {
            setImg(initState);
        }
    }

    return (
        < >
            <fieldset style={{ borderRadius: '5px', margin: 0, border: '1px solid #c4c4c4' }}>
                <Box bgcolor="#d1d1d1" p={'8px 0'} display="flex" justifyContent="center"
                    alignItems="flex-end" flexDirection="column">
                    <Loader useBackdrop open={loading} />
                    <img src={img.preview ? img.preview : org.logoUrl} height="100" alt="Logo" style={{ maxWidth: '100%', alignSelf: 'center' }} />
                    <Box display="flex" m="0 20px" alignItems="flex-end" justifyContent="space-between" width="60px">
                        {img.preview ?
                            <>
                                <IconButton id="cancel-upload" onClick={onCancel} className={classes.simpleBtn}>
                                    <Clear color="primary" className={classes.button} />
                                </IconButton>
                                <IconButton id="confirm-upload" onClick={onUpload} className={classes.simpleBtn}>
                                    <Done color="primary" className={classes.button} />
                                </IconButton>
                            </> :
                            <>
                                <input accept="image/*" style={{ display: 'none' }} id="icon-button-photo" type="file"
                                    onChange={(e) => { previewImage(e) }} />
                                <InputLabel htmlFor="icon-button-photo" className={classes.uploadBtn}>
                                    <Upload fill="currentColor" className={`${classes.button}`} />
                                </InputLabel>
                                <IconButton id="cancel-upload" onClick={onDelete} className={classes.simpleBtn}>
                                    <Delete color="primary" className={classes.button} />
                                </IconButton>
                            </>
                        }
                    </Box>
                </Box>
                <legend className={classes.ledgendText} >
                    {t("OrgSettings.CustomDomainLogo")}
                </legend>
            </fieldset>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: 'rgb(83, 17, 118)',
            },
        },
        uploadBtn: {
            cursor: 'pointer'
        },
        simpleBtn: {
            padding: 0
        },
        logoLabel: {
            alignSelf: 'flex-start',
            paddingLeft: theme.spacing(2)
        },
        ledgendText: {
            color: theme.palette.text.secondary,
            fontSize: '0.9rem'
        }
    }),

);
