import { Grid, MenuItem, TextField } from "@material-ui/core";
import { useField } from "formik";
import React, { useContext, useLayoutEffect, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFetchAvailableSlots } from "../../api/useFetchAvailableSlots";
import OrgContext from "../../container/OrgContext";
import useCustomTranslation from '../../container/useCustomTranslation';
import { slotModel } from "../../utils/slotModel";
import { workdayModel } from "../../utils/workdayModel";
import SelectDuration from './SelectDuration';


const CustomDateInput = ({ value, onClick }: any) => (
    <TextField type="button" id="aptmt-date" value={value} variant="outlined"
        size="small" color="secondary" fullWidth inputProps={{ style: { textAlign: 'left' } }}
        label={"Appointment Date"} onClick={onClick} />
);


export default function SelectTime(props: any) {
    const { storeId, storeData } = props;
    const { t } = useCustomTranslation();
    const [org] = useContext(OrgContext);
    const [, dateMeta, dateHelpers] = useField('appointmentTime');
    const [, slotMeta, slotHelpers] = useField('slotSelected');
    const [, typeMeta, typeHelpers] = useField('type');
    const [, meta, helpers] = useField('duration');

    const { value: dateValue } = dateMeta;
    const { setValue: setDateValue } = dateHelpers;

    const { value: slotValue } = slotMeta;
    const { setValue: setSlotValue } = slotHelpers;

    const { value: typeValue } = typeMeta;
    const { setValue: setTypeValue } = typeHelpers;

    const { value: durationValue } = meta;
    const { setValue: setDurationValue } = helpers;


    useEffect(() => {
        if (dateValue && storeId && typeValue) {
            fetchAvailableSlots({
                selectedDate: dateValue,
                selectedStore: storeId,
                appointmentType: typeValue,
                appointmentDuration: durationValue
            });
        }
    }, [dateValue, storeId, typeValue, durationValue]);

    const onDateChange = (date: Date) => {
        setDateValue(date);
        setSlotValue(undefined);
    };

    const onAppointmentTypeChange = (type: any) => {
        setTypeValue(type);
        setDurationValue(appointmentSlotDuration(type));
        setSlotValue(undefined);
    };

    const onAppointmentDurationChange = (duration: any) => {
        setDurationValue(duration);
        setSlotValue(undefined);
    };

    const onSlotSelected = (slotIndex: any) => {
        setSlotValue(slotIndex);
    }

    const {
        holidays: orgHolidays,
        weeklyPlan: orgWeeklyPlan
    } = org;
    const {
        useOrganizationHolidays,
        useOrganizationWeeklyPlan,
        holidays,
        weeklyPlan,
        isHomeVisitEnabled,
        isLocationVisitEnabled,
        isVideoCallEnabled,
        locationAppointmentDuration,
        homeAppointmentDuration,
        videoAppointmentDuration
    } = storeData.store;

    const storeWeeklyPlan = useOrganizationWeeklyPlan
        ? orgWeeklyPlan
        : weeklyPlan;
    const storeHolidays = useOrganizationHolidays ? orgHolidays : holidays;
    const storeWorkdayModel = workdayModel(storeWeeklyPlan, storeHolidays);

    const getDefaultValue = () => {
        const now = props.date || new Date();
        if (dateValue) return dateValue;
        if (!storeWorkdayModel.isWorkingDay(now)) {
            return storeWorkdayModel.nextWorkingDay(now);
        }
        return now;
    };

    const startDate = getDefaultValue();

    const dayWorkingPlan = storeWorkdayModel.getDayWorkingPlan(startDate);
    const { isFullHoliday } = dayWorkingPlan;

    const appointmentSlotDuration = (appointmentType: number) => {
        switch (appointmentType) {
            case 1:
                return locationAppointmentDuration
            case 2:
                return homeAppointmentDuration
            case 3:
                return videoAppointmentDuration
            default:
                return 30
        }
    }

    useLayoutEffect(() => {
        if (isLocationVisitEnabled) {
            setTypeValue(1);
            if (!durationValue) {
                setDurationValue(appointmentSlotDuration(1))
            }
        } else if (isVideoCallEnabled) {
            setTypeValue(3);
            if (!durationValue) {
                setDurationValue(appointmentSlotDuration(3))
            }
        } else if (isHomeVisitEnabled) {
            setTypeValue(2);
            if (!durationValue) {
                setDurationValue(appointmentSlotDuration(2))
            }
        }

    }, []);

    const { data, fetchAvailableSlots } = useFetchAvailableSlots(
        startDate,
        isFullHoliday,
        storeId,
        typeValue,
        durationValue
    );

    return (
        <>
            <Grid container item xs={12} spacing={1} style={{ margin: '8px 4px', padding: 0 }}>
                <Grid item xs={12} sm={12}>
                    <TextField id="aptmt-type" select label="Type" size="small" color="secondary"
                        fullWidth variant="outlined" value={typeValue}
                        onChange={(e) => onAppointmentTypeChange(e.target.value)}>
                        {isLocationVisitEnabled && org.isLocationVisitEnabled && <MenuItem className="m-store" value={1}> {t('AppointmentForm.Type.Store')}</MenuItem>}
                        {isVideoCallEnabled && org.isVideoCallEnabled && <MenuItem className="m-video" value={3}>{t('AppointmentForm.Type.Video')}</MenuItem>}
                        {isHomeVisitEnabled && org.isHomeVisitEnabled && <MenuItem className="m-home" value={2}>{t('AppointmentForm.Type.Home')}</MenuItem>}
                    </TextField>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1} style={{ margin: '8px 4px', padding: 0 }}>
                <Grid item xs={12} sm={4}>
                    <DatePicker
                        popperPlacement="top-start"
                        popperClassName="my-datepicker-opt"
                        selected={dateValue}
                        onChange={(date: any) => onDateChange(date)}
                        minDate={new Date()}
                        filterDate={storeWorkdayModel.isWorkingDay}
                        customInput={<CustomDateInput />}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectDuration
                        dayWorkingPlan={dayWorkingPlan}
                        value={durationValue}
                        durationBasedOnType={appointmentSlotDuration(typeValue)}
                        onChange={onAppointmentDurationChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField select id="aptmt-slot" label={data && data.length > 0 ? "Time Slot" : "No Time Slot"} size="small" color="secondary"
                        fullWidth variant="outlined" onChange={(e) => onSlotSelected(e.target.value)} disabled={!data || data.length === 0}
                        value={!data || data.length === 0 ? '-1' : slotValue}
                        error={slotMeta && slotMeta.touched && slotMeta.error ? true : false} helperText={slotMeta && slotMeta.touched ? slotMeta.error : undefined}>
                        {data && data.length > 0 && data.map((d: any, index: number) => {
                            return <MenuItem id={index + ''} key={index} value={d}>
                                {slotModel(d).getFormattedValue(startDate)}
                            </MenuItem>;
                        })}
                    </TextField>
                </Grid>
            </Grid>
        </>
    );
};