import { useAsync } from 'react-async';
import client, { objectToQueryString } from './api-client';

const fetchReport = async ([reportParam]: any) => {
    let url = 'appointments/reportData';
    url += `?startDate=${reportParam.startDate}&endDate=${reportParam.endDate}`;
    if (reportParam.group && reportParam.group.length > 0) {
        const groupBy = reportParam.group.join(',');
        url += `&GroupBy=${groupBy}`;
    }
    return client(url);
};


export function useGenerateReport(callback?: any) {
    return useAsync({ deferFn: fetchReport, onResolve: callback });
}

const fetchReportsAppointment = async ([filter]: any) => {
    let url = 'appointments';
    if (filter) {
        url += '?' + objectToQueryString(filter);
    }
    return client(url);
}

export function useFetchReportsAppointment(callback?: any) {
    return useAsync({ deferFn: fetchReportsAppointment, onResolve: callback });
}


