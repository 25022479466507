import React from "react";
import useAlert from "./useAlert";
import AlertMsg from "../utils/AlertMsg";
//import Modal from "./modal";

interface Alerts {
    modalContent?: any;
    closeModal?: any;
    showModal?: any;
    showErr?: any;
    showInfo?: any;
    showDone?: any;
}
const ModalContext = React.createContext<Alerts>({});
let { Provider } = ModalContext;

const ModalProvider = ({ children }: any) => {
    const alertHelper = useAlert();
    return (
        <Provider value={alertHelper}>
            <AlertMsg />
            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };