import React from 'react';
import './App.css';
import AuthProvider from './container/AuthProvider';
import AdminApp from './pages'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AdminApp />
      </AuthProvider>
    </div>
  );
}

export default App;
