import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useLayoutEffect } from "react";
import client from "../api/api-client";
import { ModalContext } from "./ModalContainer";


const initialSettings: any = {
    org: {},
}
const OrgContext = React.createContext([initialSettings, () => { }]);

export const OrgContextProvider = ({ storeId, children }: any) => {
    const [data, setData] = React.useState(initialSettings);
    const alertHelper = useContext(ModalContext);
    const { logout } = useAuth0();
    useLayoutEffect(() => {
        client('organizations/me')
            .then(r => {
                if (r) {
                    setData(r)
                }
            }).catch(err => {
                if (err && err.ErrorMessages && err.ErrorMessages.length > 0) {
                    alertHelper.showModal({
                        msg: err.ErrorMessages.join(', '),
                        type: 'error',
                        duration: 5000,
                        closeAction: () => { logout({ returnTo: window.location.origin }) }
                    });
                }
                else {
                    alertHelper.showModal({
                        msg: "Error occurred while login", type: 'error',
                        closeAction: () => { logout({ returnTo: window.location.origin }) }
                    });
                }
                //logout({ returnTo: window.location.origin });
            });
    }, []);

    const updateOrg = (data: any) => {
        setData(data);
    }

    return (
        <OrgContext.Provider value={[data, updateOrg]}>{children}</OrgContext.Provider>
    );
};
//export const OrgContextProvider = OrgContext.Provider
export const OrgContextConsumer = OrgContext.Consumer
export default OrgContext