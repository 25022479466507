import {
    Button, createStyles, Dialog, DialogContent, Grid, Tabs, Tab,
    IconButton, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Theme, Typography, Link, useMediaQuery
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useState } from 'react';
import { ReactComponent as Tick } from '../../../assets/Tick.svg';
import { ModalContext } from '../../../container/ModalContainer';
import OrgContext from '../../../container/OrgContext';
import { getCurrency, getCurrencySymbol } from '../../../utils/eventUtils';
import { SubscriptionPlanTypes } from '../../../utils/types';
import PlanSwitch from './PlanSwitch';
import Payment from './Payment';
import TableBodyMobile from './TableBodyMobile';


export default function Plan(props: any) {
    const classes = useStyles();
    const alertHelpers = useContext(ModalContext);
    const [buttonState, setButtonState] = React.useState(false);
    const { plan, SetContext, context, refreshFn, showBuy } = props;
    const [org] = useContext(OrgContext);
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClickOpen = (event: React.SyntheticEvent, plan: any) => {
        event.preventDefault();
        SetContext({ ...context, popup: true, selectedPlan: plan });
    };

    const handleClose = () => {
        SetContext({ ...context, popup: false, selectedPlan: null });
    };

    const handleIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedIndex(newValue);
    };

    const contactUs = process.env.REACT_APP_CONTACT_US_URL;

    return (<>
        { context.selectedPlan && <Dialog open={context.popup} onClose={handleClose}>
            <DialogTitle onClose={handleClose} >Plan Confirmation</DialogTitle>
            <DialogContent>
                    <Payment {...props} setButtonState={setButtonState} />
            </DialogContent>
        </Dialog>}
        {isSmallDevices ? <> 
        <Paper variant="outlined" className={classes.rootMobile}>
              <Tabs
                  textColor='secondary' className={classes.tabs}
                  orientation="horizontal"
                  variant="scrollable"
                  scrollButtons="on"
                  value={selectedIndex} 
                  onChange={handleIndexChange}>
                    {plan.subscriptionPlans.map((values: any,index: number) => (
                                <Tab id={values.plan} label={values.plan.toUpperCase()} value={index} />
                    ))}
                </Tabs>
              <Grid className={classes.content}>
                  <TableBodyMobile index={selectedIndex} plans={plan.subscriptionPlans} 
                  SetContext={SetContext} context={context} showBuy={showBuy}
                  buttonState={buttonState}/>
              </Grid>
          </Paper>
        </> : <Grid container className={classes.root}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="all plans">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <PlanSwitch context={context} SetContext={SetContext} />
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center" className={classes.tabletopbar}>{values.plan.toUpperCase()}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow key="features">
                            <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                                Features
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                values.plan === SubscriptionPlanTypes.Free || values.plan === SubscriptionPlanTypes.Enterprise ? <>
                                    <StyledTableCell align="center">
                                        {values.plan === SubscriptionPlanTypes.Free ? null :
                                            <Link href={contactUs} target="_blank" className={classes.refLink}>
                                                Contact Us
                                        </Link>}
                                    </StyledTableCell> </> : <>
                                        <StyledTableCell align="center" style={{ fontWeight: 600, fontSize: '16px' }}>
                                            <Grid> {context.planType ? <>
                                                {getCurrencySymbol(context.currency)}{values.cost.yearly[context.currency].price} </>
                                                :
                                                <> {getCurrencySymbol(context.currency)}{values.cost.monthly[context.currency].price} </>}
                                                {org.countryCode !== 'in' ? getCurrency(org.countryCode) : ""}
                                            </Grid>
                                            {showBuy && <Button color="secondary"
                                                variant="contained"
                                                disabled={buttonState}
                                                name={values.plan}
                                                className={classes.subscribeButton}
                                                onClick={e => handleClickOpen(e, values)}
                                            >
                                                Buy
                                    </Button>}
                                        </StyledTableCell>
                                    </>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="locationCalendarsLimit">
                            <StyledTableCell component="th" scope="row">
                                Location Calendars
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any, index: any) => (
                                <StyledTableCell align="center">
                                    {values.features['locationCalendarsLimit'] > 1 ? "Up to " : null}
                                    {values.features['locationCalendarsLimit'] < 1 ? "Unlimited" : values.features['locationCalendarsLimit']}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="appointmentDataRetention">
                            <StyledTableCell component="th" scope="row">
                                Appointment Data Retention
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any, index: any) => (
                                <StyledTableCell align="center">
                                    {values.features['appointmentDataRetention'] ? values.features['appointmentDataRetention'] + " Days" : null}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="locationVisit">
                            <StyledTableCell component="th" scope="row">
                                Location Visits
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['locationVisit'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="videoCall">
                            <StyledTableCell component="th" scope="row">
                                Video Calls
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['videoCall'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="homeVisit">
                            <StyledTableCell component="th" scope="row">
                                Home Visits
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['homeVisit'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="customerFacingWebsite">
                            <StyledTableCell component="th" scope="row">
                                Customer Facing Webite
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['customerFacingWebsite'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="assignIncomingAppointmentsToEmployees">
                            <StyledTableCell component="th" scope="row">
                                Assign Appointments to Employees
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['assignIncomingAppointmentsToEmployees'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="appointmentLifecycleAndRules">
                            <StyledTableCell component="th" scope="row">
                                Appointment Lifecycle and Rules
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['appointmentLifecycleAndRules'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="emailNotifications">
                            <StyledTableCell component="th" scope="row">
                                Email Notifications
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['emailNotifications'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="smsNotifications">
                            <StyledTableCell component="th" scope="row">
                                SMS Notifications
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['smsNotifications'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="appointmentReminder">
                            <StyledTableCell component="th" scope="row">
                                Appointment Reminders
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['appointmentReminder'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="customerDashboard">
                            <StyledTableCell component="th" scope="row">
                                Customer Dashboard
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['customerDashboard'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="reporting">
                            <StyledTableCell component="th" scope="row">
                                Reporting
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['reporting'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow key="reporting">
                            <StyledTableCell component="th" scope="row">
                                Payment Gateway
                            </StyledTableCell>
                            {plan.subscriptionPlans.map((values: any) => (
                                <StyledTableCell align="center">
                                    {values.features['paymentGateway'] ? <Tick /> : "-"}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>}
    </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '5px',
        },
        rootMobile:{
            marginTop: '10px',
            width:'100%',
        },
        tabs: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            width: '100%',
            minWidth: '64px',
        },
        content: {
            margin: theme.spacing(0, 1),
            marginBottom: theme.spacing(1),
            maxWidth: '100%'
        },
        refLink: {
            color: theme.palette.secondary.main,
            fontWeight: 400,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
        },
        subscribeButton: {
            marginTop: "3px",
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        tabletopbar: {
            minWidth: '150px',
            [theme.breakpoints.down('sm')]: {
                minWidth: 'auto',
            },
        }
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#F5F8FA",
            padding: '8px',
            fontWeight: 600
        },
        body: {
            padding: '8px'
        }

    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
        },
    }),
)(TableRow);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: '16px 16px 16px 24px',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

