import { useAsync } from "react-async";
import client from './api-client';
const STORE_ENDPOINT = "locations";

const getStores = async ([searchText]: any) => {
    let storeURL = STORE_ENDPOINT;
    if (searchText)
        storeURL = storeURL + `?SearchText=${searchText}`
    return client(storeURL);
};

export function useGetStores() {
    return useAsync({ deferFn: getStores });
}

const addStore = async ([store, id]: any) => {
    return client(STORE_ENDPOINT, { data: store });
};

export function useAddStore(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: addStore, onResolve: onResolve, onReject: onReject });
}

const getStoreById = async ([id]: any) => {
    let storeURL = `${STORE_ENDPOINT}/${id}`
    return client(storeURL);
};

export function useGetStoreById() {
    return useAsync({ deferFn: getStoreById });
}

const updateStore = async ([store, id]: any) => {
    let storeURL = `${STORE_ENDPOINT}/${id}`
    return client(storeURL, { data: store, method: 'PUT' });
};

export function useUpdateStore(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: updateStore, onResolve: onResolve, onReject: onReject });
}

const deleteStore = async ([id]: any) => {
    let storeURL = `${STORE_ENDPOINT}/${id}`
    return client(storeURL, { method: 'DELETE' });
};

export function useDeleteStore(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: deleteStore,onResolve: onResolve, onReject: onReject });
}

const updateFormFields = async ([values, id]: any) => {
    let storeURL = `${STORE_ENDPOINT}/${id}/formFields`
    return client(storeURL, { data: values, method: 'PUT' });
};

export function useUpdateFormFields(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: updateFormFields, onResolve: onResolve, onReject: onReject });
}

