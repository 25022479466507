import React, { useState, useEffect } from 'react';
import { MenuItem} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { CustomFormInputValueType } from '../../../utils/types'

export const CustomSelectField = (props: any) => {
    return (
        <TextField
            select
            {...props}
        >
           <MenuItem  value={CustomFormInputValueType.Text}>Text</MenuItem>
            <MenuItem  value={CustomFormInputValueType.Number}>Number</MenuItem>
            <MenuItem  value={CustomFormInputValueType.Date}>Date</MenuItem>
            <MenuItem  value={CustomFormInputValueType.Dropdown}>Dropdown</MenuItem>
            <MenuItem  value={CustomFormInputValueType.File}>File</MenuItem>
        </TextField >
    );
};