import {
    Button, createStyles, FormControl, Grid, InputLabel,
    makeStyles, MenuItem, Select,
    TextField as TF, Theme
} from '@material-ui/core';
import { Form, useField } from 'formik';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import useCustomTranslation from '../../container/useCustomTranslation';
import { AppointmentReportGroup } from '../../utils/types';
import Loader from '../Loader';


export default function ReportQuery(props: any) {
    const { t } = useCustomTranslation();

    return (
        <Form>
            <Loader useBackdrop open={false} />

            <Grid container spacing={1} xs={12} justify="space-between" alignItems="flex-end">
                <Grid item xs={12} md={4}>
                    <GroupByDropdown />
                </Grid>
                <Grid item md={2} xs={4}>
                    <CustomDateTimeField name="startDate" isTime={false} placeholder={t('Reports.StartDate')} />
                </Grid>
                <Grid item md={2} xs={4}>
                    <CustomDateTimeField name="endDate" isTime={false} placeholder={t('Reports.EndDate')} />
                </Grid>
                <Grid item xs={4} md={2} style={{ textAlign: 'end' }}>
                    <Button type="submit" id="genReport" variant="contained" color="secondary">{t('Reports.Generate')}</Button>
                </Grid>
            </Grid>
        </Form>
    );
}

const CustomDateTimeField = (props: any) => {
    const { name, isTime, placeholder, disabled } = props;
    const { t } = useCustomTranslation();
    const [, meta, helpers] = useField(name)
    const { value, error } = meta;
    const { setValue } = helpers;
    const onValChange = (val: any) => {
        setValue(val);
    }

    return (<ReactDatePicker placeholderText={placeholder} selected={value} fixedHeight popperClassName="my-datepicker-opt"
        showTimeSelect={isTime} customInput={<CustomDateInput err={error} />} popperPlacement="bottom-start"
        showTimeSelectOnly={isTime} timeIntervals={30} timeCaption={t('Reports.Time')} disabled={disabled}
        dateFormat={isTime ? "h:mm aa" : undefined} onChange={(e) => { onValChange(e) }} />);

}

function CustomDateInput({ onClick, value, placeholder, id, err }: any) {
    return (<TF type="button" id={id} value={value} size="small" color="secondary" fullWidth
        label={placeholder} onClick={onClick} required error={err} helperText={err} inputProps={{ style: { textAlign: 'left' } }}
    />);
}

function GroupByDropdown(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const MenuProps: any = {
        classes: {
            list: classes.menuItem
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const [, meta, helpers] = useField<any[]>('group');
    const { value } = meta;
    const { setValue } = helpers;

    const handleChange = (event: any) => {
        setValue(event.target.value as string[]);
    };
    return (
        <FormControl fullWidth color="secondary" required style={{ textAlign: 'left' }}>
            <InputLabel>{t('Reports.GroupBy')}</InputLabel>
            <Select onChange={handleChange} multiple value={value} required label={t('Reports.GroupBy')}
                renderValue={(selected: any) => (selected as string[]).map(v => t(`Reports.${v}`)).join(', ')}
                MenuProps={MenuProps}>
                <MenuItem id='1' value={AppointmentReportGroup.locationId}
                    className={classes.menuItem}>{t(`Reports.${AppointmentReportGroup.locationId}`)}</MenuItem>
                <MenuItem id='2' value={AppointmentReportGroup.type}
                    className={classes.menuItem}>{t(`Reports.${AppointmentReportGroup.type}`)}</MenuItem>
                <MenuItem id='3' value={AppointmentReportGroup.status}
                    className={classes.menuItem}>{t(`Reports.${AppointmentReportGroup.status}`)}</MenuItem>
                <MenuItem id='4' value={AppointmentReportGroup.reason}
                    className={classes.menuItem}>{t(`Reports.${AppointmentReportGroup.reason}`)}</MenuItem>
            </Select>
        </FormControl>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(2, 1),
            borderRadius: '3px',
            width: '100px',
            height: '35px',
            fontSize: '16px',
            lineHeight: '19px',
        },
        menuItem: {
            "& li:hover": {
                background: 'skyblue'
            },
            "& li.Mui-selected:hover": {
                background: "skyblue"
            }
        },
    }));