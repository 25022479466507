import React, { useEffect } from 'react';
import { useField, Field, useFormikContext } from 'formik';
import { Grid , TextField as TF, MenuItem, InputLabel, FormControl, InputAdornment,
        Tooltip
} from '@material-ui/core';
import { TextField, Select } from "formik-material-ui";
import { CustomFormInputValueType } from '../../utils/types'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InfoIcon from '@material-ui/icons/Info';
import { getFormFields } from './FormHelper';
import { makeStyles } from '@material-ui/core/styles';

const CustomDateInput = ({ value, label, onClick, helpText, isRequired }: any) => (
    <TF variant="outlined" size="small" color="secondary" 
        fullWidth inputProps={{ style: { textAlign: 'left' } }}
        label={label} onClick={onClick} value={value}
        required={isRequired}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                    <Tooltip title={helpText} placement="left-start">
                        <InfoIcon style={{color:'#818181'}}/>
                    </Tooltip>
                </InputAdornment>,
        }}
        />
);

export default function CustomFormDetails({ StoreData, fieldName, fieldArrayHelpers }: any) {
    const classes = useStyles();
    const store = StoreData.store;
    const { setFieldValue } = useFormikContext();
    const [, Meta, Helpers] = useField(fieldName);
    const { value } = Meta;
    const { setValue } = Helpers;

    useEffect(() => {
        if (store.formFields) {
            getFormFields(fieldArrayHelpers, store);
        }
    }, [])

    const uploadFile = (e: any, index:any) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            value[index].TextValue = file.name;
            value[index].file = file;
            setValue(value);
        }
        if(file){
            reader.readAsDataURL(file)
        }
    }

    return (<>
        {store.formFields && store.formFields.map((field : any, index: any) => (
            <>
            {field.fieldType === CustomFormInputValueType.Text &&
            <Grid item xs={12}>
                <Field component={TextField} name={`${fieldName}.${index}.TextValue`} required={field.isRequired}
                    variant="outlined" size="small" color="secondary" fullWidth label={field.name}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                                <Tooltip title={field.helpText} placement="left-start">
                                    <InfoIcon style={{color:'#818181'}} />
                                </Tooltip>
                            </InputAdornment>,
                    }}
                />
            </Grid>}
            {field.fieldType === CustomFormInputValueType.Number &&
            <Grid item xs={12}>
                <Field component={TextField} name={`${fieldName}.${index}.NumericValue`} required={field.isRequired}
                        type='Number' variant="outlined" size="small" color="secondary" fullWidth label={field.name}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                    <Tooltip title={field.helpText} placement="left-start">
                                        <InfoIcon style={{color:'#818181'}} />
                                    </Tooltip>
                                </InputAdornment>,
                        }}
                        />
            </Grid>}
            {field.fieldType === CustomFormInputValueType.Dropdown &&
            <Grid item xs={12}>
                <FormControl fullWidth color="secondary" required={field.isRequired} style={{ textAlign: 'left' }}
                        size="small" title={field.helpText}>
                <InputLabel variant="outlined" margin="dense">{field.name}</InputLabel>
                <Field component={Select} name={`${fieldName}.${index}.TextValue`} required={field.isRequired}
                        variant="outlined" size="small" color="secondary" fullWidth type="text" label={field.name}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                    <Tooltip title={field.helpText} placement="left-start">
                                        <InfoIcon style={{color:'#818181'}} />
                                    </Tooltip>
                                </InputAdornment>,
                        }}
                        >
                        {field.options ? field.options.map( (op : any) => (
                            <MenuItem value={op}>{op}</MenuItem>
                        )): <MenuItem value=""></MenuItem>}
                    </Field>
                </FormControl>
            </Grid>}
            {field.fieldType === CustomFormInputValueType.Date &&
            <Grid item xs={12}>
                <DatePicker
                        popperPlacement="top-start"
                        popperClassName="my-datepicker-opt"
                        selected={(value[index] && value[index].dateValue && new Date(value[index].dateValue)) || null}
                        onChange={(date) => setFieldValue(`formFields.${index}.dateValue`,date)}
                        customInput={<CustomDateInput label={field.name} helpText={field.helpText} isRequired={field.isRequired}/>}
                    />
            </Grid>}
            {field.fieldType === CustomFormInputValueType.File &&
            <Grid item xs={12}>
                <FormControl variant="outlined" size="small" color="secondary" fullWidth>
                <InputLabel className={classes.fileLabel}
                      required={field.isRequired}  shrink
                     variant='outlined'>{field.name}</InputLabel>
                <TF name={`${fieldName}.${index}.TextValue`} required={field.isRequired}
                        variant="outlined" size="small" color="secondary" fullWidth
                        type="file"
                        onChange={(e: any) => { uploadFile(e, index) }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                    <Tooltip title={field.helpText} placement="left-start">
                                        <InfoIcon style={{color:'#818181'}} />
                                    </Tooltip>
                                </InputAdornment>,
                        }}/>
                </FormControl>
            </Grid>}
            </>
        ))}
    </>);
}

const useStyles = makeStyles((theme) => ({
    fileLabel:{
        background: 'white',
        padding: '0 5px',
    }
  
  }));