import React from 'react';
import { useField } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import useCustomTranslation from '../../container/useCustomTranslation';

export default function AutocompleteReasons({ appointmentReasons }: any) {
    const { t } = useCustomTranslation();
    const [, meta, helpers] = useField('reason');
    const { setValue } = helpers;
    return (
        <Autocomplete id="cust-msg" size="small" color="secondary"
            fullWidth freeSolo
            options={appointmentReasons ? appointmentReasons : []}
            onFocus={() => { helpers.setTouched(true) }}
            forcePopupIcon={true}
            onChange={(event, newValue) => { setValue(newValue) }}
            renderInput={(params) =>
                <TextField {...params}
                    color="secondary"
                    label={t('AppointmentForm.AppointmentReason')}
                    placeholder={t('AppointmentForm.ReasonPlaceHolder')}
                    required variant="outlined"
                    error={meta.touched && meta.error ? true : false}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                />}
            onInputChange={(e, value) => { setValue(value) }}
        />)
}