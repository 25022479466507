import { createStyles, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import React from 'react';
import AddButton from '../Settings/AddBtn';


interface MenuButtonProps {
    handleMenuClick: any;
    buttonLabel: any;
}


export default function MenuButton(props: MenuButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>

            <AddButton fieldId="add-new-aptmt" btnLabel={props.buttonLabel} onClick={handleClick} />

            <Menu
                id="logout-menu"
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menuBorder }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <MenuItem onClick={() => { handleClose(); props.handleMenuClick(false) }}>Single</MenuItem>
                <MenuItem onClick={() => { handleClose(); props.handleMenuClick(true) }}>Multiple</MenuItem>
            </Menu>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        avatar: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            width: '30px',
            height: '30px',
            fontSize: '16px',
        },
        menuBorder: {
            border: '1px solid lightgrey'
        },
        menuBarText: {
            cursor: "default",
            fontSize: "14px",
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        menuClickable: {
            cursor: "pointer",
            fontSize: "16px",
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                textDecoration: 'underline'
            }
        }
    }),
);