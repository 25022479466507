import { useContext } from "react";
import { useTranslation } from "react-i18next";
import OrgContext from "./OrgContext";

const defaultTerminology = {
    "organization": {
        "singular": "Business",
        "plural": "Businesses"
    },
    "location": {
        "singular": "Location",
        "plural": "Locations"
    },
    "employee": {
        "singular": "Employee",
        "plural": "Employees"
    },
    "customer": {
        "singular": "Customer",
        "plural": "Customers"
    },
    "Host": {
        "singular": "Host",
        "plural": "Hosts"
    }
}

export default () => {
    const [org] = useContext(OrgContext);
    const { t } = useTranslation();
    const trasnlate = (str: any, options?: any) => {
        if (org && org.terminology) {
            const interpolationOptions = {
                ...org.terminology,
                ...options
            }
            return t(str, interpolationOptions);
        } else {
            const interpolationOptions = {
                ...defaultTerminology,
                ...options
            }
            return t(str, interpolationOptions);
        }
    };

    return { t: trasnlate, defaultT: t }
}