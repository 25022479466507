import React from 'react';
import { MenuItem, TextField,Grid } from "@material-ui/core";
import 'react-datepicker/dist/react-datepicker.css';
import useCustomTranslation from "../../container/useCustomTranslation";

export default function SelectStore(props: any) {
    const { storeId,storeList, onChange,disabled } = props;
    const { t } = useCustomTranslation();
    return (
        <Grid item xs={12}>
        <TextField select id="store-id" label={t('AppointmentForm.SelectStore')}
          size="small" color="secondary" fullWidth
            variant="outlined" 
            onChange={onChange}
            disabled={disabled }
            value={storeId ? storeId : ''}
            >
             {storeList && storeList.length > 0 && storeList.map((store: any, index: number) => {
            return <MenuItem id={index + ''} key={index} value={store.id}
               >{store.name}</MenuItem>;
        })}
        </TextField>
     </Grid>
    );

};




                   