import React from "react";
import { Breadcrumbs, Typography, Link } from "@material-ui/core";
import { Link as RouterLink, Route } from 'react-router-dom';
import useCustomTranslation from "../container/useCustomTranslation";


const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;

const MAPPED_PATHS = [
  'locations', 'customers', 'locationsCalendar'
]

export default function BreadcrumbNav(props: any) {
  const { noLeftMargin } = props;
  const { t } = useCustomTranslation();
  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter((x) => x);
        return (
          <Breadcrumbs aria-label="breadcrumb"
            style={{ marginLeft: noLeftMargin ? '0' : '20px', minHeight: '40px', display: 'flex', alignItems: 'center' }}>
            <LinkRouter color="secondary" to="/home">
              Home
            </LinkRouter>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const onlyHome = pathnames.length === 1 && value === 'home';
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              const toPath = `${pathnames.slice(0, index + 1)}`

              return onlyHome ? null : last ? (
                <Typography style={{ textTransform: 'capitalize' }} color="textSecondary" key={to}>
                  {MAPPED_PATHS.includes(value) ? t(`Home.${value}`) : value}
                </Typography>
              ) : (
                  <LinkRouter style={{ textTransform: 'capitalize' }} color="secondary" to={to} key={to}>
                    {MAPPED_PATHS.includes(toPath) ? t(`Home.${toPath}`) : toPath}
                  </LinkRouter>
                );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
}
