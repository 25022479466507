
const BASE_URL = process.env.REACT_APP_API_URL;

export function objectToQueryString(obj: any) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

function getConfig(endpoint: string, { data, headers: customHeaders, ...customConfig }: any = {}) {
    const accessToken = localStorage.getItem('authHeader');
    const config = {
        method: data ? 'POST' : 'GET',
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            'Content-Type': data ? 'application/json' : undefined,
            Authorization: `Bearer ${accessToken}`,
            ...customHeaders
        },
        ...customConfig
    };
    return config;
}

async function client(endpoint: string, { data, headers: customHeaders, ...customConfig }: any = {}) {
    const config = getConfig(endpoint, { data, headers: customHeaders, ...customConfig });
    return window
        .fetch(`${BASE_URL}/${endpoint}`, config)
        .then(async (response) => {
            let is401 = false;
            if (response.status === 401) {
                console.log("Unauthorized");
                is401 = true;
                localStorage.removeItem('authHeader');
            }
            if (!is401) {
                const data = await response.json();
                if (response.ok) {
                    return data;
                }
                else {
                    return Promise.reject(data);
                }
            }
            else {
                return Promise.reject({ err: 401 });
            }
        }).catch(e => {
            return Promise.reject(e);
            // if (e && e.err !== 401) {
            //     return Promise.reject(e);
            // }
        });
}

export async function clientAsync(endpoint: string, { data, headers: customHeaders, ...customConfig }: any = {}) {
    const config = getConfig(endpoint, { data, headers: customHeaders, ...customConfig });
    return await fetch(`${BASE_URL}/${endpoint}`, config);
}

export default client;