import { useAsync } from "react-async";
import client from './api-client';
const EMPLOYEE_ENDPOINT = "employees";

export const getEmployees = async ([searchText]: any) => {
    let employeeURL = EMPLOYEE_ENDPOINT;
    if (searchText)
        employeeURL = employeeURL + `?SearchText=${searchText}`
    return client(employeeURL);
};

export function useGetEmployees() {
    return useAsync({ deferFn: getEmployees });
}

const addEmployee = async ([employee, id]: any) => {
    return client(EMPLOYEE_ENDPOINT, { data: employee });
};

export function useAddEmployee(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: addEmployee, onResolve: onResolve, onReject: onReject });
}

const getEmployeeById = async ([id]: any) => {
    let employeeURL = `${EMPLOYEE_ENDPOINT}/${id}`
    return client(employeeURL);
};

export function useGetEmployeeById() {
    return useAsync({ deferFn: getEmployeeById });
}

const updateEmployee = async ([employee, id]: any) => {
    let employeeURL = `${EMPLOYEE_ENDPOINT}/${id}`
    return client(employeeURL, { data: employee, method: 'PUT' });
};

export function useUpdateEmployee(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: updateEmployee, onResolve: onResolve, onReject: onReject });
}

const deleteEmployee = async ([id]: any) => {
    let employeeURL = `${EMPLOYEE_ENDPOINT}/${id}`
    return client(employeeURL, { method: 'DELETE' });
};

export function useDeleteEmployee(onResolve?: any, onReject?: any) {
    return useAsync({ deferFn: deleteEmployee, onResolve: onResolve, onReject: onReject });
}