import React from "react";
import useCustomTranslation from '../../container/useCustomTranslation';

export default function Event({ event }: any) {
  const { t } = useCustomTranslation();
  const firstEvent: any = event.events[0];
  if (firstEvent) {
    return (
      <span>
        {event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} | {
          event.events.length === 1 ? firstEvent.customer.name :
            <><b>{event.events.length}</b> {t('Calendar.Booking')}</>}
      </span>
    )
  } else {
    return null;
  }
}