import {
    Box, Button, ButtonBase, createStyles, Grid,
    makeStyles, Table,
    TableBody, TableCell, TableRow
} from '@material-ui/core';
import clsx from 'clsx';
import React from "react";
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/right-arrow.svg';
import useCustomTranslation from "../../container/useCustomTranslation";
import { getStoreIconFromType } from "../../utils/eventUtils";
import { EventIcon } from "../Calendar/EventIcon";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        gridListItem: {
            minHeight: 107,
            border: '1px solid #DFE3E9',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px #cccccc5e',
            borderTop: '4px solid',
            borderTopColor: theme.palette.secondary.main,
            margin: '10px 10px 0',
            borderRadius: '5px',
            lineBreak: 'anywhere'
        },
        buttonContent: {
            width: '100%',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.03em',
            color: theme.palette.secondary.main,
            borderTop: '2px solid #DFE3E9',
            backgroundColor: '#F9FAFA',
            boxSizing: 'border-box',
            borderRadius: '0 0 5px 5px',
            '&:hover': {
                fontWeight: 'bold'
            }
        },
        title: {
            fontSize: '16px',
            lineHeight: '18px',
            padding: theme.spacing(1, 2),
            fontWeight: 600,
            border: 0,
            paddingBottom: 0,
        },
        text: {
            border: 0,
            fontSize: '14px',
        },
        subText: {
            border: 0,
            fontSize: '17px',
            padding: 0,

        },
        pending: {
            color: '#008000'
        },
        arrowColor: {
            fill: theme.palette.secondary.main,
        }
    }),
);



export default function Store(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { store } = props;
    const link = "/locations/" + store.name;
    return (
        <Grid key={store.id} item xs={12} sm={6} md={4} spacing={1} justify="center">
            <Grid className={classes.gridListItem} key={store.id} item>
                <ButtonBase id={`store-${store.name}`} className="test-stores" component={Link}
                    disableRipple style={{ width: '100%' }} to={{ pathname: link, state: { id: store.id } }}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2} className={classes.title}>
                                    <Box display="flex" alignItems="center">
                                        <Box display="inline" paddingRight={1}>
                                            <EventIcon type={getStoreIconFromType(store)} fill={"#1E2226"} fontSize="small" />
                                        </Box>
                                        <Box display="inline">
                                            {store.name}
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.text}>{t('Stores.TotalAppointment')}</TableCell>
                                <TableCell className={classes.subText} align="right">{store.todayTotalAppointmentCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.text}>{t('Stores.PendingAppointment')} </TableCell>
                                <TableCell className={clsx(classes.pending, classes.subText)} align="right">{store.todayPendingAppointmentCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ padding: 0, border: 0 }} colSpan={2} align="center">
                                    <Button
                                        id={`open-cal-${store.name}`}
                                        color="primary"
                                        className={classes.buttonContent}
                                        endIcon={<Arrow className={classes.arrowColor} />}
                                        disableRipple>
                                        {t('Stores.Enter')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table >
                </ButtonBase>
            </Grid>
        </Grid >
    );
}