import { Divider, Grid, InputLabel, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React, { useContext,useMemo } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useCustomTranslation from '../../container/useCustomTranslation';
import { useUpdateAppointment } from '../../api/settingService';
import Loader from "../Loader";
import AddButton, { SaveButton } from "./AddBtn";
import DragAndDrop from './DragDrop';
import { ModalContext } from '../../container/ModalContainer';


export default function Appointment(props: any) {
  const classes = useStyles();
  const { t } = useCustomTranslation();
  const { org, refreshFn } = props;
  const alertHelpers = useContext(ModalContext);
  const updateSettings = useUpdateAppointment(refreshFn);

  const isFreePlan = useMemo(() => {
    return org && (!org.subscriptionPlan || org.subscriptionPlan.subscriptionPlanType === 'Free')
  }, [org]);

  const warningHandle = (event: any) => {
    if (event.target.value === 'false') {
      alertHelpers.showInfo(t('Alerts.CapacityWarnig'));
    }
  };

  const initialValues = {
    enforceAppointmentsCapacity: org.enforceAppointmentsCapacity ? true : false,
    autoAcceptCreatedAppointments: org.autoAcceptCreatedAppointments ? true : false,
    autoApproveRequestedAppointments: org.autoApproveRequestedAppointments ? true : false,
    appointmentDuration: org.appointmentDuration,
    maximumAllowedAdvanceBooking: org.maximumAllowedAdvanceBooking,
    maximumAllowedslots: org.maximumAllowedslots,
    isLocationVisitEnabled: org.isLocationVisitEnabled ? true : false,
    isVideoCallEnabled: org.isVideoCallEnabled ? true : false,
    isHomeVisitEnabled: org.isHomeVisitEnabled ? true : false,
    isSmsEnabled: org.isSmsEnabled ? true : false,

    sendReminderBefore: org.sendReminderBefore,

    locationEnforceAppointmentsCapacity: org.locationEnforceAppointmentsCapacity ? true : false,
    //locationAppointmentDuration: org.locationAppointmentDuration,
    locationMaximumAllowedAdvanceBooking: org.locationMaximumAllowedAdvanceBooking,
    locationMaximumAllowedSlots: org.locationMaximumAllowedSlots,
    locationAutoAcceptCreatedAppointments: org.locationAutoAcceptCreatedAppointments ? true : false,
    locationAutoApproveRequestedAppointments: org.locationAutoApproveRequestedAppointments ? true : false,

    videoEnforceAppointmentsCapacity: org.videoEnforceAppointmentsCapacity ? true : false,
    //videoAppointmentDuration: org.videoAppointmentDuration,
    videoMaximumAllowedAdvanceBooking: org.videoMaximumAllowedAdvanceBooking,
    videoMaximumAllowedSlots: org.videoMaximumAllowedSlots,
    videoAutoAcceptCreatedAppointments: org.videoAutoAcceptCreatedAppointments ? true : false,
    videoAutoApproveRequestedAppointments: org.videoAutoApproveRequestedAppointments ? true : false,

    homeEnforceAppointmentsCapacity: org.homeEnforceAppointmentsCapacity ? true : false,
    //homeAppointmentDuration: org.homeAppointmentDuration,
    homeMaximumAllowedAdvanceBooking: org.homeMaximumAllowedAdvanceBooking,
    homeMaximumAllowedSlots: org.homeMaximumAllowedSlots,
    homeAutoAcceptCreatedAppointments: org.homeAutoAcceptCreatedAppointments ? true : false,
    homeAutoApproveRequestedAppointments: org.homeAutoApproveRequestedAppointments ? true : false,

    showAppointmentReasonsOnWebsite: org.showAppointmentReasonsOnWebsite ? true : false,
    appointmentReasons: org.appointmentReasons ? org.appointmentReasons : []
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false);
        updateSettings.run(values);
      }}
      validate={(values) => {
        const errors: any = {};
        // if (values.maximumAllowedAdvanceBooking <0 || !Number.isInteger(values.maximumAllowedAdvanceBooking)) {
        //   errors.maximumAllowedAdvanceBooking = '';
        // }
        return errors;
      }}
    >
      {({ values, dirty }) => (<Form>
        <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0', marginBottom: '8px' }}>
          <Grid item>
            <Typography variant="h6">
              {t('Settings.Appointment')} </Typography>
          </Grid>
          <Grid item>
            <SaveButton type="submit" id="save-basic-settings" btnLabel={"Save"}
              disabled={!dirty} />
          </Grid>
        </Grid>

        <Loader useBackdrop open={updateSettings.isLoading} />
        <Grid container item spacing={1}>
          {/*step 1 */}
          <Grid container>
            <Grid item xs={12}>
              <Field component={CheckboxWithLabel} type="checkbox" checked={values.isLocationVisitEnabled}
                name="isLocationVisitEnabled" Label={{ label: t("AppointmentSettings.isLocationVisitEnabled"), classes: { label: classes.mainLabel } }} />
            </Grid>
            {values.isLocationVisitEnabled && <Grid container item xs={12} className={classes.subContainer}>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.locationEnforceAppointmentsCapacity}
                  name="locationEnforceAppointmentsCapacity" Label={{ label: t("AppointmentSettings.Enforce") }}
                  onClick={warningHandle} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.locationAutoAcceptCreatedAppointments}
                  name="locationAutoAcceptCreatedAppointments" Label={{ label: t("AppointmentSettings.AutoAcceptCreated") }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.locationAutoApproveRequestedAppointments}
                  name="locationAutoApproveRequestedAppointments" Label={{ label: t("AppointmentSettings.AutoApproveRequested"), style: { marginRight: 8 } }} />
              </Grid>
              {/* </Grid> */}
              {/* <Grid item xs={12} md={4}>
                <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                  <Grid item>
                    <InputLabel htmlFor="aptmt-slot">
                      {t("AppointmentSettings.AppointmentSlot")}
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 15, step: 15 }}
                      name="locationAppointmentDuration" required size="small" color="secondary" />
                  </Grid>
                  <Grid item>
                    {t("AppointmentSettings.Mins")}
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid container alignItems="center" justify="flex-start" item xs={12} md={4}>
                <Grid item>
                  <InputLabel htmlFor="aptmt-adv-booking">
                    {t("AppointmentSettings.MaxAdvBooking")}
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                    name="locationMaximumAllowedAdvanceBooking" required size="small" color="secondary" />
                </Grid>
                <Grid item>
                  {t("AppointmentSettings.Days")}
                </Grid>
              </Grid>

              <Grid container alignItems="center" justify="flex-start" item xs={12} md={4}>
                <Grid item>
                  <InputLabel htmlFor="aptmt-adv-booking">
                    {t("AppointmentSettings.MaxAdvSlots")}
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                    name="locationMaximumAllowedSlots" required size="small" color="secondary" />
                </Grid>
              </Grid>
            </Grid>}
            <Divider variant="fullWidth" className={classes.hrLine} />
          </Grid>
          {/* step 2 */}
          {!isFreePlan &&
         
          <Grid container>
            <Grid item xs={12}>
              <Field component={CheckboxWithLabel} type="checkbox" checked={values.isVideoCallEnabled}
                name="isVideoCallEnabled" Label={{ label: t("AppointmentSettings.isVideoCallEnabled"), classes: { label: classes.mainLabel } }} />
            </Grid>
            {values.isVideoCallEnabled && <Grid container item xs={12} className={classes.subContainer}>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.videoEnforceAppointmentsCapacity}
                  name="videoEnforceAppointmentsCapacity" Label={{ label: t("AppointmentSettings.Enforce") }}
                  onClick={warningHandle} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.videoAutoAcceptCreatedAppointments}
                  name="videoAutoAcceptCreatedAppointments" Label={{ label: t("AppointmentSettings.AutoAcceptCreated") }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.videoAutoApproveRequestedAppointments}
                  name="videoAutoApproveRequestedAppointments" Label={{ label: t("AppointmentSettings.AutoApproveRequested"), style: { marginRight: 8 } }} />
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12}>
                  <Grid item>
                    <InputLabel htmlFor="aptmt-slot">
                      {t("AppointmentSettings.AppointmentSlot")}
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 15, step: 15 }}
                      name="videoAppointmentDuration" required size="small" color="secondary" />
                  </Grid>
                  <Grid item>
                    {t("AppointmentSettings.Mins")}
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid container alignItems="center" justify="flex-start" item xs={12} md={4}>
                <Grid item>
                  <InputLabel htmlFor="aptmt-adv-booking">
                    {t("AppointmentSettings.MaxAdvBooking")}
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                    name="videoMaximumAllowedAdvanceBooking" required={!isFreePlan} size="small" color="secondary" />
                </Grid>
                <Grid item>
                  {t("AppointmentSettings.Days")}
                </Grid>
              </Grid>

              <Grid container alignItems="center" justify="flex-start" item xs={12} md={4}>
                <Grid item>
                  <InputLabel htmlFor="aptmt-adv-booking">
                    {t("AppointmentSettings.MaxAdvSlots")}
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                    name="videoMaximumAllowedSlots" required size="small" color="secondary" />
                </Grid>
              </Grid>
            </Grid>}
            <Divider variant="fullWidth" className={classes.hrLine} />
          </Grid>
          }
          {/*step 3 */}
          <Grid container>
            <Grid item xs={12}>
              <Field component={CheckboxWithLabel} type="checkbox" checked={values.isHomeVisitEnabled}
                name="isHomeVisitEnabled" Label={{ label: t("AppointmentSettings.isHomeVisitEnabled"), classes: { label: classes.mainLabel } }} />
            </Grid>
            {values.isHomeVisitEnabled && <Grid container item xs={12} className={classes.subContainer}>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.homeEnforceAppointmentsCapacity}
                  name="homeEnforceAppointmentsCapacity" Label={{ label: t("AppointmentSettings.Enforce") }}
                  onClick={warningHandle} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.homeAutoAcceptCreatedAppointments}
                  name="homeAutoAcceptCreatedAppointments" Label={{ label: t("AppointmentSettings.AutoAcceptCreated") }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field component={CheckboxWithLabel} type="checkbox" checked={values.homeAutoApproveRequestedAppointments}
                  name="homeAutoApproveRequestedAppointments" Label={{ label: t("AppointmentSettings.AutoApproveRequested"), style: { marginRight: 8 } }} />
              </Grid>
              <Grid container alignItems="center" justify="flex-start" item xs={12} md={4}>
                <Grid item>
                  <InputLabel htmlFor="aptmt-adv-booking">
                    {t("AppointmentSettings.MaxAdvBooking")}
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                    name="homeMaximumAllowedAdvanceBooking" required size="small" color="secondary" />
                </Grid>
                <Grid item>
                  {t("AppointmentSettings.Days")}
                </Grid>
              </Grid>

              <Grid container alignItems="center" justify="flex-start" item xs={12} md={4}>
                <Grid item>
                  <InputLabel htmlFor="aptmt-adv-booking">
                    {t("AppointmentSettings.MaxAdvSlots")}
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                    name="homeMaximumAllowedSlots" required size="small" color="secondary" />
                </Grid>
              </Grid>
            </Grid>}
            <Divider variant="fullWidth" className={classes.hrLine} />
          </Grid>
          {!isFreePlan &&
          <>
          <Grid container alignItems="center" justify="flex-start" spacing={2} item xs={12}>
            <Grid item>
              <Field component={CheckboxWithLabel} type="checkbox" checked={values.isSmsEnabled}
                name="isSmsEnabled" Label={{ label: t("AppointmentSettings.EnableSMS") }} />
            </Grid>
            <Grid item>
              <InputLabel htmlFor="sms">
                {t("AppointmentSettings.SMSCount")}
              </InputLabel>
            </Grid>
            <Grid item>
              <InputLabel id="sms">
                {org.smsBalance}
              </InputLabel>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={12} md={7}>
            <Grid item>
              <InputLabel>
                {t("AppointmentSettings.SendReminderBefore")}
              </InputLabel>
            </Grid>
            <Grid item>
              <Field component={TextField} className={classes.numberTextInput} type="number" inputProps={{ min: 1 }}
                name="sendReminderBefore" required={!isFreePlan} size="small" color="secondary" />
            </Grid>
            <Grid item>
              {t("AppointmentSettings.Mins")}
            </Grid>
          </Grid>

          <Divider variant="fullWidth" className={classes.hrLine} />
          </>
         }
          <Grid container alignItems="flex-start" justify="flex-start" item xs={12} spacing={1}>
            <FieldArray name="appointmentReasons">
              {({
                form: { values, setValues, validateForm },
                ...fieldArrayHelpers
              }) => <>
                  <Grid container item alignItems="center" xs={12} justify="space-between">
                    <InputLabel>
                      {t("AppointmentSettings.ReasonTemplates")}
                    </InputLabel>
                    <Grid container item md={3} alignItems="center" justify="space-between">
                      <Field component={CheckboxWithLabel} type="checkbox" checked={values.showAppointmentReasonsOnWebsite} 
                        name="showAppointmentReasonsOnWebsite" Label={{ label: t("AppointmentSettings.ShowReasonsOnWebsite") }}/>
                      <AddButton fieldId="add-new-reason" btnLabel={'Reason'}
                        onClick={() => fieldArrayHelpers.insert(values.appointmentReasons.length, '')} />
                    </Grid>

                  </Grid>
                  <DndProvider backend={HTML5Backend}>
                    <DragAndDrop values={values} fieldArrayHelpers={fieldArrayHelpers} />
                  </DndProvider>
                </>
              }
            </FieldArray>
            <Grid container item></Grid>
          </Grid>

        </Grid>
      </Form>)
      }
    </Formik >
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    numberTextInput: {
      width: "44px",
      marginLeft: "3px"
    },
    subContainer: {
      marginLeft: theme.spacing(1.5),
    },
    hrLine: {
      width: '100%',
      margin: theme.spacing(1, 0),
    },
    spacing: {
      marginRight: 4,
      padding: 0,
      marginTop: 2
    },
    btnroot: {
      marginLeft: theme.spacing(1),
      '&:hover': {
        fontWeight: 'bold'
      }
    },
    mainLabel: {
      fontWeight: 500,
    }

  }),
);