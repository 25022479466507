import React, { useState, useContext, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ModalContext } from '../../../container/ModalContainer';
import {
    Theme, createStyles, Grid, useMediaQuery, Drawer, DialogTitle, DialogContent, InputLabel,
    Divider, Button
} from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import useCustomTranslation from '../../../container/useCustomTranslation';
import client from '../../../api/api-client';
import Loader from '../../Loader';
import { CustomFormInputValueType } from '../../../utils/types'
import AddButton from "../AddBtn";
import { Cancel } from '@material-ui/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DnDCustomForm from './DnDCustomForm';
import { useUpdateFormFields } from '../../../api/storeService'
import {setOptionsViewArray} from './CustomFormHelper'

const STORE_ENDPOINT = "locations";

interface CustomFormProps {
    id?: string;
    selected?: any;
    handleClose: any;
    open: boolean;
    title: string;
    org: any;
}

const fetchStoreDetails = (id: string) => {
    return client(`${STORE_ENDPOINT}/${id}`)
}

export default function CustomFormFieldsTable(props: CustomFormProps) {
    const classes = useStyles();
    const { open, handleClose, title, selected, org } = props;
    const { t } = useCustomTranslation();
    const [storeDetails, setStoreDetails] = useState();
    useLayoutEffect(() => {
        if (selected) {
            fetchStoreDetails(selected.id)
                .then(r => setStoreDetails(r));
        }
    }, []);
    return (<>
        <Drawer open={open} onClose={handleClose}
            PaperProps={{ variant: 'outlined', style: { minWidth: '50vw', maxWidth: '900px', width: '100%' } }}
            anchor='right'>

            <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title}
                    <Cancel id="cloz-aptms" onClick={handleClose} /></div>
            </DialogTitle>
            <DialogContent style={{ padding: '10px' }}>
                {storeDetails? 
                    <CustomFormInputs handleClose={handleClose} org={org} selected={storeDetails} />:
                    <Loader useBackdrop open={true} />
                }
            </DialogContent>
        </Drawer>
    </>);

}

function CustomFormInputs(props: any) {
    const classes = useStyles();
    const { selected, handleClose, org } = props;
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const alertHelper = useContext(ModalContext);
    const { t } = useCustomTranslation();
    const [optionRows , setOptionRows] = useState(setOptionsViewArray(selected.formFields ? selected.formFields.length : 0 ))
    const closeFlyout = () => {
        handleClose(null, true);
    }
    const alertErr = (err: any) => {
        if (err && err.ErrorMessages) {
            alertHelper.showErr(err.ErrorMessages[0]);
        } else {
            alertHelper.showErr("Error occurred while saving data");
        }
    }
    const initialValues = {
        formFields : selected.formFields ? selected.formFields : [],
    };
    const updateStore = useUpdateFormFields(closeFlyout, alertErr);
    const changeOptionStringToList = (values : any) => {
        values.formFields.map((value: any, index: any)=>{
            if(value.fieldType === CustomFormInputValueType.Dropdown && value.options){
                if (typeof value.options === 'string'){
                    let optionstring = values.formFields[index].options;
                    values.formFields[index].options = optionstring.split(",");
                } 
            }
        })
    };
    const newEntry = {
        name : "",
        fieldType: CustomFormInputValueType.Text,
        isRequired : false,
        helpText: "",
        options: [],
    }
    return (<>
        <Formik
            initialValues={initialValues}
            onSubmit={(values: any, { setSubmitting }) => {
                setSubmitting(false);
                // change option string to list
                changeOptionStringToList(values);
                updateStore.run(values.formFields,selected.id)
            }}
            validate={(values) => {
                const errors: any = {};
                return errors;
            }}
            >
            {({ values, dirty }) => (<Form>
                <Loader useBackdrop open={false} />
                <Grid container alignItems="flex-start" justify="flex-start" item xs={12} spacing={1}>
                    <FieldArray name="formFields">
                    {({
                        form: { values, setValues, validateForm },
                        ...fieldArrayHelpers
                    }) => <>
                        <Grid container item alignItems="center" xs={12} justify="space-between">
                            <InputLabel>
                            </InputLabel>
                            <AddButton fieldId="add-new-reason" btnLabel={'Field'}
                            onClick={() => { fieldArrayHelpers.insert(values.formFields.length, newEntry);
                                                 setOptionRows([...optionRows, false ]) }} />

                        </Grid>
                        <DndProvider backend={HTML5Backend}>
                            <DnDCustomForm values={values} fieldArrayHelpers={fieldArrayHelpers} fieldName="formFields"
                            setOptionRows={setOptionRows}
                            optionRows={optionRows} />
                        </DndProvider>
                        </>
                    }
                    </FieldArray>
                    <Grid container item></Grid>
                </Grid>
                <div className={classes.buttons}>
                    <Divider variant="fullWidth" className={classes.hrLine} />

                    <Button id="create-store" variant="contained" type="submit"
                        disabled={!dirty} className={classes.button} color="secondary">
                        Save
                    </Button>
                    <Button id="cancel-create-store" onClick={handleClose} className={classes.button} color="inherit" variant="contained">
                        {t("StoreSettings.CloseBtn")}
                    </Button>
                </div>
            </Form>)}
        </Formik>
    </>)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(0.5, 1),
            borderRadius: '3px',
            width: '100px',
            height: '35px',
            fontSize: '16px',
            lineHeight: '19px',
        },
        hrLine: {
            width: '100%',
            margin: theme.spacing(1, 0),
        },
    }),
);