import {
    Button,
    createStyles, DialogContent, DialogTitle, Drawer,
    FormControl, Grid,
    InputLabel, makeStyles,
    MenuItem, Select, Theme, Divider
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Field, Form, Formik, useField } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React, { useContext } from 'react';
import useCustomTranslation from '../../container/useCustomTranslation';
import { useAddEmployee, useDeleteEmployee, useUpdateEmployee } from '../../api/employeeService';
import Loader from '../Loader';
import { PhoneNumberField } from '../FormFields/PhoneNumberField';
import { ModalContext } from '../../container/ModalContainer';

interface EmployeeFormProps {
    type: 'add' | 'edit' | 'view' | undefined;
    id?: string;
    selected?: any;
    handleClose: any;
    open: boolean;
    title: string;
    locations?: any;
}

export default function EmployeeForm(props: EmployeeFormProps) {
    const { open, handleClose, title } = props;

    return (
        <Drawer open={open} onClose={handleClose}
            PaperProps={{ variant: 'outlined', style: { minWidth: '40vw', maxWidth: '500px', width: '100%' } }}
            anchor='right'>

            <DialogTitle style={{ backgroundColor: '#34383C', padding: '5px 10px', color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title}
                    <Cancel id="cloz-aptms" onClick={handleClose} /></div>
            </DialogTitle>
            <DialogContent style={{ padding: '10px' }}>
                <EmployeeInputs {...props} />
            </DialogContent>
        </Drawer>
    );
}

function EmployeeInputs(props: any) {
    const classes = useStyles();
    const { selected, handleClose, type, locations }: any = props;
    const { t } = useCustomTranslation();
    const isReadOnly = type === 'view' || type === 'delete';
    const alertHelper = useContext(ModalContext);

    const closeFlyout = () => {
        handleClose(null, true);
    }
    const alertErr = (err: any) => {
        if (err && err.ErrorMessages) {
            alertHelper.showErr(err.ErrorMessages[0]);
        } else {
            alertHelper.showErr(t('Alerts.EmployeeSaveErr'));
        }
    }
    const updateEmployee = useUpdateEmployee(closeFlyout, alertErr);
    const addEmployee = useAddEmployee(closeFlyout, alertErr);
    const deleteEmployee = useDeleteEmployee(closeFlyout, alertErr);

    const updateSettings = type === 'edit' ? updateEmployee : addEmployee;
    const locationArr: any[] = selected && selected.locations ? selected.locations.map((v: any) => v.id) : [];

    const initialValues = selected ?
        {
            name: selected.name, email: selected.email, mobilePhone: selected.mobilePhone,
            locations: locationArr, isLocationAdmin: selected.isLocationAdmin || false
        } :
        { name: '', email: '', mobilePhone: '', locations: locationArr, isLocationAdmin: false };

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const errors: any = {};
                if (isReadOnly) {
                    return errors;
                }
                if (!values.name || values.name.trim() === '') {
                    errors.name = 'Required';
                }
                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                if (!values.mobilePhone) {
                    errors.mobilePhone = 'Required';
                } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.mobilePhone)) {
                    errors.mobilePhone = 'Invalid mobile number'
                }
                return errors;
            }}
            onSubmit={(values) => {
                if (type === 'delete') {
                    deleteEmployee.run(selected.id);
                } else {
                    const locationsId = values.locations.map(v => { return { "Id": v } });
                    const uploadData = {
                        name: values.name.trim(), email: values.email, mobilePhone: values.mobilePhone.trim(),
                        locations: locationsId, isLocationAdmin: values.isLocationAdmin
                    }
                    updateSettings.run(uploadData, selected.id)
                }
            }}>
            {({ dirty, setFieldValue, values }) => (<Form>
                <Loader useBackdrop open={updateSettings.isLoading || deleteEmployee.isLoading} />

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field component={TextField} name="name" type="text" label={t("EmployeeSettings.Name")}
                            required variant="outlined" size="small" disabled={isReadOnly} color="secondary" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Field component={TextField} name="email" type="email" label={t("EmployeeSettings.Email")}
                            variant="outlined" size="small" color="secondary" disabled={isReadOnly} fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneNumberField name="mobilePhone" type="text" label={t("EmployeeSettings.Phone")}
                            variant="outlined" size="small" color="secondary" disabled={isReadOnly} fullWidth required />
                    </Grid>
                    <LocationList disabled={isReadOnly} locations={locations} />
                    <Grid item xs={12}>
                        <Field component={CheckboxWithLabel} type="checkbox" checked={values.isLocationAdmin}
                            name="isLocationAdmin" Label={{ label: t("EmployeeSettings.LocationAdmin") }} />
                    </Grid>
                </Grid>
                <div className={classes.buttons}>
                    <Divider variant="fullWidth" className={classes.hrLine} />
                    {!isReadOnly && <Button id="create-employee" variant="contained" type="submit"
                        disabled={!dirty} className={classes.button} color="secondary">
                        {props.type === 'add' ? t('EmployeeSettings.CreateBtn') : t('EmployeeSettings.EditBtn')}
                    </Button>}
                    {type === 'delete' && <Button id="delete-employee" variant="contained"
                        type="submit" className={classes.button} color="secondary">
                        {t('EmployeeSettings.DeleteBtn')}
                    </Button>}
                    <Button id="cancel-create-employee" onClick={handleClose} className={classes.button} color="inherit" variant="contained">
                        {!isReadOnly ? t('EmployeeSettings.CancelBtn') : t("EmployeeSettings.CloseBtn")}
                    </Button>
                </div>
            </Form>)}
        </Formik>
    )
}

function LocationList(props: any) {
    const classes = useStyles();
    const { disabled, locations } = props;
    const { t } = useCustomTranslation();

    // moves the menu below the select input
    const MenuProps: any = {
        classes: {
            list: classes.menuItem
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };


    const [, meta, helpers] = useField<any[]>('locations');

    const { value } = meta;
    const { setValue } = helpers;
    const handleChange = (event: any) => {
        setValue(event.target.value as string[]);
    };

    return (
        <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" color="secondary">
                <InputLabel id="storeLabel" variant="outlined" margin="dense">{t("EmployeeSettings.LocationLabel")}</InputLabel>
                <Select onChange={handleChange} multiple value={value} MenuProps={MenuProps}
                    labelId='storeLabel' disabled={disabled} label={t("EmployeeSettings.LocationLabel")}
                    classes={{ select: classes.selectPadding }}>
                    {locations && locations.length > 0 && locations.map((d: any, index: number) => {
                        return <MenuItem id={index + ''} key={index} value={d.id}
                            className={classes.menuItem}>{d.name}</MenuItem>;
                    })}
                </Select>
            </FormControl>
        </Grid>
    );
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(0.5, 1),
            borderRadius: '3px',
            width: '100px',
            height: '35px',
            fontSize: '16px',
            lineHeight: '19px',
        },
        menuItem: {
            "& li:hover": {
                background: 'skyblue'
            },
            "& li.Mui-selected:hover": {
                background: "skyblue"
            }
        },
        hrLine: {
            width: '100%',
            margin: theme.spacing(1, 0),
        },
        selectPadding: {
            padding: '12px 14px'
        }
    }));