export const LOGIN_PATH='/'

export enum AppointmentType {
    All = "All",
    LocationVisit="LocationVisit",
    HomeVisit = "HomeVisit",
    VideoCall = "VideoCall"
}

export enum AppointmentStatus {
    Requested = "Requested",
    Created = "Created",
    Scheduled = "Scheduled",
    Accepted = "Accepted",
    Rejected = "Rejected",
    Cancelled = "Cancelled",
    InProgress = "InProgress",
    Completed = "Completed",
    Deleted = "Deleted"
}

export enum NotificationType {
    NotificationAny = 1,
    NotificationReminder = 2,
    NotificationRunningLate = 3,

    EmployeeAssigned = 4,
    EmployeeUnassigned = 5,

    CustomoerAppointmentCreated=6,
    CustomoerAppointmentRequested = 7,
    CustomoerAppointmentScheduled = 8,
    CustomoerAppointmentCancelled = 9,
    CustomoerAppointmentRejected = 10,

    EmployeeAppointmentCreated = 11,
    EmployeeAppointmentScheduled = 12,
    EmployeeAppointmentCancelled = 13,
    EmployeeAppointmentRejected = 14,
    EmployeeAppointmentCompleted= 15,
}

export enum AppointmentReportGroup {
    locationId = "locationId",
    type = "type",
    status = "status",
    reason = 'reason',
}

export interface Appointment {
    id?: string;
    type: AppointmentType;
    status: AppointmentStatus;
    appointmentTime: Date;
    appointmentPrice: number;
    appointmentDuration?: number;
    customer: Customer
    message?: string;
    host?: any;
    description?: string;
    cancellationReason?: string;
    rejectionReason?: string;
    canStart?: boolean;
    canCancel?: boolean;
    isPastDue?: boolean;
    completionNotes?: string;
    reason?: string;
    joinUrl?: string;
    formFields: any;
}
export interface Customer {
    id?: string;
    name: string;
    email?: string;
    mobilePhone?: string;
}

export const SubscriptionPlanTypes = {
    Free: 'Free',
    Basic: 'Basic',
    Intermediate: 'Intermediate',
    Premium: 'Premium',
    Enterprise: 'Enterprise'
}

export const PaymentStatus = {
    Pending: 'Pending',
    Paid: 'Paid',
    Evaluation: 'Evaluation',
    Active: 'Active',
    Cancelled: 'Cancelled',
    CancellationPending: 'CancellationPending'
}

export const SubscriptionPlanFrequency = {
    Monthly: 'Monthly',
    Yearly: 'Yearly'
}

export const SmsPackageType = {
    Silver: 'Silver',
    Gold: 'Gold',
    Platinum: 'Platinum'
}

export const PaymentGatewayType = {
    None: 'None',
    Razorpay: 'Razorpay',
    PayPal:'PayPal'
}

export const CustomFormInputValueType = {
    Text: 'Text',
    Number: 'Number',
    Date:'Date',
    Dropdown: 'Dropdown',
    File : 'File'
}