import {
    createStyles, Grid, IconButton, InputAdornment,
    makeStyles, TablePagination, TextField, Theme, useMediaQuery
} from '@material-ui/core';
import { Close, Search } from "@material-ui/icons";
import React, { useLayoutEffect, useState } from "react";
import { useAsync } from "react-async";
import { getCustomers } from '../api/customerService';
import BreadcrumbNav from "../component/BreadcrumbNav";
import CustomerForm from "../component/Customer/CustomerForm";
import CustomerList from '../component/Customer/CustomerList';
import Loader from "../component/Loader";
import AddButton from "../component/Settings/AddBtn";
import Page from "../container/Page";
import useCustomTranslation from "../container/useCustomTranslation";

const getAllCustomers = async ({ searchText, skipCount }: any, { signal }: any) => {
    const res = await getCustomers(searchText, skipCount, PAGE_SIZE);
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}
const searchCustomers = async ([{ searchText, skipCount }]: any, { signal }: any) => {
    const res = await getCustomers(searchText, skipCount, PAGE_SIZE);
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

const PAGE_SIZE = 15;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            padding: '6px',
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
            },
            '&:focus': {
                boxShadow: 'none',
            },
        },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            //overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            //margin: '10px 10px 0'
        },
        tableActions: {
            margin: 0,
            '& .MuiIconButton-root': {
                padding: '4px'
            }
        },
        toolbar: {
            padding: '0 8px',
            minHeight: '40px'
        },
        pagingCaption: {

        }
    }),
);
// <SvgIcon  fontSize="small"><Filter/></SvgIcon>
function SearchHead(props: any) {
    const { t } = useCustomTranslation();
    const { searchClick, onCreateClick, searchTerm } = props;
    const [textInput, setTextInput] = useState(searchTerm);

    const performOperation = (e: any) => {
        e.preventDefault();
        searchClick(textInput);
    }

    const clearSearch = () => {
        setTextInput('');
        searchClick('');
    }

    return (
        <>
            <TextField name="serach-text" size="small" color="secondary" variant="outlined"
                placeholder={t('Customers.SearchPlaceHolder')} value={textInput} style={{ margin: '0px 8px' }}
                onChange={(e: any) => setTextInput(e.target.value)} onKeyPress={e => {
                    if (e.key === 'Enter') {
                        performOperation(e);
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton id="action-btn" onClick={clearSearch} edge="end">
                                {textInput ? <Close /> : <Search />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    style: { height: '1em', padding: '8px' }
                }}
            />
            <AddButton fieldId="add-new-cust" btnLabel={t('CustomerForm.AddCustomer')}
                onClick={onCreateClick} />

        </>

    )
}


const initialState: any = { open: false, title: '', type: undefined, selected: {} };

export default function Customers() {
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const [flyout, setFlyout] = useState(initialState);
    const [customers, setCustomers] = useState<any>();
    const [page, setPage] = React.useState(0);
    const [textInput, setTextInput] = useState('');
    const { data, isPending, run, reload } = useAsync({
        promiseFn: getAllCustomers,
        deferFn: searchCustomers,
        searchText: textInput, skipCount: page * PAGE_SIZE
    })

    const handleClose = (e: any, doRefresh = false) => {
        if (doRefresh === true) {
            reload();
        }
        setFlyout(initialState);
    };

    const openEdit = (customer: any) => {
        setFlyout({ open: true, selected: customer, title: t("CustomerForm.EditLabel"), type: 'edit' });
    }

    const openDelete = (customer: any) => {
        setFlyout({ open: true, selected: customer, title: t("CustomerForm.DeleteLabel"), type: 'delete' });
    }

    const openCreate = (customer: any) => {
        setFlyout({ open: true, selected: {}, title: t("CustomerForm.CreateLabel"), type: 'add' });
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        run({ searchText: textInput, skipCount: newPage * PAGE_SIZE });

    };

    useLayoutEffect(() => {
        setCustomers(data ? data.models : []);
    }, [data]);

    const performSearch = (searchStr: any) => {
        setTextInput(searchStr.trim());
        if (textInput.trim() !== '' || searchStr.trim() !== '') {
            setPage(0)
            run({ searchText: searchStr, skipCount: 0 * PAGE_SIZE });
        }

    }
    // return (<>
    //     <SearchHead searchClick={run} onCreateClick={openCreate} />
    //     <CustomerList pending={isPending} error={error} data={customers} onEdit={openEdit} onDelete={openDelete} />
    //     {flyout.open &&
    //         <CustomerForm open={flyout.open} handleClose={handleClose} type={flyout.type}
    //             title={flyout.title} selected={flyout.selected} />}
    // </>
    // )
    // if (error) return <div>Something went wrong: {error.message}</div>
    return (<Page
        Header={() => <Grid container justify="center" alignItems="center">
            <Grid item xs style={{ display: 'flex' }}>
                <BreadcrumbNav />
            </Grid>
            <Grid item>
                <TablePagination
                    rowsPerPageOptions={[PAGE_SIZE]}
                    component="div"
                    count={data ? data.totalCount : 0}
                    rowsPerPage={PAGE_SIZE}
                    page={page}
                    classes={{ actions: classes.tableActions, toolbar: classes.toolbar, caption: classes.pagingCaption }}
                    onChangePage={handleChangePage}
                    style={{ display: 'flex' }}
                />
            </Grid>

            <Grid container={isSmallDevices} justify="space-between" item
                style={{ marginRight: '8px', marginBottom: isSmallDevices ? '4px' : '0' }}>
                <SearchHead searchClick={performSearch} onCreateClick={openCreate} searchTerm={textInput} />
            </Grid>
        </Grid>}
        Body={() => <>
            {isPending ? <Loader width={300} height={30} textHeight={40} /> :
                <div className={classes.root}>
                    <CustomerList data={customers} onEdit={openEdit} onDelete={openDelete} />
                </div>}
            {flyout.open &&
                <CustomerForm open={flyout.open} handleClose={handleClose} type={flyout.type}
                    title={flyout.title} selected={flyout.selected} />}
        </>}
    />)
};


