import { createStyles, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { Field, FieldArray } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import 'rc-time-picker/assets/index.css';
import React from "react";
import useCustomTranslation from '../../container/useCustomTranslation';


export default function StoreBusinessHours(props: any) {
    const { isReadOnly } = props;
    //const updateSettings = useUpdateWeeklyPlan(refreshFn);
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const isSmallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <Grid container spacing={1}>
            <FieldArray name="weeklyPlan">
                {({
                    form: { values, setValues, validateForm },
                    ...fieldArrayHelpers
                }) =>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align={'left'} className={clsx(classes.headerText, classes.textBoxPadding)}>
                                    {t('BusinessHours.Day')}
                                </TableCell>
                                <TableCell align={'center'} className={clsx(classes.headerText, classes.textBoxPadding)}>
                                    {t('BusinessHours.BusinessHours')}
                                </TableCell>
                                <TableCell align={'center'} className={clsx(classes.headerText, classes.textBoxPadding)}>
                                    {t('BusinessHours.Break')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.weeklyPlan.map((r: any, i: any) => {
                                const isItemSelected = values.weeklyPlan[i].workingHours ? true : false;
                                return (
                                    <TableRow hover tabIndex={-1} key={i}>
                                        <TableCell className={classes.textBoxPadding}>
                                            <Field component={CheckboxWithLabel} type="checkbox"
                                                checked={isItemSelected} disabled={isReadOnly}
                                                name={`weeklyPlan[${i}].isWorking`} Label={{ label: r.weekday, style: { marginRight: 0 } }} />
                                        </TableCell>

                                        <TableCell align="center" className={classes.textBoxPadding}>
                                            <Grid container spacing={isSmallDevices ? 0 : 1} justify="center" className={classes.flexColumn}>
                                                <Grid item>
                                                    <Field type="time" name={`weeklyPlan[${i}].workingHours.startTime`}
                                                        disabled={isReadOnly || !(values.weeklyPlan[i].isWorking)}
                                                        value={r.workingHours ? r.workingHours.startTime : ""} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                                                </Grid>
                                                <Grid item>
                                                    <label>To</label>
                                                </Grid>
                                                <Grid item>
                                                    <Field type="time" name={`weeklyPlan[${i}].workingHours.endTime`}
                                                        disabled={isReadOnly || !(values.weeklyPlan[i].isWorking)}
                                                        value={r.workingHours ? r.workingHours.endTime : ""} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center" padding="none">
                                            <Grid container spacing={isSmallDevices ? 0 : 1} justify="center" className={classes.flexColumn}>
                                                <Grid item>
                                                    <Field type="time" name={`weeklyPlan[${i}].break.startTime`}
                                                        disabled={isReadOnly || !(values.weeklyPlan[i].isWorking)}
                                                        value={r.break ? r.break.startTime : ""} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                                                </Grid>
                                                <Grid item>
                                                    <label>To</label>
                                                </Grid>
                                                <Grid item>
                                                    <Field type="time" name={`weeklyPlan[${i}].break.endTime`}
                                                        disabled={isReadOnly || !(values.weeklyPlan[i].isWorking)}
                                                        value={r.break ? r.break.endTime : ""} style={{ minWidth: '4em', width: 'auto', maxWidth: '8em' }} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                }
            </FieldArray>
        </Grid>)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerText: {
            fontWeight: 'bold',
            fontSize: '14px'
        },
        labelText: {
            fontWeight: 600,
            fontSize: '14px'
        },
        textBoxPadding: {
            padding: '6px 4px',
            '&:last-child': {
                padding: '6px 4px',
            }
        },
        flexColumn: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        }
    }),
);