import { sub } from 'date-fns';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, DateLocalizer, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarEvent from './CalendarEvent';
import CalendarMonthEvent from './CalendarMonthEvent';

const localizer: DateLocalizer = momentLocalizer(moment);

function CalendarUI(props: any) {
  const { handleEventOpen, handleSlotSelect, handleRangeChange, events, timings, resourcing, calVal,
    state, setState } = props;
  const isResourcing = Boolean(resourcing);
  const dstate: any = {
    view: 'day'
  };
  const [value, setValue] = useState(calVal.date);
  const formats: Object = {
    eventTimeRangeFormat: () => ""
  };

  useEffect(() => {
    if (calVal.view && calVal.view === 'fday') {
      setState(dstate);
      setValue(calVal.date);
    }
  }, [calVal]);

  return (
    <div className="Calendar">
      <Calendar
        defaultDate={value}
        views={isResourcing ? ['day'] : ['month', 'week', 'day']} //, 'work_week', 'agenda'
        defaultView={state.view}
        view={state.view}
        events={events}
        onView={(v) => setState({ ...state, view: v })}
        localizer={localizer}
        timeslots={1}
        step={timings.slot ? timings.slot : 60}
        //dayPropGetter={customDayPropGetter}
        selectable={true}
        popup
        dayLayoutAlgorithm='no-overlap'
        popupOffset={10}
        className='Calendar-main'
        eventPropGetter={(event, start, end, isSelected) => {
          return { style: { backgroundColor: '#F3F3F3', borderRadius: 0, border: '1px solid #aaaaaa', color: '#1E2226' } }
        }}

        date={value}
        onNavigate={(value) => {
          setValue(value)
        }}

        onSelectEvent={event => handleEventOpen(event)}
        onSelectSlot={handleSlotSelect}
        onRangeChange={(e, v) => { handleRangeChange && handleRangeChange(e, v) }}
        min={timings.startTime}
        max={timings.endTime && sub(timings.endTime, { minutes: 1 })}

        resources={isResourcing && resourcing.map}
        resourceIdAccessor={isResourcing && resourcing.resourceIdAccessor}
        resourceTitleAccessor={isResourcing && resourcing.resourceAccessor}

        formats={formats}
        components={{
          event: (props: any) => <CalendarEvent {...props} view={state.view} />,
          month: {
            event: CalendarMonthEvent
          }
        }}
      //scrollToTime={timings.startTime}
      />
    </div>
  );
}
// function propsAreEqual(prev: any, next: any) {
//   console.log(prev, next);
//   return prev.view === next.view;
// }

export default React.memo(CalendarUI);
