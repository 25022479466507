import React from 'react';
import { Auth0Provider } from "@auth0/auth0-react";

export default function AuthProvider(prop: any) {
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_DOMAIN ? process.env.REACT_APP_DOMAIN : ''}
            clientId={process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : ''}
            redirectUri={window.location.origin}
            audience={process.env.REACT_APP_AUDIENCE}
            scope={"profile read:current_user update:current_user_metadata"}
        >
            {prop.children}
        </Auth0Provider>
    );
}