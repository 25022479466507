
import { useAsync } from 'react-async';
import client, { clientAsync } from './api-client';

export async function getCustomers(searchText?: any, skipCount:number=0, takeCount?: any) {
    let customersURL = `customers?Skip=${skipCount}`;
    if (takeCount) {
        customersURL = customersURL + `&Take=${takeCount}`;
    }
    if (searchText) //SearchText=aaaa
        customersURL = customersURL + `&SearchText=${searchText}`

    const metadataResponse = clientAsync(customersURL);
    // const resp = await metadataResponse.json();
    return metadataResponse;
}

const fetchClientAppointments = async ([{ id, type }]: any) => {
    const url = `customers/${id}/appointments`
    return client(url);
};

export function useClientAppointments(callback?: any) {
    return useAsync({ deferFn: fetchClientAppointments, onResolve: callback });
}

const addCustomer = async ([customer]: any) => {
    return client('customers', { data: customer })
}

export function useAddCustomer(callback?: any, errCallback?: any) {
    return useAsync({ deferFn: addCustomer, onResolve: callback, onReject: errCallback });
}

const updateCustomer = async ([customer,id]: any) => {
    return client(`customers/${id}`, { data: customer, method: 'PUT'  })
}

export function useUpdateCustomer(callback?: any, errCallback?: any) {
    return useAsync({ deferFn: updateCustomer, onResolve: callback, onReject: errCallback });
}

const deleteCustomer = async ([id]: any) => {
    return client(`customers/${id}`, { method: 'DELETE'  })
}

export function useDeleteCustomer(callback?: any, errCallback?: any) {
    return useAsync({ deferFn: deleteCustomer, onResolve: callback, onReject: errCallback });
}


