import { IconButton, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Close, Done } from '@material-ui/icons';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFetchAvailableSlots } from '../../api/useFetchAvailableSlots';
import { getUTCDate } from '../../utils/eventUtils';
import { workdayModel } from '../../utils/workdayModel';
import { SlotsContainer } from './SlotsContainer';
import { DurationContainer } from './DurationContainer';


export function DateTimeSelector(props: any) {
  const { org, storeData, appointmnetType, closeButtonClass, event,
    onCloseButton, textBox, onSendButton, onTextBoxChange } = props;

  const eventDate = new Date(event.appointmentTime);
  const {
    holidays: orgHolidays,
    weeklyPlan: orgWeeklyPlan
  } = org;
  const {
    useOrganizationHolidays,
    useOrganizationWeeklyPlan,
    holidays,
    weeklyPlan,
    locationAppointmentDuration,
    homeAppointmentDuration,
    videoAppointmentDuration
  } = storeData.store;
  const storeWeeklyPlan = useOrganizationWeeklyPlan
    ? orgWeeklyPlan
    : weeklyPlan;
  const storeHolidays = useOrganizationHolidays ? orgHolidays : holidays;
  const storeWorkdayModel = workdayModel(storeWeeklyPlan, storeHolidays);

  const appointmentSlotDuration = (type: string) => {
    switch (type) {
      case 'VideoCall':
        return videoAppointmentDuration
      case 'LocationVisit':
        return locationAppointmentDuration
      case 'HomeVisit':
        return homeAppointmentDuration
      default:
        return 30
    }
  }

  const [slotValue, setSlotValue] = useState<any>();

  const [durationValue, setDurationValue] = useState<any>(textBox.appointmentDuration);

  const { isWorkingDay, nextWorkingDay } = storeWorkdayModel;

  const getDefaultValue = () => {
    const now = eventDate;
    if (dateValue) return dateValue;
    if (!isWorkingDay(now)) {
      return nextWorkingDay(now);
    }
    return now;
  };

  const startDate: any = getDefaultValue();

  const [dateValue, setDateValue] = useState<any>(startDate);

  const dayWorkingPlan = storeWorkdayModel.getDayWorkingPlan(startDate);
  const { isFullHoliday } = dayWorkingPlan;
  const {
    isPending,
    data,
    error,
    fetchAvailableSlots
  } = useFetchAvailableSlots(startDate, isFullHoliday, storeData.store.id, appointmnetType, durationValue);

  const onDateChange = (date: any) => {
    setDateValue(date);
    setSlotValue(null);
    fetchAvailableSlots({
      selectedDate: date,
      selectedStore: storeData.store.id,
      appointmentType: appointmnetType,
      appointmentDuration: durationValue
    });
  };

  const setSelectedDuration = (duration: number) => {
    setDurationValue(duration);
    setSlotValue(null);
    fetchAvailableSlots({
      selectedDate: dateValue,
      selectedStore: storeData.store.id,
      appointmentType: appointmnetType,
      appointmentDuration: duration
    });
    onTextBoxChange({ ...textBox, date: dateValue, appointmentDuration: duration })
  };

  const setSelectedSlot = (slot: any) => {
    setDateValue(startDate);
    setSlotValue(slot);
    const appointmentTime = getUTCDate(startDate);
    onTextBoxChange({ ...textBox, date: appointmentTime.toISOString(), slot: slot })
  };

  if (!storeData.store.id) {
    return <div>No data</div>
  }
  else {
    return (
      <Grid container xs={12} spacing={1} alignItems="center" justify="space-around">
        <Grid item xs={6} sm={3}>
          <DatePicker
            popperPlacement="top-start"
            popperClassName="my-datepicker-opt"
            selected={startDate}
            onChange={(date: any) => onDateChange(date)}
            minDate={new Date()}
            filterDate={storeWorkdayModel.isWorkingDay}
            customInput={<CustomDateInput />} />
        </Grid>
        <Grid item xs={6} sm={3} container justify="center">
          <DurationContainer
            dayWorkingPlan={dayWorkingPlan}
            setSelectedDuration={setSelectedDuration}
            durationValue={durationValue}
            durationBasedOnType={appointmentSlotDuration(appointmnetType)}
          />
        </Grid>
        <Grid item xs={6} sm={3} container justify="center">
          <SlotsContainer
            isPending={isPending}
            error={error}
            dateValue={dateValue}
            data={data}
            setSelectedSlot={setSelectedSlot}
            slotValue={slotValue}
          />
        </Grid>
        <Grid item container xs={6} sm={3}>
          <Grid item>
            <IconButton id="send-approve-btn" color="secondary" className={closeButtonClass} style={{ margin: '8px 0' }}
              disabled={!dateValue && !slotValue} onClick={onSendButton}>
              <Done />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton id="cancel-reason-btn" onClick={onCloseButton} className={closeButtonClass} style={{ marginRight: '0' }}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function CustomDateInput({ value, onClick }: any) {
  return (<TextField type="button" id="aptmt-date" value={value} variant="outlined" size="small" color="secondary" fullWidth
    label={"Date"} onClick={onClick}
  />
  )
}

