const BASE_URL = process.env.REACT_APP_API_URL;

export function validateDomain(doaminPath: string, header:any) {
    return client(`organizations/isDomainPathAvailable?domainPath=${doaminPath}`,header)
}

export function createOrg(authHeader: any, orgData: any, plan?: any) {
    let url = 'organizations'
    if (plan) {
        url = url + `?subscriptionPlanType=${plan}`;
    }
    return client(url,authHeader,{data:orgData})
}

function getConfig(accessToken: string, { data, headers: customHeaders, ...customConfig }: any = {}) {
    const config = {
        method: data ? 'POST' : 'GET',
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            'Content-Type': data ? 'application/json' : undefined,
            Authorization: `Bearer ${accessToken}`,
            ...customHeaders
        },
        ...customConfig
    };
    return config;
}

async function client(endpoint: string, accessToken: any, { data, headers: customHeaders, ...customConfig }: any = {}) {
    const config = getConfig(accessToken, { data, headers: customHeaders, ...customConfig });
    return window
        .fetch(`${BASE_URL}/${endpoint}`, config)
        .then(async (response) => {
            const data = await response.json();
            if (response.ok) {
              return data;
            } else {
              return Promise.reject(data);
            }
          });
}
