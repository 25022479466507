import { createStyles, Grid, InputAdornment, makeStyles, TextField as TF, Theme, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useContext } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import { useUpdateBasicDetails } from '../../api/settingService';
import { ModalContext } from '../../container/ModalContainer';
import useCustomTranslation from '../../container/useCustomTranslation';
import ColorPickerField from '../FormFields/ColorPickerField';
import { PhoneNumberField } from '../FormFields/PhoneNumberField';
import Loader from "../Loader";
import { SaveButton } from "./AddBtn";
import CountryCode from './CountryCode';
import ImageUploader from './ImageUploader';

const DOMAIN_PREFIX = process.env.REACT_APP_CUSTOMER_DOMAIN_PREFIX;

export default function OrgSetting(props: any) {
  const { t } = useCustomTranslation();
  const alertHelper = useContext(ModalContext);
  const { org, refreshFn } = props;
  const updateSettings = useUpdateBasicDetails(refreshFn);
  const classes = useStyles()
  const initialValues = {
    name: org.name,
    Address: org.address,
    email: org.email,
    mobilePhone: org.mobilePhone,
    countryCode: org.countryCode,
    country: org.country,
    primaryColor: org.primaryColor
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};
        if (!values.name || values.name.trim() === '') {
          errors.name = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (!values.mobilePhone) {
          errors.mobilePhone = 'Required';
        } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(values.mobilePhone)) {
          errors.mobilePhone = 'Invalid mobile number'
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        values.name = values.name.trim();
        values.primaryColor = values.primaryColor === '' ? undefined : values.primaryColor;
        updateSettings.run(values)
        setSubmitting(false);
      }}
    >
      {({ dirty, values }) => (<Form>
        <Grid container item xs={12} justify="space-between" style={{ padding: '16px 0', marginBottom: '16px' }}>
          <Grid item>
            <Typography variant="h6">
              {t('Settings.Organisation')} </Typography>
          </Grid>
          <Grid item>
            <SaveButton type="submit" id="save-basic-settings" disabled={!dirty} btnLabel={"Save"} />
          </Grid>
        </Grid>
        <Loader useBackdrop open={updateSettings.isLoading} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Field component={TextField} name="name" type="text" label={t("OrgSettings.Name")}
                  required variant="outlined" size="small" color="secondary" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Field component={TextField} name="email" type="email" label={t("OrgSettings.Email")}
                  variant="outlined" size="small" color="secondary" fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberField name="mobilePhone" type="text" label={t("OrgSettings.Phone")} changecode={org.mobilePhone}
                  variant="outlined" size="small" color="secondary" fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <CountryCode required />
              </Grid>
              <Grid item xs={12}>
                <Field component={TextField} name="Address" type="text" label={t("OrgSettings.Address")}
                  required variant="outlined" size="small" multiline rows={2} inputProps={{ style: { minHeight: '72px' } }}
                  color="secondary" fullWidth />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.helpboxBorder}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <ImageUploader org={org} refreshFn={refreshFn} />
              </Grid>

              <Grid item xs={12}>
                <ColorPickerField name="primaryColor" label={t("OrgSettings.CustomPrimaryColor")} />
              </Grid>
              <Grid item xs={12}>
                <TF variant="outlined" size="small" color="secondary" label={t("OrgSettings.CustomDomain")}
                  value={`${DOMAIN_PREFIX}${org.domainPath}`} disabled fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard text={`${DOMAIN_PREFIX}${org.domainPath}`}
                          onCopy={() => alertHelper.showDone(t('Alerts.LinkCopy'))}>
                          <FileCopy fontSize="small" color="secondary" />
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                    style: { color: '#333' }
                  }}
                  inputProps={{
                    style: { textOverflow: 'ellipsis', fontSize: '14px' },
                  }}
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Form>)}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      fontWeight: 600,
      fontSize: '16px'
    },
    helpboxBorder: {
      [theme.breakpoints.down('sm')]: {
        borderLeft: 'none',
        //borderTop: '1px solid #aaa',
        paddingLeft: '0px',
        //paddingTop: theme.spacing(1),
      },
      borderLeft: '1px solid #aaa',
      paddingLeft: theme.spacing(1),
    },
  }),
);

