import { Paper } from '@material-ui/core';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { getLocationsLite } from '../api/calendarService';
import { useGenerateReport, useFetchReportsAppointment } from '../api/reportService';
import Loader from '../component/Loader';
import ReportGraphics from '../component/Report/ReportGraphics';
import ReportQuery from '../component/Report/ReportQuery';
import EventListDialog from "../component/Calendar/EventListDialog";
import { AppointmentReportGroup } from '../utils/types'
import { ModalContext } from '../container/ModalContainer';
import useCustomTranslation from '../container/useCustomTranslation';

export default function Reports() {
    const report = useGenerateReport();
    const alertHelper = useContext(ModalContext);
    const { t } = useCustomTranslation();
    const [reportParams, setReportParams] = useState<any>();
    const [locations, setlocations] = useState<any>([]);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [events, setEvents] = useState<any[]>([]);
    const initValue: any = {
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        group: [AppointmentReportGroup.locationId]
    };

    useEffect(() => {
        getLocationsLite().then(res => {
            setlocations(res.models);
        })
    }, []);

    const handleDeatilClose = (value: any) => {
        setDetailsOpen(false);
    };
    const successCallback = (data: any) => {
        if (data && data.models.length > 0) {
            setEvents(data.models);
            setDetailsOpen(true)
        } else {
            alertHelper.showInfo(t('Alerts.NoAppointments'));
        }
    }

    const reportApt = useFetchReportsAppointment(successCallback);

    const handleEventOpen = (value: any) => {
        const reqObject: any = {
            startDate: reportParams.startDate,
            endDate: reportParams.endDate,
        }
        if (reportParams.group.includes(AppointmentReportGroup.locationId)) {
            reqObject['locationId'] = value.locationId;
        }
        if (reportParams.group.includes(AppointmentReportGroup.status)) {
            reqObject['appointmentStatus'] = value.status;
        }
        if (reportParams.group.includes(AppointmentReportGroup.type)) {
            reqObject['appointmentType'] = value.type;
        }
        if (reportParams.group.includes(AppointmentReportGroup.reason) && value.reason) {
            reqObject['appointmentReason'] = value.reason;
        }

        reportApt.run(reqObject);
    };

    return (
        <Paper variant="outlined" style={{ marginTop: "16px", border: 0 }}>
            <Formik
                initialValues={initValue}
                validate={values => {
                    const err: any = {};
                    if (moment(values.startDate).isAfter(moment(values.endDate))) {
                        err.startDate = t('Reports.TimeValidErr')
                    }
                    return err;
                }}
                onSubmit={(values) => {
                    const submitValue = {
                        startDate: moment(values.startDate).startOf('day').utc().toISOString(),
                        endDate: moment(values.endDate).endOf('day').utc().toISOString(),
                        group: values.group
                    }
                    setReportParams(submitValue);
                    report.run(submitValue);
                }}>
                <>
                    <ReportQuery genReport={report.run} />
                    <ReportGraphics report={report.data} cols={reportParams ? reportParams.group : []}
                        locations={locations} onCountClick={handleEventOpen} />
                    {detailsOpen && <EventListDialog events={events ? events : []} openedFrom="report"
                        handleClose={handleDeatilClose} open={detailsOpen} />}
                </>
            </Formik>
            <Loader useBackdrop open={report.isPending || reportApt.isPending} />
        </Paper>
    );
}




