import {
    createStyles, FormControlLabel, Grid, IconButton,
    InputAdornment, makeStyles, MenuItem, Radio, RadioGroup, TextField, Theme
} from '@material-ui/core';
import { Close, Done, Send } from '@material-ui/icons';
import React, { useContext, useLayoutEffect, useState } from 'react';
import useCustomTranslation from '../../container/useCustomTranslation';
import { fetchEmployees, getStore } from '../../api/calendarService';
import OrgContext from '../../container/OrgContext';
import { DateTimeSelector } from '../FormFields/DateTimeSelector';

export default function EventButtonsActionBox(props: any) {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { textBox, storeData, event } = props;
    const { onSendButton, onCloseButton, onTextChange, onRadioButtonClick } = props;
    const [eventMetaData, setEventMetaData] = useState<any>();
    const [org] = useContext(OrgContext);
    useLayoutEffect(() => {
        if (textBox.type === 'approve' || textBox.type === 'assign' || textBox.type === 'reschedule') {
            if (storeData) {
                setEventMetaData({ store: storeData.store, employees: storeData.employees.models })
            } else {
                const locationFetchId = event.locationId;
                const fetchData = async () => {
                    try {
                        const apiStore = await getStore(locationFetchId);
                        const apiEmployee = await fetchEmployees(locationFetchId);
                        setEventMetaData({ store: apiStore, employees: apiEmployee.models });
                    } catch (e) {
                        setEventMetaData({ store: {}, employees: [] });

                    }
                };
                fetchData();
            }
        }
    }, []);

    const setHostName = (evt: any) => {
        const emp = eventMetaData.employees.find((e: any) => e.id === evt.target.value);
        if (emp) {
            textBox.reminderType = emp.name;
        }
        onTextChange(evt);
    }

    if (textBox.type === 'approve' || textBox.type === 'assign') {
        return (
            <Grid container xs={12} justify="flex-start" alignItems="center" alignContent="center">
                <Grid item xs={12} sm={9}>
                    <TextField select id="cust-host" label={t('AppointmentForm.Host')} size="small" color="secondary" fullWidth
                        variant="outlined" disabled={!eventMetaData || !eventMetaData.employees}
                        value={textBox.text ? textBox.text : '-1'} onChange={setHostName}>
                        <MenuItem id={'0'} key={0} value={'-1'}>{textBox.label}</MenuItem>
                        {eventMetaData && eventMetaData.employees &&
                            eventMetaData.employees.map((d: any, index: number) => {
                                return <MenuItem id={index + ''} key={index} value={d.id}>{d.name}</MenuItem>;
                            })}
                    </TextField>
                </Grid>
                <Grid item>
                    <IconButton id="send-approve-btn" onClick={onSendButton} className={classes.button}>
                        <Done />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton id="cancel-reason-btn" onClick={onCloseButton} className={classes.button}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    } else if (textBox.type === 'reschedule') {
        if (eventMetaData) {
            return <DateTimeSelector storeData={eventMetaData} org={org} event={event}
                appointmnetType={event.type} closeButtonClass={classes.button} textBox={textBox}
                onTextBoxChange={props.onTextBoxChange} onCloseButton={onCloseButton} onSendButton={onSendButton} />
        } else {
            return <span>Fetching details..</span>
        }
    } else if (textBox.type === 'updateLink') {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField name="reason" size="small" type="url" color="secondary" variant="outlined" fullWidth value={textBox.text}
                        label={t("AppointmentDetails.StartURL")}
                        onChange={onTextChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField name="reason" size="small" type="url" color="secondary" variant="outlined" fullWidth value={textBox.reminderType}
                        label={t("AppointmentDetails.JoinURL")}
                        onChange={onRadioButtonClick} />
                </Grid>
                <Grid container item justify="flex-end" alignItems="center">
                    <Grid item>
                        <IconButton id="send-approve-btn" onClick={onSendButton} className={classes.button}>
                            <Done />
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <IconButton id="cancel-reason-btn" onClick={onCloseButton} className={classes.button}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );

    } else {
        return (
            <Grid container xs={12} justify="flex-start" alignItems="flex-end">
                <Grid item xs={12} sm={9}>
                    {textBox.type === 'notify' && <RadioGroup row value={textBox.reminderType} onChange={onRadioButtonClick}>
                        <Grid container direction="row" justify="flex-start">
                            <Grid item>
                                <FormControlLabel label={<div style={{ fontSize: '12px' }}>{t("AppointmentDetails.ReminderRB")}</div>}
                                    control={<Radio size="small" id="radio-type-all" color="secondary" />}
                                    value={'1'} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel label={<div style={{ fontSize: '12px' }}>{t("AppointmentDetails.LateRB")}</div>}
                                    control={<Radio size="small" id="radio-type-all" color="secondary" />}
                                    value={'2'} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel label={<div style={{ fontSize: '12px' }}>{t("AppointmentDetails.UpdateRB")}</div>}
                                    control={<Radio size="small" id="radio-type-all" color="secondary" />}
                                    value={'0'} />
                            </Grid>
                        </Grid>
                    </RadioGroup>}
                    <TextField name="reason" size="small" color="secondary" variant="outlined" fullWidth value={textBox.text}
                        label={textBox.label ? textBox.label : t("AppointmentDetails.MessageTxt")}
                        onChange={onTextChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton id="send-reason-btn" onClick={onSendButton} edge="end">
                                        <Send color="secondary" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid item>
                    <IconButton id="cancel-reason-btn" onClick={onCloseButton} className={classes.button}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            borderRadius: '5px',
            height: '30px',
            fontSize: '14px',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderColor: 'none',
            },
            '&:focus': {
                boxShadow: 'none',
            },
        },
    }),
);