import { createStyles, Divider, Grid, makeStyles, MenuItem, TextField, Theme, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Field, useField } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { useContext, useLayoutEffect, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFetchAvailableSlots } from "../../api/useFetchAvailableSlots";
import OrgContext from "../../container/OrgContext";
import useCustomTranslation from '../../container/useCustomTranslation';
import { slotModel } from "../../utils/slotModel";
import { workdayModel } from "../../utils/workdayModel";
import SelectDuration from './SelectDuration';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const weeks = [
    {value: 1, label:'Monday'},
    {value: 2, label:'Tuesday'},
    {value: 3, label:'Wednesday'},
    {value: 4, label:'Thursday'},
    {value: 5, label:'Friday'},
    {value: 6, label:'Saturday'},
    {value: 7, label:'Sunday'},
];

const CustomDateInput = ({ value, onClick }: any) => (
    <TextField type="button" id="aptmt-date" value={value} variant="outlined"
        size="small" color="secondary" fullWidth inputProps={{ style: { textAlign: 'left' } }}
        label={"Recurring Upto"} onClick={onClick} />
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkboxes: {
            padding: '4px 8px',
        },
    }));

export default function SelectRecurring(props: any) {
    const { storeId, storeData } = props;
    const [options, setOptions] = useState(weeks);
    const { t } = useCustomTranslation();
    const [org] = useContext(OrgContext);
    const classes = useStyles();
    const [, isRecurringMeta, isRecurringHelpers] = useField('isRecurring');
    const [, recurringDaysMeta, recurringDaysHelpers] = useField('recurringDays');
    const [, recurringUptoMeta, recurringUptoHelpers] = useField('recurringUpto');

    const { value: isRecurringValue } = isRecurringMeta;
    const { setValue: setIsRecurringValue } = isRecurringHelpers;

    const { value: recurringDaysValue } = recurringDaysMeta;
    const { setValue: setRecurringDaysValue } = recurringDaysHelpers;

    const { value: recurringUpto } = recurringUptoMeta;
    const { setValue: setRecurringUptoValue } = recurringUptoHelpers;

    const onDateChange = (date: Date) => {
        setRecurringUptoValue(date);
    };

    const onIsRecurringChange = (event: any) => {
        setIsRecurringValue(event.target.selected);
    };

    const setFields = (options: any) => {
        console.log(options);
        if (options) {
            setRecurringDaysValue(options.map((c: any) => c.value).sort(function (a: number, b: number) { return a - b; }));
        } else {
            setRecurringDaysValue(['']);
        }
    }

    const {
        holidays: orgHolidays,
        weeklyPlan: orgWeeklyPlan
    } = org;
    const {
        useOrganizationHolidays,
        useOrganizationWeeklyPlan,
        holidays,
        weeklyPlan,
        isHomeVisitEnabled,
        isLocationVisitEnabled,
        isVideoCallEnabled,
        locationAppointmentDuration,
        homeAppointmentDuration,
        videoAppointmentDuration
    } = storeData.store;

    const storeWeeklyPlan = useOrganizationWeeklyPlan
        ? orgWeeklyPlan
        : weeklyPlan;
    const storeHolidays = useOrganizationHolidays ? orgHolidays : holidays;
    const storeWorkdayModel = workdayModel(storeWeeklyPlan, storeHolidays);


    return (
        <>
            <Grid item xs={12}>
                <Field component={CheckboxWithLabel} type="checkbox" onChange={(e: any) => onIsRecurringChange(e)} checked={isRecurringValue}
                name="isRecurring" Label={{ label: 'Recurring Appointment' }}
                classes={{ root: classes.checkboxes }} />
            </Grid>
            {
                isRecurringValue &&
                <>
                    <Grid item xs={12} sm={6} style={{paddingBottom: '20px'}}>
                        <Autocomplete
                            multiple
                            id="checkboxes-customer-details"
                            size="small"
                            options={options}
                            disableCloseOnSelect fullWidth
                            getOptionLabel={(option: any) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderOption={(option: any, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            {option.label}
                                        </Grid>
                                        <Divider style={{ width: "100%" }} variant="fullWidth" />
                                    </Grid>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" size="small" color="secondary"
                                    InputProps={{
                                        ...params.InputProps
                                    }}
                                />
                            )}
                            onChange={(event, newValue) => setFields(newValue)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            popperPlacement="top-start"
                            popperClassName="my-datepicker-opt"
                            selected={recurringUpto}
                            onChange={(date: any) => onDateChange(date)}
                            minDate={new Date()}
                            filterDate={storeWorkdayModel.isWorkingDay}
                            customInput={<CustomDateInput />}
                        />
                    </Grid>
                </>
            }
        </>
    );
};