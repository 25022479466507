import { Checkbox, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { getCustomers } from '../../api/customerService';


const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const searchCustomers = async ([{ searchText }]: any, { signal }: any) => {
    const res = await getCustomers(searchText, 0);
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

export default function BulkAutocompleteCutomerDetails(props: any) {
    const [options, setOptions] = useState([]);
    const { storeData } = props;
    const [, meta, helpers] = useField<any[]>('customerIds');

    const { data, isLoading, run } = useAsync({
        deferFn: searchCustomers,
    })

    const loading = isLoading && options.length === 0;

    useEffect(() => {
        run({ searchText: '' })
    }, []);

    useEffect(() => {
        setOptions(data ? data.models : []);
    }, [data]);

    function handleChange(value: any) {
        if (value && value.trim() !== '') {
            run({ searchText: value })
        }
    }

    const setFields = (customer: any) => {
        console.log(customer);
        if (customer) {
            helpers.setValue(customer.map((c: any) => c.id));
        } else {
            helpers.setValue(['']);
        }
    }

    return (
        <>
            <Grid item xs={12}>
                <Autocomplete
                    multiple
                    id="checkboxes-customer-details"
                    size="small"
                    options={options}
                    disableCloseOnSelect fullWidth
                    getOptionLabel={(option: any) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={(option: any, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    {option.name}
                                    <div style={{ fontSize: '12px', color: '#666' }}>{option.email}</div>
                                    <div style={{ fontSize: '12px', color: '#666' }}>{option.mobilePhone}</div>
                                </Grid>
                                <Divider style={{ width: "100%" }} variant="fullWidth" />
                            </Grid>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small" color="secondary"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    onChange={(event, newValue) => setFields(newValue)}
                    onInputChange={(e, value) => { handleChange(value) }}
                    loading={loading}
                />
            </Grid>
        </>
    );
}