import PhoneNumber from 'awesome-phonenumber';
import { differenceInCalendarDays, differenceInMinutes, isSameDay } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { slotModel } from './slotModel';
import { AppointmentStatus, AppointmentType, PaymentStatus, SubscriptionPlanTypes } from "./types";

const DAYS_OF_WEEK = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export function isOrgPresent(user: any) {
    if (user && user['https://prorigo.com/org_data']) {
        const str = user['https://prorigo.com/org_data']
        const org = JSON.parse(str);
        return org && org.orgId;
    } else {
        return false;
    }
}
export function getColorByEnvStats(evStyle: AppointmentStatus) {
    switch (evStyle) {
        case AppointmentStatus.Completed:
            return '#555555';
        case AppointmentStatus.Accepted:
        case AppointmentStatus.Scheduled:
            return '#008000';
        case AppointmentStatus.Requested:
            return '#59ABE3';
        case AppointmentStatus.Cancelled:
            return '#CB070D';
        case AppointmentStatus.Rejected:
            return '#F5751A';
        case AppointmentStatus.InProgress:
            return '#F7C206';
        default:
            return '#70D005';
    }
}

export function getTypeStringFromType(type: AppointmentType) {
    switch (type) {
        case AppointmentType.HomeVisit:
            return "AppointmentForm.Type.Home";
        case AppointmentType.LocationVisit:
            return "AppointmentForm.Type.Store";
        case AppointmentType.VideoCall:
            return "AppointmentForm.Type.Video";
        default:
            return "";

    }
}

export function getTypeStringFromNumber(type: number) {
    switch (type) {
        case 1:
            return "AppointmentForm.Type.Store";
        case 2:
            return "AppointmentForm.Type.Home";
        case 3:
            return "AppointmentForm.Type.Video";
        default:
            return "";

    }
}

export function getStatusStringFromNumber(type: number) {
    switch (type) {
        case 1:
            return "Requested";
        case 2:
            return "Created";
        case 3:
            return "Scheduled";
        case 4:
            return "Rejected";
        case 5:
            return "Cancelled";
        case 6:
            return "InProgress";
        case 7:
            return "Completed";
        default:
            return "";

    }
}

export function getStoreIconFromType(store: any) {
    const isHomeVisit = store.isHomeVisitEnabled;
    const isStoreVisit = store.isLocationVisitEnabled;
    const isVideoVisit = store.isVideoCallEnabled;
    if (isHomeVisit && !isStoreVisit && !isVideoVisit) {
        return 'HomeVisit';
    } else if (!isHomeVisit && isStoreVisit && !isVideoVisit) {
        return 'LocationVisit';
    } else if (!isHomeVisit && !isStoreVisit && isVideoVisit) {
        return 'VideoCall'
    } else {
        return 'Multi'
    }
}

export function getMinMaxHoliday(orgSetting: any, storeData: any, view: string, date: Date) {
    const calSetting: any = {
        weekOff: []
    };
    const startTimes: any = [];
    const endTimes: any = [];
    let weeklyPlan: any = [];
    if (storeData.store && !storeData.store.useOrganizationWeeklyPlan) {
        weeklyPlan = storeData.store.weeklyPlan;
    } else {
        weeklyPlan = orgSetting.weeklyPlan;
    }
    if (weeklyPlan) {
        weeklyPlan.forEach((d: any) => {
            if (d.workingHours) {
                startTimes.push(slotModel(d.workingHours.startTime).getLocalTime(new Date()));
                endTimes.push(slotModel(d.workingHours.endTime).getLocalTime(new Date()));
            } else {
                calSetting.weekOff.push(d.weekday);
            }
        })
    }
    let weekday = date.getDay();
    if(view === 'day' && weeklyPlan[weekday].workingHours){
        calSetting.startTime = slotModel(weeklyPlan[weekday].workingHours.startTime).getLocalTime(new Date());
        calSetting.endTime = slotModel(weeklyPlan[weekday].workingHours.endTime).getLocalTime(new Date());
    }
    else{
        calSetting.startTime = _.min(startTimes);
        calSetting.endTime = _.max(endTimes);
    }
    if (!isSameDay(calSetting.startTime, calSetting.endTime)) {
        calSetting.startTime = undefined;
        calSetting.endTime = undefined;
    }
    const currStore = storeData.store;
    if (currStore && !currStore.useOrganizationHolidays) {
        calSetting.holidays = currStore.holidays;
    } else {
        calSetting.holidays = orgSetting.holidays;
    }
    const isHomeVisit = currStore.isHomeVisitEnabled;
    const isStoreVisit = currStore.isLocationVisitEnabled;
    const isVideoVisit = currStore.isVideoCallEnabled;

    if (isHomeVisit && !isStoreVisit && !isVideoVisit) {
        calSetting.slot = currStore.homeAppointmentDuration;
    } else if (!isHomeVisit && isStoreVisit && !isVideoVisit) {
        calSetting.slot = currStore.locationAppointmentDuration;;
    } else if (!isHomeVisit && !isStoreVisit && isVideoVisit) {
        calSetting.slot = currStore.videoAppointmentDuration;
    } else if (isHomeVisit && isStoreVisit && !isVideoVisit) {
        calSetting.slot = _.min([currStore.locationAppointmentDuration, currStore.homeAppointmentDuration]);
    } else if (!isHomeVisit && isStoreVisit && isVideoVisit) {
        calSetting.slot = _.min([currStore.locationAppointmentDuration, currStore.videoAppointmentDuration]);
    } else if (isHomeVisit && !isStoreVisit && isVideoVisit) {
        calSetting.slot = _.min([currStore.videoAppointmentDuration, currStore.homeAppointmentDuration]);
    } else {
        calSetting.slot = _.min([currStore.locationAppointmentDuration,
        currStore.videoAppointmentDuration, currStore.homeAppointmentDuration]);
    }
    // minimum of above setting. if it returns 0 set it to 15
    calSetting.slot = _.max([calSetting.slot, 15]);

    return calSetting;
}


export function getLocalTimeFormat(time: any) {

    let startTime = time.includes(".") ?
        time.split(".")[1].split(":") : time.split(":")

    const timeZoneOffset: any = (new Date()).getTimezoneOffset();

    let startTimeSign = startTime[0].includes("-") ? -1 : 1;

    let startTimeInMinutes = Math.abs(parseInt(startTime[0]) * 60) + parseInt(startTime[1]);
    startTimeInMinutes = startTimeInMinutes * startTimeSign;
    startTimeInMinutes = startTimeInMinutes - timeZoneOffset;
    startTimeInMinutes = startTimeInMinutes.toString().includes("-") ? (24 * 60 - Math.abs(startTimeInMinutes)) : startTimeInMinutes;

    let startHrs = Math.trunc(startTimeInMinutes / 60);
    startHrs = startHrs >= 24 ? startHrs - 24 : startHrs;
    let startMins = Math.abs(startTimeInMinutes % 60);

    const startTimeHr = startHrs >= 10 ? startHrs.toString() : "0" + startHrs.toString();
    const startTimeMins = startMins >= 10 ? startMins.toString() : "0" + startMins.toString();

    return `${startTimeHr}:${startTimeMins}`
}

export function getUTCTimeFormat(localTime: string): string {
    const [hrs, mins] = localTime.split(':');
    const timeZoneOffset: any = (new Date()).getTimezoneOffset();
    let timeInMinutes = parseInt(hrs) * 60 + parseInt(mins);
    timeInMinutes = timeInMinutes + timeZoneOffset;
    let sign = Math.sign(timeInMinutes);
    let hour = Math.trunc(timeInMinutes / 60);
    let min = Math.abs(timeInMinutes % 60);
    let minWithTwoDigit = min >= 10 ? min.toString() : "0" + min.toString();
    let timeFormat = hour + ":" + minWithTwoDigit;
    if (hour >= 24) {
        let day = Math.trunc(hour / 24);
        hour = Math.abs(hour % 24);
        let hoursWithTwoDigit = hour >= 10 ? hour.toString() : "0" + hour.toString();
        timeFormat = day.toString() + "." + hoursWithTwoDigit + ":" + minWithTwoDigit;
    }
    if (hour === 0 && sign === -1) {
        timeFormat = "-" + timeFormat;
    }
    return timeFormat;

}

export function getUTCTimeFormatForTimeSlot(localTime: string): string {
    const [hrs, mins] = localTime.split(':');
    const timeZoneOffset: any = (new Date()).getTimezoneOffset();
    let timeInMinutes = parseInt(hrs) * 60 + parseInt(mins);
    timeInMinutes = timeInMinutes + timeZoneOffset;
    let sign = Math.sign(timeInMinutes);
    let hour = Math.trunc(timeInMinutes / 60);
    let formattedHour = Math.abs(hour) < 10?( hour<0 ?(`-0${Math.abs(hour)}`):"0" + Math.abs(hour)): hour;
    let min = Math.abs(timeInMinutes % 60);
    let minWithTwoDigit = min >= 10 ? min.toString() : "0" + min.toString();
    let timeFormat = formattedHour + ":" + minWithTwoDigit;
    if (hour >= 24) {
        let day = Math.trunc(hour / 24);
        hour = Math.abs(hour % 24);
        let hoursWithTwoDigit = hour >= 10 ? hour.toString() : "0" + hour.toString();        
        let formattedDay = day < 10? ( day<0 ?(`-0${day}`) : "0" + day) : day;
        timeFormat = formattedDay.toString() + "." + hoursWithTwoDigit + ":" + minWithTwoDigit;
    }
    if (hour === 0 && sign === -1) {
        timeFormat = "-" + timeFormat;
    }
    return timeFormat;

}

export function getLocalTimeStringMoment(time: string) {
    return moment.utc(`${time}:00`, 'HH:mm').local().format("HH:mm")
}
export function getUTCTimeStringMoment(time: string) {
    return moment(`${time}:00`, 'HH:mm').utc().format("HH:mm")
}

export function minToHr() {
    const num = moment().utcOffset();
    const hours = Math.floor(num / 60);
    const minutes = Math.abs(num % 60);
    return hours + ":" + minutes;
}

export function getMinMaxStoreTime(stores: any, org: any, date: Date) {
    // considering time is in 24 hr format and atlest one store is present.
    let weekday = date.getDay();
    const calSetting: any = {
        weekOff: []
    };
    const startTimes: any = [];
    const endTimes: any = [];
    for (let j = 0; j < stores.length; j++) {
        if (!stores[j].useOrganizationWeeklyPlan) {
            if (stores[j].weeklyPlan[weekday].workingHours) {
                startTimes.push(slotModel(stores[j].weeklyPlan[weekday].workingHours.startTime).getLocalTime(new Date()));
                endTimes.push(slotModel(stores[j].weeklyPlan[weekday].workingHours.endTime).getLocalTime(new Date()));
            }
        }
        else {
            if (org.weeklyPlan && org.weeklyPlan[weekday] && org.weeklyPlan[weekday].workingHours) {
                startTimes.push(slotModel(org.weeklyPlan[weekday].workingHours.startTime).getLocalTime(new Date()));
                endTimes.push(slotModel(org.weeklyPlan[weekday].workingHours.endTime).getLocalTime(new Date()));
            }
        }
    }
    calSetting.startTime = _.min(startTimes);
    calSetting.endTime = _.max(endTimes);
    // If US Person visiting Indian time calendar show 24 hrs time
    if (!isSameDay(calSetting.startTime, calSetting.endTime)) {
        calSetting.startTime = undefined;
        calSetting.endTime = undefined;
    }
    return calSetting;
}

export function mapAppointment(eventArr: any[], factor = 60) {
    const start = new Date().getTime();

    let breakArray = [];
    if (eventArr){
        for (let i=0; i<eventArr.length; i++ ){
            let divi = Math.ceil(eventArr[i].appointmentDuration/factor);
            for (let y=0; y<divi; y++){
                const temp = {...eventArr[i]}
                temp.startTime =  moment.utc(eventArr[i].appointmentTime).local().add(y * factor, "minute").toDate();
                temp.endTime =  moment.utc(eventArr[i].appointmentTime).local().add((y+1) * factor, "minute").toDate();
                breakArray.push(temp);
            };
        };
    }
    let sortArray = _.chain(breakArray)
            .groupBy(function (obj) { return Math.floor(+(moment.utc(obj.startTime).local().toDate()) / (1000 * 60 * factor)); })
            .sortBy(function (v, k) { return k; }).value();
    let mergeArray = [];
    for(let i=0; i<sortArray.length ; i++ ){
        if(sortArray[i].length > 1){
            mergeArray.push(sortArray[i]);
            continue;
        }
        let element  = sortArray[i][0];
        for (let j=i+1 ; j<sortArray.length ; j++){
            if(sortArray[j].length > 1){
                break;
            }
            if(element.id === sortArray[j][0].id ){
                element.endTime = moment.utc(element.endTime).local().add(factor, "minute").toDate()
                i++;
                continue;
            }
            break;
        }
        mergeArray.push([element]);
    }
    const groupedEvent =  _.chain(mergeArray)
    .map((o: any, i: any) => {
        return {
            events: o, start: moment.utc(o[0].startTime).local().toDate(),
            end: moment.utc(o[0].endTime).local().toDate(), title: '', resourceId: o[0].locationId
        }
    }).value();
    const end = new Date().getTime();
    console.log(end, start, (end - start));
    return groupedEvent;
}

export function mapAppointmentSchedlesBackup(eventArr: any[], factor = 60) {
    return eventArr.map((o: any, i: any) => {
        return {
            events: [o], start: moment.utc(o.appointmentTime).local().toDate(),
            end: moment.utc(o.appointmentTime).local().add((o.appointmentDuration), "minute").toDate(),
            title: '', resourceId: o.locationId
        }
    });
}

export function mapAppointmentSchedles(eventArr: any[], factor = 60) {
    const locations = _.chain(eventArr)
        .groupBy('locationId')
        .values().value()
        .map(o => {
            return mapAppointment(o, factor);
        }).flat();
    
    return locations;
}

export function getWeeklyModelToDisplay(weeklyPlan: any) {
    const displayWeekPlan: any[] = [];
    const weeklyPlanMap = weeklyPlan ?
        new Map(weeklyPlan.map((obj: any) => [obj.weekday, obj])) :
        new Map();
    DAYS_OF_WEEK.map((d: any, i: any) => {
        const r: any = weeklyPlanMap.get(d);
        if (r) {
            const newValue = {
                ...r,
                workingHours: {
                    startTime: r.workingHours ? getLocalTimeFormat(r.workingHours.startTime) : '',
                    endTime: r.workingHours ? getLocalTimeFormat(r.workingHours.endTime) : '',
                },
                break: {
                    startTime: r.break ? getLocalTimeFormat(r.break.startTime) : '',
                    endTime: r.break ? getLocalTimeFormat(r.break.endTime) : '',
                },
                isWorking: r.workingHours ? true : false
            }
            displayWeekPlan.push(newValue);
        } else {
            displayWeekPlan.push({
                weekday: d,
                workingHours: {
                    startTime: '',
                    endTime: '',
                },
                break: {
                    startTime: '',
                    endTime: '',
                },
                isWorking: false
            });
        }
    })
    return displayWeekPlan;
}

export function convertWeekModelToUTC(weeklyPlan: any) {
    const data: any = [];
    weeklyPlan.map((r: any, i: any) => {
        data.push({
            Weekday: i,
            WorkingHours: r.isWorking && r.workingHours.startTime ? {
                startTime: getUTCTimeFormat(r.workingHours.startTime),
                endTime: getUTCTimeFormat(r.workingHours.endTime)
            } : undefined,
            Break: r.isWorking && r.break.startTime ? {
                startTime: getUTCTimeFormat(r.break.startTime),
                endTime: getUTCTimeFormat(r.break.endTime)
            } : undefined,
        })
    });
    return data;
}

export function getUTCDate(date: string | Date) {
    const selectedDate = new Date(date);
    return new Date(
        Date.UTC(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate()
        )
    )
}

export function localeFormattedDate(date: string | Date) {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' '
        + dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return formattedDate;
}

export function localeFormattedDateOnly(date: any) {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' })
    return formattedDate;
}

export function diffInDays(inputDate: any) {
    if (inputDate) {
        const diffDays = differenceInCalendarDays(new Date(inputDate), new Date());
        return { days: diffDays, isBelowWeek: diffDays <= 7 }
    } else {
        return { days: Number.MAX_SAFE_INTEGER, isBelowWeek: false }
    }
}

export function getCurrencySymbol(currencyCode: string) {
    switch (currencyCode) {
        case 'INR':
        case 'in':
            return '₹ ';
        case 'CAD':
        case 'ca':
            return '$';
        case 'USD':
        default:
            return '$';
    }
}

export function getCurrencySuffix(currencyCode: string) {
    switch (currencyCode) {
      case 'INR':
      case 'in':
        return '/- ';
      case 'CAD':
      case 'ca':
        return 'CAD';
      case 'USD':
      default:
        return 'USD';
    }
  }

export interface IDuration { duration: number, label: string };

export function getAvailableDurations(duration: any, currtime: any, endtime: any): IDuration[] {
    const durations: any = [];
    if (Number.isInteger(duration)) {
        const mins = differenceInMinutes(endtime, currtime);
        durations.concat(getTimeDurationSlots(duration, mins));

    } else {
        durations.push({ duration: 30, label: ' M' });
        durations.push({ duration: 60, label: '1 H' });
        durations.push({ duration: 90, label: '1 H 30 M' });
        durations.push({ duration: 120, label: '2 H' });
    }
    return durations;
}

export function getTimeDurationSlots(duration: number, maxSlot: any): IDuration[] {
    const durations = [];
    for (let i = duration; i <= maxSlot; i = i + duration) {
        const minLabel = humanizeMinsToHr(i);
        durations.push({ duration: i, label: minLabel });
    }

    return durations;
}

export function humanizeMinsToHr(mins: any) {
    const dur = moment.duration({ "minutes": mins });
    const hr = dur.get('h');
    const min = dur.get('m');
    const minLabel = hr > 0 ?
        min > 0 ? `${hr} H ${min} M`
            : `${hr} H`
        : `${min} M`;

    return minLabel;
}

export function getCurrency(CountryCode: string) {
    switch (CountryCode) {
        case 'in':
            return 'INR';
        case 'ca':
            return 'CAD';
        case 'us':
        default:
            return 'USD';
    }
}

export function formatPhoneNumber(phoneNumber: string | undefined) {
    if (phoneNumber){
        let pn = new PhoneNumber( phoneNumber );
        return pn.getNumber( 'international' )
    }
    return " ";
}

export function getColorByPaymentStats(evStyle: string) {
    switch (evStyle) {
        case PaymentStatus.Paid:
            return '#70D005';
        case PaymentStatus.Active:
            return '#008000';
        case PaymentStatus.Evaluation:
            return '#59ABE3';
        case PaymentStatus.CancellationPending:
            return '#F5751A';
        case PaymentStatus.Cancelled:
            return '#CB070D';
        case PaymentStatus.Pending:
            return '#F7C206';
        default:
            return '#70D005';
    }
}

export function getColorByPlanType(plan: string) {
    switch (plan) {
        case SubscriptionPlanTypes.Free:
            return '#77befc';
        case SubscriptionPlanTypes.Basic:
            return '#f47822';
        case SubscriptionPlanTypes.Intermediate:
            return '#4eae4a';
        case SubscriptionPlanTypes.Premium:
            return '#912c02';
        case SubscriptionPlanTypes.Enterprise:
            return '#7719ab';
        default:
            return '#70D005';
    }
}

export function getColorBySmsBalance(balance: number) {
    if (balance < 100){
        return 'red';
    }
    if(balance > 100 && balance < 200){
        return 'yellow';
    }
    if(balance > 200){
        return 'green';
    }
    return 'green';
}